import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 160px 0 40px;

  > h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 67px;
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 80px;
  }

  > div {
    max-width: 1920px;
    padding: 0 80px;
    margin: 0 auto;
  }

  @media (max-width: 767.98px) {
    padding: 80px 20px;
  }
`

export const SectionSearch = styled.div`
  width: 100%;
  > div {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1920px;
    padding: 20px 0;

    > div {
      display: flex;
      flex-direction: column;
      width: 420px;

      > label {
        font-size: 16px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #454545;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 1199.98px) {
    display: none;
  }
`

export const Filters = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 16px;
  margin: 10px 16px;

  > h3 {
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  > section {
    display: flex;
    justify-content: space-between;
  }
`

export const Search = styled.div`
  display: flex;
  width: 100%;

  > div {
    background: #ffffff;
    border: 1px solid #233237;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px;

    > img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    > input {
      margin-left: 10px;
    }
  }

  > button {
    background: none;
    margin-left: 15px;

    > img {
      width: 38px;
      height: 38px;
    }
  }

  @media (max-width: 575.98px) {
    margin-bottom: 20px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1200px) {
    padding: 10px 0;

    > button {
      display: none;
    }

    > div {
      padding: 10px;

      > img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      > input {
        margin-left: 10px;
      }
    }
  }
`

export const SectionOldPost = styled.div`
  > div {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: space-between;
    gap: 40px 0;

    > img {
      width: 300px;
    }

    > div {
      width: 300px;
    }

    @media (max-width: 991.98px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -20px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-around;
      gap: 100px 20px;
    }

    @media (min-width: 1200px) and (max-width: 1820px) {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-between;
    }

    @media (min-width: 1821px) {
      display: grid;
      grid-template-columns: auto auto auto auto;
      justify-content: space-between;
    }
  }
`

export const SectionPaginator = styled.div``

export const Select = styled.select`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid rgba(69, 69, 69, 0.6);
  box-sizing: border-box;
  padding: 11px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 100%;

  > option {
    background-color: ${props => props.theme.colors.white};
  }
`

export const InputData = styled.input`
  border: 1px solid rgba(69, 69, 69, 0.6);
  box-sizing: border-box;
  padding: 11px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
`

export const Title = styled.h3`
  color: #2c394190;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 16px;
  margin: 10px 0 0 20px;
  transition: 0.3s;
`

export const Banner = styled.img`
  width: 413px;
  height: 189px;
  max-width: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
`

export const NewsContent = styled.div`
  > div {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991.98px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > a {
        margin-bottom: 40px;
      }
    }

    @media (min-width: 992px) and (max-width: 1399.98px) {
      display: flex;
      flex-direction: column;

      > a {
        width: 100%;
      }

      > a:nth-child(1) {
        margin-bottom: 20px;
      }
    }

    @media (min-width: 1600px) and (max-width: 1919.98px) {
      > a {
        width: calc(50% - 28px);
      }
    }

    @media (min-width: 1920px) {
      > a {
        width: calc(50% - 28px);
      }
    }
  }
`

export const Card = styled(NavLink)`
  background: #ffffff;
  border: 1px solid #dfdfdf;
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 450px;
  cursor: pointer !important;
  text-decoration: none !important;
  overflow: hidden !important;

  :hover {
    filter: drop-shadow(rgb(0 0 0 / 35%) 0px 0px 30px);
  }

  :hover ${Title} {
    color: ${props => props.theme.colors.tertiary};
    transition: 0.3s;
  }

  :hover ${Banner} {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
`

export const Region = styled.p`
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.gray};
  padding: 4px 8px;
  border-radius: 50px;
  margin-bottom: 8px;
  width: max-content;
  margin: 24px 0 10px 20px;
`

export const Content = styled.div`
  padding: 24px 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  p {
    color: #5a5a5a;
  }

  > div:nth-child(1) {
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
`

export const Icon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 10px;
`

export const Button = styled(NavLink)`
  > img {
    width: 32px;
    height: 32px;
  }
`

export const Close = styled.button`
  float: right;
  margin-top: -20px;
  background: none;
`

export const ButtonFilter = styled.button`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.secondary};
  padding: 13px 35px;
`

export const ButtonClear = styled.button`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};
  padding: 13px 35px;
`
