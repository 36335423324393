import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);
`

export const ContainerLink = styled.a`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);
  width: 240px;
  height: 90px;
  margin: 7px;

  > img {
    max-width: 190px;
    height: auto;
  }
`

export const Card = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

export const CardImg = styled.button`
  background-color: ${props => props.theme.colors.white};
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  width: 240px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px;
  border: none;
  cursor: pointer;
`

export const Section = styled.div`
  height: auto;
  max-width: 1920px;
  position: relative;
  z-index: 10;
`

export const Content = styled.div`
  top: 150px;
  width: 80%;
  margin: 0 auto;
  > p {
    background-color: ${props => props.theme.colors.white};
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    padding: 20px;
    margin-top: 20px;
  }
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 80px;
  margin-bottom: 48px;
  width: 100%;

  @media (max-width: 575.98px) {
    display: none;
  }
`

export const PreviousBtn = styled.button`
  background: none;
  width: 32px;
  height: 32px;
  margin-right: 10px;

  > img {
    width: 32px;
    height: 32px;
    transform: rotateY(180deg);
  }
`

export const NextBtn = styled.button`
  background: none;
  width: 32px;
  height: 32px;

  > img {
    width: 32px;
    height: 32px;
  }
`
