import React from 'react'
import {
  Container,
  SectionHeader,
  Content,
  SectionPolitic,
  SectionManagement,
  SectionSupporter,
  SectionReports,
  ReportList,
  Partners,
  PartnerItem
} from '../Transparencia/style'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { convertData } from '../../functions'
import renderHTML from 'react-render-html'
import AnnualReports from '../../components/AnnualReports'
import OurPartners from '../../components/OurPartners'
import circleDown from '../../assets/circleDown.png'
import { Helmet } from 'react-helmet'
import { DefaultBtn } from '../../components/DefaultButton/style'

const Transparencia: React.FC = () => {
  const { transparencies } = useSelector((state: RootState) => state.clickState)

  return (
    <Container>
      <Helmet title="Transparência - Fogo Cruzado" />
      {transparencies.map((transparencySection: any) => {
        return (
          <>
            {transparencySection?.name == 'Abertura' && (
              <SectionHeader>
                <div>
                  <div>
                    <div>
                      {transparencySection?.title.length > 0
                        ? renderHTML(transparencySection?.title)
                        : ''}
                    </div>
                    <div>
                      {transparencySection?.description.length > 0
                        ? renderHTML(transparencySection?.description)
                        : ''}
                    </div>
                    <button onClick={() => window.scrollTo(0, 600)}>
                      <img src={circleDown} alt="" />
                    </button>
                  </div>
                  <img src={transparencySection?.image?.file} alt="" />
                </div>
              </SectionHeader>
            )}
            {transparencySection?.name == 'Bloco 1' && (
              <Content>
                <div>
                  {transparencySection?.title.length > 0
                    ? renderHTML(transparencySection?.title)
                    : ''}
                </div>
                <div>
                  {transparencySection?.description.length > 0
                    ? renderHTML(transparencySection?.description)
                    : ''}
                </div>
              </Content>
            )}
            {transparencySection?.name == 'Bloco 2' && (
              <SectionPolitic>
                <div>
                  {renderHTML(transparencySection.items[0].title ?? '')}
                </div>
                <div>
                  {renderHTML(transparencySection.items[0].description ?? '')}
                </div>
                <div>
                  {renderHTML(transparencySection.items[1].description ?? '')}
                </div>
                <div>
                  <img src={transparencySection.items[0].image?.file} />
                </div>
              </SectionPolitic>
            )}
            {transparencySection?.name == 'Bloco 3' && (
              <SectionManagement>
                <section>
                  <img src={transparencySection?.image?.file} />
                  <div>
                    <>
                      {transparencySection?.title.length > 0
                        ? renderHTML(transparencySection?.title)
                        : ''}
                      {transparencySection?.description.length > 0
                        ? renderHTML(transparencySection?.description)
                        : ''}
                    </>
                  </div>
                </section>
              </SectionManagement>
            )}

            {/* Sessão temporariamente oculta = SectionSupporter.display: none */}
            {transparencySection?.name == 'Bloco 4' && (
              <SectionSupporter>  
                <div>
                  <div>
                    <div>
                      {transparencySection?.title.length > 0
                        ? renderHTML(transparencySection?.title)
                        : ''}
                    </div>
                    <div>
                      {transparencySection?.description.length > 0
                        ? renderHTML(transparencySection?.description)
                        : ''}
                    </div>
                    {/* <DefaultBtn to="" text="Quero apoiar" /> */}
                  </div>
                  <img src={transparencySection?.image?.file} />
                </div>
              </SectionSupporter>
            )}

            {transparencySection?.name == 'Bloco 5' && (
              <SectionReports>
                {transparencySection?.items.map((item: any) => {
                  return (
                    <div>
                      <div>
                        {item?.title.length > 0 ? renderHTML(item?.title) : ''}
                      </div>
                      <div>
                        {item?.description.length > 0
                          ? renderHTML(item?.description)
                          : ''}
                      </div>

                      <ReportList>
                        {item?.reports.map((report: any) => {
                          return (
                            <AnnualReports
                              title={report?.title}
                              img={report?.image?.file}
                              date={convertData(report?.createAt)}
                              link={report?.file?.file}
                              fileName={report?.description}
                            />
                          )
                        })}
                      </ReportList>
                    </div>
                  )
                })}
              </SectionReports>
            )}
            {transparencySection?.name == 'Bloco 6' && (
              <Partners>
                <div>
                  {transparencySection?.title.length > 0
                    ? renderHTML(transparencySection?.title)
                    : ''}
                </div>
                <div>
                  {transparencySection?.items.map((partner: any) => {
                    return (
                      <PartnerItem target="_blank" href={partner.description}>
                        <img src={partner.image?.file} />
                      </PartnerItem>
                    )
                  })}
                </div>
              </Partners>
            )}
          </>
        )
      })}
    </Container>
  )
}

export default Transparencia
