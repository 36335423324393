import React, { useEffect, useState } from 'react'
import { Container, Card, CardFlex, Img, Number, Text } from '../OurData/style'
import plus from '../../assets/plus.png'
import VisibilitySensor from 'react-visibility-sensor'
import { Waypoint } from 'react-waypoint'

interface IProps {
  number: number
  text: string
  suffix?: string
}

const OurData: React.FC<IProps> = props => {
  const [viewPortEntered, setViewPortEntered] = useState(false)

  const onVWEnter = () => {
    setViewPortEntered(true)
  }

  return (
    <Container>
      <Card>
        <CardFlex>
          <Img src={plus} />
          {/* @ts-ignore */}
          <Waypoint onEnter={onVWEnter}>
            {viewPortEntered && (
              <Number end={props.number} start={0} duration={5} />
            )}
          </Waypoint>
        </CardFlex>
        <Text>{props.text}</Text>
      </Card>
    </Container>
  )
}

export default OurData
// {({ countUpRef, start }) => (
//     // <VisibilitySensor delayedCall
//     //     onChange={() => {
//     //         console.log(start,'start fora')
//     //         if(start.length == 0 && start.name == ""){
//     //             start
//     //             console.log(start.length,'start dentro')
//     //         }
//     //     }}
//     // >
//     //     <Number ref={countUpRef} />
//     // </VisibilitySensor>
//     <>

//     </>
// )}
