import React from 'react'
import { Container } from './style'

interface TypeButtonProps {
  text: string
  selected: boolean
  onSelect(): void
}

const TypeButton: React.FC<TypeButtonProps> = ({
  text,
  selected,
  onSelect
}) => {
  return (
    <Container
      selected={selected}
      onClick={event => {
        event.preventDefault()
        onSelect()
      }}
    >
      {text}
    </Container>
  )
}

export default TypeButton
