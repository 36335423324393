import styled from 'styled-components'
import Select from '../../components/Select/index'
import { SelectItem } from '../../components/Select/style'

export const Container = styled.div`
  margin-top: 143px;
  width: 100%;
  * {
    font-family: 'Raleway', sans-serif !important;
    line-height: 32px;
  }

  @media only screen and (max-width: 575.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 1200px) {
    margin-top: 143px;
  }
`

export const SectionHeader = styled.div`
  background: linear-gradient(180deg, #2c3841 8.01%, #23526f 126.28%);
  @media (max-width: 575.98px) {
    > div {
      height: 330px;
      position: relative;
      > div {
        padding: 16px 16px;
        z-index: 2;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        > button {
          display: none;
        }

        > div:nth-child(1) {
          > h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
          text-align: left;
        }
      }
      > img {
        z-index: 1;
        width: 100%;
        opacity: 0.1;
        position: absolute;
        height: 330px;
        object-fit: cover;
        top: 0;
        left: 0;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      height: 400px;
      position: relative;
      > div {
        padding: 16px 16px;
        z-index: 2;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        > button {
          display: none;
        }

        > div:nth-child(1) {
          margin-top: 40px;
          > h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 38px;
            line-height: 48px;
            text-align: center;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
        }
      }
      > img {
        z-index: 1;
        width: 100%;
        max-height: 400px;
        object-fit: cover;
        opacity: 0.1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    padding: 60px 0px;
    padding-left: 40px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        > button {
          display: block;
          width: 48px;
          height: 48px;
          background: none;
          margin-left: 0;
          margin-top: 65px;
          text-decoration: none;
          cursor: pointer;

          img {
            width: 48px;
            height: 48px;
          }
        }

        > div:nth-child(1) {
          > h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 67px;
            text-align: left;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
          max-width: 500px;
        }
      }

      > img {
        max-height: 400px;
        padding-left: 30px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1599.98px) {
    padding: 60px 0px;
    padding-left: 40px;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        > button {
          display: block;
          width: 48px;
          height: 48px;
          background: none;
          margin-left: 0;
          margin-top: 65px;
          text-decoration: none;
          cursor: pointer;

          img {
            width: 48px;
            height: 48px;
          }
        }

        > div:nth-child(1) {
          > h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 38px;
            line-height: 44px;
            text-align: left;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
          max-width: 500px;
        }
      }

      > img {
        max-height: 500px;
        padding-left: 20px;
      }
    }
  }
  @media (min-width: 1600px) {
    padding: 60px 0px;
    padding-left: 80px;
    > div {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1920px;

      > div {
        > button {
          display: block;
          width: 48px;
          height: 48px;
          background: none;
          margin-left: 0;
          margin-top: 65px;
          text-decoration: none;
          cursor: pointer;

          img {
            width: 48px;
            height: 48px;
          }
        }

        > div:nth-child(1) {
          > h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 38px;
            line-height: 44px;
            text-align: left;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 28px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
          max-width: 500px;
        }
      }

      > img {
        max-height: 600px;
        padding-left: 20px;
      }
    }
  }
`

export const Content = styled.div`
  @media (max-width: 575.98px) {
    margin: 40px 16px;

    > div {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        color: #233237;
        padding-bottom: 20px;
      }

      > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      b,
      strong,
      i {
        text-align: center;
      }

      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      li {
        margin-left: 18px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #233237;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    margin: 40px 16px;

    > div {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
        margin-bottom: 40px;
        text-align: center;
      }

      > p,
      b,
      strong,
      i {
        text-align: left;
      }

      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #232323;
      }

      li {
        margin-left: 18px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 34px;
        color: #233237;
      }
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    padding: 60px 40px;

    > div {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
        margin-bottom: 40px;
      }

      > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      b,
      strong,
      i {
        text-align: left;
      }

      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #232323;
      }

      li {
        margin-left: 18px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 34px;
        color: #233237;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1599.98px) {
    padding: 60px 40px;

    > div {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
        margin-bottom: 40px;
      }

      > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      b,
      strong,
      i {
        text-align: left;
      }

      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #232323;
      }

      li {
        margin-left: 18px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 34px;
        color: #233237;
      }
    }
  }
  @media (min-width: 1600px) {
    padding: 60px 80px;
    max-width: 1920px;
    margin: 0 auto;
    > div {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
        margin-bottom: 40px;
      }

      > p,
      h1,
      h2,
      h3,
      h4,
      h5,
      b,
      strong,
      i {
        text-align: left;
      }

      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #232323;
      }

      li {
        margin-left: 18px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 34px;
        color: #233237;
      }
    }
  }
`

export const SectionPolitic = styled.div`
  background-color: #f8f8f8;

  @media (max-width: 575.98px) {
    padding: 60px 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      'TITLE'
      'LEFT'
      'RIGHT'
      'IMG';

    > div:nth-child(1) {
      grid-area: TITLE;
      padding-bottom: 40px;
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        color: #233237;
        text-align: center;
      }
    }

    > div:nth-child(2) {
      grid-area: LEFT;
      height: 100%;
      padding-bottom: 20px;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #233237;
      }
    }

    > div:nth-child(3) {
      grid-area: RIGHT;
      padding-bottom: 40px;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #233237;
      }
    }
    > div:nth-child(4) {
      display: flex;
      grid-area: IMG;
      align-items: center;
      justify-content: center;
      > img {
        object-fit: cover;
        max-height: 250px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    padding: 60px 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      'TITLE'
      'LEFT'
      'RIGHT'
      'IMG';

    > div:nth-child(1) {
      grid-area: TITLE;
      padding-bottom: 40px;
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
        text-align: center;
      }
    }

    > div:nth-child(2) {
      grid-area: LEFT;
      height: 100%;
      padding-bottom: 40px;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }

    > div:nth-child(3) {
      grid-area: RIGHT;
      padding-bottom: 40px;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }
    > div:nth-child(4) {
      display: flex;
      grid-area: IMG;
      align-items: center;
      justify-content: center;
      > img {
        object-fit: cover;
        height: 280px;
      }
    }
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    padding: 60px 40px;
    padding-right: 100px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'TITLE TITLE'
      'LEFT RIGHT'
      'LEFT IMG';
    grid-column-gap: 50px;

    > div:nth-child(1) {
      grid-area: TITLE;
      padding-bottom: 40px;
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
      }
    }

    > div:nth-child(2) {
      grid-area: LEFT;
      height: 100%;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }

    > div:nth-child(3) {
      grid-area: RIGHT;
      padding-bottom: 40px;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }
    > div:nth-child(4) {
      display: flex;
      grid-area: IMG;
      align-items: center;
      justify-content: center;
      > img {
        object-fit: cover;
        height: 280px;
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1599.98px) {
    padding: 60px 40px;
    padding-right: 80px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'TITLE TITLE'
      'LEFT RIGHT'
      'LEFT IMG';
    grid-column-gap: 50px;

    > div:nth-child(1) {
      grid-area: TITLE;
      padding-bottom: 40px;
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
      }
    }

    > div:nth-child(2) {
      grid-area: LEFT;
      height: 100%;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }

    > div:nth-child(3) {
      grid-area: RIGHT;
      padding-bottom: 40px;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }
    > div:nth-child(4) {
      display: flex;
      grid-area: IMG;
      align-items: center;
      justify-content: center;
      > img {
        object-fit: cover;
        height: 280px;
      }
    }
  }

  @media (min-width: 1600px) {
    padding: 60px 80px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'TITLE TITLE'
      'LEFT RIGHT'
      'LEFT IMG';
    grid-column-gap: 50px;

    > div:nth-child(1) {
      grid-area: TITLE;
      padding-bottom: 40px;
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        color: #233237;
      }
    }

    > div:nth-child(2) {
      grid-area: LEFT;
      height: 100%;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }

    > div:nth-child(3) {
      grid-area: RIGHT;
      padding-bottom: 40px;
      > p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #233237;
      }
    }
    > div:nth-child(4) {
      display: flex;
      grid-area: IMG;
      align-items: center;
      justify-content: center;
      > img {
        object-fit: cover;
        height: 280px;
      }
    }
  }
`

export const SectionManagement = styled.div`
  > img {
    max-width: 730px;
    max-height: 744px;
    width: auto;
    height: auto;
    object-fit: contain;
  }

  @media (max-width: 575.98px) {
    padding: 40px 16px;
    > section {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        color: #233237;
        padding-bottom: 40px;
        text-align: center;
      }

      > div {
        > p {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }
      }

      > img {
        display: none;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 1199px) {
    padding: 40px 20px;

    > section {
      > div {
        margin: 30px 0;
        > h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 45px;
          color: #233237;
          padding-bottom: 40px;
          text-align: center;
        }
      }

      > img {
        display: none;
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1599.98px) {
    padding-top: 70px;
    padding-bottom: 120px;

    > section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 52%;
        > h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 45px;
          color: #233237;
          padding-bottom: 40px;
        }

        > p {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          color: #233237;
          padding-right: 100px;
        }
      }

      > img {
        max-width: 600px;
        max-height: 744px;
        width: auto;
        height: auto;
        object-fit: contain;
        padding-right: 30px;
      }
    }
  }
  @media (min-width: 1600px) {
    padding-top: 70px;
    padding-bottom: 120px;
    padding-right: 80px;
    max-width: 1920px;

    > section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 52%;
        > h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 45px;
          color: #233237;
          padding-bottom: 40px;
        }

        > p {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          color: #233237;
          padding-right: 100px;
        }
      }

      > img {
        max-width: 650px;
        max-height: 744px;
        width: auto;
        height: auto;
        object-fit: contain;
        padding-right: 30px;
      }
    }
  }
`

export const SectionSupporter = styled.div`
  background: linear-gradient(180deg, #2c3841 8.01%, #23526f 126.28%);
  display: none;
  @media (max-width: 575.98px) {
    > div {
      height: 500px;
      position: relative;
      > div {
        padding: 16px 40px;
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        > button {
          display: none;
        }

        > div:nth-child(1) {
          > h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
        }
      }
      > img {
        z-index: 1;
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 0.1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      height: 700px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        padding: 16px 40px;
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        > button {
          display: none;
        }

        > div:nth-child(1) {
          h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
          text-align: center;
        }
      }
      > img {
        z-index: 1;
        width: 100%;
        max-height: 700px;
        object-fit: cover;
        opacity: 0.1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    > div {
      height: 700px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        padding: 16px 40px;
        z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        > button {
          display: none;
        }

        > div:nth-child(1) {
          h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 38px;
            line-height: 48px;
            text-align: center;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
          text-align: center;
        }
      }
      > img {
        z-index: 1;
        width: 100%;
        max-height: 700px;
        object-fit: cover;
        opacity: 0.1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1599.98px) {
    > div {
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        padding: 16px 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 48%;

        > div:nth-child(1) {
          h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 45px;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
        }

        > a:nth-child(3) {
          width: 140px;
        }
      }
      > img {
        max-width: 36%;
      }
    }
  }
  @media (min-width: 1600px) {
    > div {
      max-width: 1920px;
      margin: 0 auto;
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        padding: 16px 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 48%;

        > div:nth-child(1) {
          h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 45px;
            color: #ffffff;
            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 2px;
            }
          }
        }

        > div:nth-child(2) {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          color: #ffffff;
          margin-top: 40px;
          margin-bottom: 30px;
        }

        > a:nth-child(3) {
          width: 140px;
        }
      }
      > img {
        width: 38%;
      }
    }
  }
`

export const SectionReports = styled.div`
  color: ${props => props.theme.colors.tertiary};
  @media (max-width: 575.98px) {
    padding: 0px 16px;

    > div {
      > div:nth-child(1) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        text-align: center;
        color: #233237;
        padding-bottom: 20px;
      }

      > div:nth-child(2) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: left;
        color: #233237;
        margin-bottom: 40px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    padding: 40px 16px;

    > div {
      > div:nth-child(1) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 49px;
        text-align: center;
        color: #233237;
        padding-bottom: 20px;
      }

      > div:nth-child(2) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: left;
        color: #233237;
        margin-bottom: 40px;
      }
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    padding: 40px 40px;

    > div {
      > div:nth-child(1) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 49px;
        text-align: center;
        color: #233237;
        padding-bottom: 40px;
      }

      > div:nth-child(2) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: left;
        color: #233237;
        margin-bottom: 40px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1599.98px) {
    padding: 40px 40px;

    > div {
      > div:nth-child(1) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 49px;
        text-align: left;
        color: #233237;
        padding-bottom: 40px;
      }

      > div:nth-child(2) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        text-align: left;
        color: #233237;
        margin-bottom: 40px;
      }
    }
  }
  @media (min-width: 1600px) {
    padding: 40px 80px;
    max-width: 1920px;
    margin: 0 auto;

    > div {
      > div:nth-child(1) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 49px;
        text-align: left;
        color: #233237;
        margin-bottom: 40px;
      }

      > div:nth-child(2) {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        text-align: left;
        color: #233237;
        margin-bottom: 40px;
      }
    }
  }
`

export const ReportList = styled.div`
  margin-top: 40px;
  gap: 20px;
  /* padding-bottom: 80px; */

  @media (max-width: 575.98px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (min-width: 1200px) and (max-width: 1599.98px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (min-width: 1600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1920px;
    margin: 0 auto;
  }
`

export const Partners = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 100px 100px 0px 0px;
  align-items: center;

  > div:nth-child(1) {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 40px;

    > h3 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      color: #233237;
    }
  }

  > div:nth-child(2) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 82px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`

export const PartnerItem = styled.a`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  height: 120px;
  margin: 20px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));

  > img {
    max-width: 190px;
    height: auto;
  }
`

export const SelectDate = styled(Select)`
  border: 1px solid ${props => props.theme.colors.success};
  @media (max-width: 575.98px) {
  }

  ${SelectItem} {
    background-color: red;
  }
`

export const CardPartners = styled.a`
  background-color: ${props => props.theme.colors.white};
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  text-decoration: none;
  width: 240px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px;
  border: none;
  cursor: pointer;

  > img {
    max-width: 190px;
    height: auto;
  }
`
