import React from 'react'
import { Container, Bakc, Previous, Number } from '../Paginator/style'

import pagIcon from '../../assets/pagIcon.png'

interface IProps {
  to: string
  number: number
}

const Paginator: React.FC<IProps> = props => {
  return (
    <Container>
      <Bakc to={props.to}>
        <img src={pagIcon} alt="icon back" />
      </Bakc>
      <Number to={props.to}>{props.number}</Number>
      <Previous to={props.to}>
        <img src={pagIcon} alt="icon next" />
      </Previous>
    </Container>
  )
}

export default Paginator
