import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  > div {
    max-width: 1920px;
    margin: 0 auto;
    padding: 200px 80px 0 80px;
    text-align: justify;

    > div {
      margin: 20px 0 40px;

      p {
        margin-bottom: 15px;
      }
    }
  }
`
