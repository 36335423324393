import * as yup from 'yup'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const donationValidationSchema = yup.object().shape({
  donationValue: yup
    .string()
    .matches(
      /^R\$(\d{1,3}(\.\d{3})*|\d+)(\,\d{2})?$/,
      'Informe um valor válido para a doação'
    )
    .required('Você precisa informar o valor da doação'),
  email: yup
    .string()
    .email('O e-mail informado está inválido')
    .required('Você precisa informar o e-mail para contato')
})

export const donationValueMask = createNumberMask({
  prefix: 'R$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false
})
