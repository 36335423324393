import React from 'react'
import { Container, Card, Download } from '../AnnualReports/style'

interface IProps {
  title: string
  img: string
  date: string
  link?: string
  fileName?: string
}

const AnnualReports: React.FC<IProps> = props => {
  return (
    <Container>
      <img src={props.img} />
      <Card>
        <p>{props.title}</p>
        <h3>{props.date}</h3>
        <div>
          {props.link != null ? (
            <Download
              href={props.link}
              download={props.fileName}
              target="_blank"
            >
              Baixar PDF
            </Download>
          ) : (
            <Download>Em breve</Download>
          )}
        </div>
      </Card>
    </Container>
  )
}

export default AnnualReports
