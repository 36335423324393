import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  NavHigher,
  NavBottom,
  Navigation,
  LinkNav,
  Logo,
  Search,
  Nav,
  ContainerResp,
  LinkNavResp,
  SearchResp,
  LogoResp,
  Patners,
  InputSearch,
  Input
} from './style'
import { Link } from 'react-router-dom'

import DefaultButton from '../DefaultButton'

import search from '../../assets/pesquisar.png'
import logo from '../../assets/logo-branco.png'
import open from '../../assets/open.png'
import close from '../../assets/close.png'
import feather_search from '../../assets/feather_search.png'
import { RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { getAllNewsByParams } from '../../functions'
import { GET_NEWS, SET_SEARCH } from '../../store/actions'

const Header: React.FC = () => {
  const dispatch = useDispatch()
  let { seachSite, token } = useSelector((state: RootState) => state.clickState)
  const [show, setShow] = useState(true)
  const [showInput, setShowInput] = useState(false)
  const [values, setValue] = useState('')
  const [link, setLink] = useState('')
  const ref = useRef<any>(null)

  function showMenu() {
    if (show == true) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  function searchSite() {
    getAllNewsByParams(token, '', '', '', '', '', seachSite).then(resp => {
      dispatch({ type: GET_NEWS, posts: resp.data })
      dispatch({ type: SET_SEARCH, seachSite: values })
    })
  }

  useEffect(() => {
    seachSite = values
    console.log(seachSite, values, 'valores iguais')

    // getAllNewsByParams(token, '', '', '', '', '', seachSite)
    //     .then((resp) => {
    //         dispatch({type: GET_NEWS, posts: resp.data})
    //     })
  }, [values, seachSite])

  return (
    <>
      <Container>
        <Nav>
          <NavHigher>
            <div>
              <LinkNav to="/impacto">Impacto</LinkNav>
              <LinkNav to="/transparencia">Transparência</LinkNav>
              <LinkNav to="/redes-sociais">Redes sociais</LinkNav>
            </div>
          </NavHigher>
          <NavBottom>
            <div>
              <Link to="">
                <Logo src={logo} />
              </Link>
              <Navigation>
                <LinkNav to="">Home</LinkNav>
                <LinkNav to="/sobre">Sobre</LinkNav>
                <LinkNav to="/noticias">Notícias</LinkNav>
                <LinkNav to="/dados/tempo-real">Dados</LinkNav>
                <LinkNav to="/API">API</LinkNav>
                <Search onClick={() => setShowInput(!showInput)}>
                  <img src={search} width="43px" height="43px" />
                </Search>
                {showInput === true ? (
                  <>
                    <InputSearch to="/pesquisa-site" ref={ref} />
                    <Input
                      type="text"
                      placeholder="Pesquisar..."
                      onChange={e => {
                        setValue(e.target.value)
                        seachSite = e.target.value
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          ref?.current?.click()
                          searchSite()
                        }
                      }}
                    />
                  </>
                ) : (
                  <div style={{ display: 'none' }} />
                )}
              </Navigation>
              <div style={{ width: '250px' }}></div>
              {/* <DefaultButton text="Quero apoiar" to="/seja-um-apoiador"/> */}
            </div>
          </NavBottom>
        </Nav>
      </Container>

      <ContainerResp active={show}>
        <div>
          <Link to="">
            <LogoResp src={logo} />
          </Link>
          <SearchResp>
            <InputSearch
              to="/pesquisa-site"
              ref={ref}
              style={{ display: 'none' }}
            />
            <input
              type="text"
              name=""
              id=""
              placeholder="Pesquisar..."
              onChange={e => {
                setValue(e.target.value)
                seachSite = e.target.value
              }}
            />
            <button
              onClick={() => {
                ref?.current?.click()
                searchSite()
              }}
            >
              <img src={feather_search} />
            </button>
          </SearchResp>
          {/* <Patners  to="/seja-um-apoiador">
                        Quero apoiar    
                    </Patners> */}
          <button onClick={() => showMenu()}>
            <img src={show == false ? close : open} alt="" />
          </button>
        </div>
        <div hidden={show}>
          <SearchResp>
            <InputSearch
              to="/pesquisa-site"
              ref={ref}
              style={{ display: 'none' }}
            />
            <input
              type="text"
              name=""
              id=""
              placeholder="Pesquisar..."
              onChange={e => {
                setValue(e.target.value)
                seachSite = e.target.value
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  ref?.current?.click()
                  searchSite()
                }
              }}
            />
            <button
              onClick={() => {
                ref?.current?.click()
                searchSite()
              }}
            >
              <img src={feather_search} />
            </button>
          </SearchResp>
          <nav>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to=""
            >
              Home
            </LinkNavResp>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to="/sobre"
            >
              Sobre
            </LinkNavResp>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to="/noticias"
            >
              Notícias
            </LinkNavResp>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to="/dados/tempo-real"
            >
              Dados
            </LinkNavResp>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to="/API"
            >
              API
            </LinkNavResp>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to="/impacto"
            >
              Impacto
            </LinkNavResp>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to="/transparencia"
            >
              Transparência
            </LinkNavResp>
            <LinkNavResp
              onClick={() => {
                showMenu()
              }}
              to="/redes-sociais"
            >
              Redes sociais
            </LinkNavResp>
          </nav>
        </div>
      </ContainerResp>
    </>
  )
}

export default Header
