import React from 'react'
import './style.css'
import { NavLink } from 'react-router-dom'
import learnMore from '../../assets/learnMore.png'

interface IProps {
  local: string
  title: string
  content: string
  to: string
  img: string
}

const NewsList: React.FC<IProps> = props => {
  return (
    <NavLink to={props.to} className="link">
      <div className="container">
        <div className="image-container">
          <img src={props.img} alt="" className="img" />
        </div>
        <div className="card">
          <div className="content">
            <div className="locale">{props.local}</div>
            <div className="title">{props.title}</div>
            <div className="text">{props.content}</div>
            <div className="learn_more">
              <img src={learnMore} alt="" className="learn_more-icon" />
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default NewsList
