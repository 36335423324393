import styled from 'styled-components'

export const Container = styled.div`
  padding: 32px 80px;

  @media (max-width: 991.99px) {
    padding: 0 0 0 0 !important;
  }
`

export const Header = styled.div`
  > section {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 991.99px) {
    > section {
      flex-direction: column;
      align-items: left;
    }
  }
`

export const Title = styled.p`
  margin-bottom: 20px;
  text-align: left;

  @media (max-width: 991.99px) {
    margin-top: 10px;
    margin-bottom: 0;
  }
`

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 20px;
      > button {
        background: none;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;

        > img {
          width: 56px;
          height: 56px;
        }
      }
    }
  }
`

export const Section = styled.div`
  margin: 0 auto;

  display: ${props => {
    if (props.hidden == true) {
      return 'none'
    } else {
      return 'block'
    }
  }};

  @media (max-width: 991.99px) {
    width: clamp(320px, 100%, 100%);
    margin: 0 !important;
  }
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 80px;
`

export const SectionImg = styled.div`
  background-color: ${props => props.theme.colors.orange};
  width: 350px;
  height: 340px;
  margin-right: 70px;
  padding-right: 14px;
  padding-bottom: 14px;
  z-index: 1;

  > img {
    width: 330px !important;
    height: 330px !important;
    margin-left: -10px;
    margin-top: -10px;
  }

  @media (max-width: 992px) {
    width: 158px;
    height: 158px;
    margin-right: 10px;

    > img {
      width: 158px !important;
      height: 158px !important;
    }
  }
`

export const Line = styled.div`
  background-color: ${props => props.theme.colors.orange};
  height: 8px;
  width: auto;
  margin-left: -70px;
  z-index: -9;
`

export const SectionText = styled.div`
  color: ${props => props.theme.colors.tertiary};
  margin-top: 60px;

  > p:nth-child(1) {
    font-family: 'Raleway';
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    color: #233237;
  }

  @media (max-width: 991.99px) {
    margin-top: 0;
  }
`

export const Text = styled.p`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #233237;

  @media (max-width: 991.99px) {
    display: none;
  }
`

export const RespText = styled.p`
  margin-top: 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #233237;
  width: 100%;

  @media (min-width: 992px) {
    display: none;
  }
`
