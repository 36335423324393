import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from './customForm'

const MailchimpFormContainer: React.FC = () => {
  const postUrl = `https://fogocruzado.us5.list-manage.com/subscribe/post?u=82d9fdb2cb6bf9ddd084fc1a8&id=b1b410212e`

  return (
    <>
      {/* @ts-ignore */}
      <MailchimpSubscribe
        url={postUrl}
        //@ts-ignore
        render={({ subscribe, status, message }) => {
          return (
            //@ts-ignore
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )
        }}
      />
    </>
  )
}

export default MailchimpFormContainer
