import React from 'react'

import {
  Container,
  Card,
  SectionLeft,
  SectionRigth
} from '../DownloadApp/style'

import appleStoreIcon from '../../assets/appleStore.png'
import googlePlayIcon from '../../assets/playStore.png'

const DownloadApp: React.FC = () => {
  return (
    <Container>
      <div className="container-inner">
        <div className="container-text">
          <h3>Baixe nosso aplicativo</h3>

          <p>
            Disponível na <b>Apple Store</b> e no <b>Google play</b>.
          </p>
        </div>
        <div className="container-flex">
          <button>
            <img src={appleStoreIcon} alt="apple icon" />
            <div>
              <span>Download via</span>
              <p>Apple Store</p>
            </div>
          </button>
          <button>
            <img src={googlePlayIcon} alt="google play icon" />
            <div>
              <span>Download via</span>
              <p>Google Play</p>
            </div>
          </button>
        </div>
      </div>
    </Container>
  )
}

export default DownloadApp
