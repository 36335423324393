import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import { Container } from './style'

const PoliticaDePrivacidade: React.FC = () => {
  return (
    <Container>
      <Helmet title="Política de privacidade - Fogo Cruzado" />
      <div>
        <h2>POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS DO FOGO CRUZADO</h2>
        <div>
          <p>
            Tomamos todas as precauções para respeitar a privacidade dos nossos
            usuários e não estigmatizar vítimas de violência armada, respeitando
            a{' '}
            <a
              href="https://www.serpro.gov.br/lgpd/menu/a-lgpd/o-que-muda-com-a-lgpd"
              target="_blank"
              style={{ color: '#1155cc' }}
            >
              Lei Geral de Proteção de Dados
            </a>
            .
          </p>
          <p>
            O Instituto Fogo Cruzado, detentor do CNPJ: 41.138.166/0001-56, é o
            controlador de todos os dados recolhidos em suas plataformas,
            aplicativos e atividades. A ele compete as decisões referentes ao
            tratamento dos dados de usuários, pesquisadores e colaboradores.
          </p>
          <p>
            Veja abaixo como nos adequamos à legislação e conheça nosso
            compromisso ético com os usuários e a população das regiões
            metropolitanas em que trabalhamos em cada plataforma que operamos:
          </p>
        </div>
        <h4>APLICATIVO</h4>
        <div>
          <p>
            Para colaborar ativamente com o registro de tiroteios na plataforma
            Fogo Cruzado, o usuário precisa realizar um cadastro com algumas
            informações básicas obrigatórias (apelido de usuário, senha e
            pergunta de segurança). Essas informações são utilizadas apenas
            internamente para identificar usuários ativos - e não sua identidade
            - e classificá-los como confiáveis ou bloqueá-los em casos de spam.
          </p>
          <p>
            As informações de usuários que são coletadas e armazenadas - segundo
            descrição acima acima - não são repassadas a terceiros e são
            armazenadas em tabelas separadas daquelas disponibilizadas ao
            público sobre registros de tiroteios da organização.
          </p>
          <p>
            O tratamento de dados de cadastro é feito com base no consentimento,
            de acordo com o Termo de Consentimento disponível para o usuário
            antes do preenchimento de seus dados.
          </p>
          <p>
            Para validar o cadastro, o usuário precisa fornecer o seu número de
            telefone celular, com o objetivo de receber um código via SMS que
            valide o seu cadastro. Esta é uma etapa adotada para evitar o acesso
            por robôs. Essas informações não são armazenadas pelo Fogo Cruzado
            após o cumprimento da sua funcionalidade e não são utilizadas
            posteriormente com qualquer outro objetivo. O Fogo Cruzado utiliza
            um serviço terceirizado para o envio de SMS chamado{' '}
            <a
              href="https://www.twilio.com/go/twilio-brand-sales-pt-1?utm_source=google&utm_medium=cpc&utm_term=twilio&utm_campaign=G_S_LATAM_Brand_Twilio_Portuguese&cq_plac=&cq_net=g&cq_pos=&cq_med=&cq_plt=gp&gclid=Cj0KCQjw2MWVBhCQARIsAIjbwoNURMUug2LvhHtClVF2QSw47R2QamtQSEb8q3ChVGzr_fjNYoSb2OEaAk9sEALw_wcB"
              target="_blank"
              style={{ color: '#1155cc' }}
            >
              Twillio
            </a>
            .
          </p>
        </div>
        <h4>DADOS DE LOCALIZAÇÃO DO APLICATIVO</h4>
        <div>
          <p>
            O aplicativo do Fogo Cruzado oferece funcionalidades adaptadas de
            acordo com a localização do usuário, como: (i) o envio apenas de
            alertas de tiroteios a 5 quilômetros do usuário; e (ii) a
            visualização em lista de tiroteios a 5 quilômetros de distância do
            usuário.
          </p>
          <p>
            Para usufruir dessas funcionalidades, o usuário precisa compartilhar
            sua localização com o aplicativo Fogo Cruzado, para que, através
            dela, seja possível adaptar os recursos às preferências do usuário.
          </p>
          <p>
            <b>
              Ao escolher compartilhar essas informações, o usuário
              compartilhará dados de localização em segundo plano – ou seja,
              mesmo quando o aplicativo não estiver em uso ou estiver fechado. O
              usuário pode desabilitar essa funcionalidade a qualquer momento
              dentro do aplicativo.
            </b>
          </p>
          <p>
            <b>
              Informações de localização não são armazenadas pelo Fogo Cruzado
              nem compartilhadas com terceiros.
            </b>
          </p>
          <p>
            Os dados de localização do usuário são utilizados apenas para
            filtrar as informações a serem exibidas na tela e/ou recebimento de
            notificações baseadas na localização - no caso da opção "Apenas
            ocorrências próximas (raio de 5km)". Nenhuma ação de marketing ou de
            venda de produtos ou serviços será enviada a usuários com base em
            sua localização.
          </p>
        </div>
        <h4>API (Application Programming Interface)</h4>
        <div>
          <p>
            Para acessar a API do Fogo Cruzado o usuário deve realizar um
            cadastro com algumas informações básicas (apelido de usuário, e-mail
            e senha). Essas informações são utilizadas para segurança interna do
            projeto, permitindo a liberação ou bloqueio dos usuários que ameacem
            a estabilidade do servidor ou coloquem em risco o serviço gratuito
            prestado. Os emails também podem ser usados para o envio de
            informações referentes a mudanças e/ou suspensão nos serviços da
            API. Os dados pessoais coletados de usuários{' '}
            <b>
              <u>não são repassados a terceiros</u>
            </b>
            .
          </p>
          <p>
            O tratamento de dados pessoais de cadastro é feito com base no
            consentimento, de acordo com o Termo de Consentimento disponível
            para o usuário antes do preenchimento de seus dados.
          </p>
          <p>
            Por meio da API, é possível acessar, em tempo real, a base de dados
            sobre tiroteios contendo informações sobre: data e horário do
            registro, local do registro, presença de agentes de segurança,
            número de mortos e feridos (civis e agentes de segurança, incluindo
            status dos agentes de segurança), gênero das vítimas, motivação dos
            tiros, ocorrência de chacinas (incluindo a identificação de unidades
            policiais envolvidas, quando cabível), vítimas de balas perdidas,
            vítimas baleadas no interior de residências ou quintais, vítimas
            baleadas no entorno ou dentro de unidades de ensino, recorte etário
            de vítimas baleadas (crianças, adolescentes e idosos), modais de
            transporte coletivo interrompidos e vias com circulação interrompida
            por tiros, além de sinalizações de animais baleados, tiros em bares,
            vítimas com passagem pelo sistema de justiça criminal, tentativas de
            feminicídio, utilização de helicópteros como plataforma de tiros,
            vítimas baleadas a caminho/ou retornando de unidades de ensino,
            atentados com disparos de arma de fogo contra políticos, tiros em
            presídios ou em shoppings, tiroteios contínuos, motoristas de
            aplicativos ou vendedores ambulantes baleados. Informações que
            possam expor usuários ou estigmatizar vítimas de violência armada
            não são disponibilizadas na API.
          </p>
        </div>
        <h4>NEWSLETTER</h4>
        <div>
          <p>
            O Fogo Cruzado tem uma newsletter periódica, onde são divulgadas
            informações recentes sobre a plataforma e seus dados. A adesão ao
            serviço é voluntária, gratuita e para fazê-la o usuário precisa
            fornecer apenas o e-mail. Essas informações são armazenadas em banco
            de dados próprio e{' '}
            <b>
              <u>não serão compartilhadas com terceiros</u>
            </b>
            . O Fogo Cruzado utiliza um serviço especializado para o cadastro e
            envio de newsletters chamado{' '}
            <a
              href="https://mailchimp.com/pt-br/grow-with-mailchimp/?gclid=Cj0KCQjw2MWVBhCQARIsAIjbwoNeM2g-G9BVij03NgLiEwa_Fiktz4mrV1FEwe11LMAlH2euEw6Ifg0aAmSnEALw_wcB&gclsrc=aw.ds"
              target="_blank"
              style={{ color: '#1155cc' }}
            >
              MailChimp
            </a>
            .
          </p>
        </div>
        <h4>BASE DE DADOS PÚBLICA</h4>
        <div>
          <p>
            O principal serviço prestado pelo Fogo Cruzado é a disponibilização
            de uma base de dados aberta sobre violência armada que possa ser
            utilizada por qualquer indivíduo ou organização interessada em
            produzir diagnósticos sobre as regiões metropolitanas em que
            atuamos.
          </p>
          <p>
            Essa base é composta por notificações de disparos de arma de fogo
            enviadas por usuários, bem como por informações coletadas através da
            imprensa e de canais de divulgação das polícias. Todas as
            informações são cuidadosamente analisadas pela equipe para evitar a
            comunicação de informações falsas e duplicadas. Nos casos de
            registros coletados publicamente através de fontes policiais e da
            imprensa, muitas vezes é possível coletar informações complementares
            a respeito dos incidentes que incluem motivação dos tiroteios e
            características das vítimas. Neste caso, quando disponíveis, são
            compiladas informações como nome e idade das vítimas, dentre outras
            possíveis características disponíveis. Essas informações sempre são
            coletadas a partir de registros públicos e seus links salvos.
          </p>
          <p>
            O Fogo Cruzado armazena essas informações e faz uso delas em
            relatórios periódicos, textos de caráter jornalístico para o site, e
            comunicações em nossas redes sociais. No entanto, mesmo sendo
            coletadas publicamente - e, portanto, de conhecimento geral -,{' '}
            <b>
              <u>
                {' '}
                parte dessas informações não consta entre os dados públicos do
                Fogo Cruzado disponibilizados na API
              </u>
            </b>
            . Acreditamos que são informações sensíveis que podem trazer
            consequências de estigmatização para vítimas de violência armada e
            seus parentes se não forem tratadas de forma adequada. Por isso,{' '}
            <b>
              <u>
                {' '}
                essas informações são compartilhadas apenas com parceiros que
                entrem em contato direto conosco para especificar o escopo e os
                termos em que tais dados serão utilizados
              </u>
            </b>{' '}
            (seja para fins jornalísticos, de pesquisa ou política pública) se
            os mesmos forem compatíveis com a preocupação do Fogo Cruzado de não
            exposição e estigmatização de vítimas de violência armada.
          </p>
        </div>
        <h4>RASTREAMENTO DE DADOS DE USUÁRIOS E SERVIÇOS UTILIZADOS</h4>
        <div>
          <p>
            O Fogo Cruzado não utiliza cookies ou rastreadores de forma ativa no
            site, ou seja, não existe nenhum algoritmo embutido no código que
            faça coleta de dados dos usuários anonimamente, porém os serviços
            que nós utilizamos podem estar fazendo isso.
          </p>
          <p>
            Abaixo segue uma lista com os serviços e as respectivas páginas da
            política de privacidade e uso de Cookies:
          </p>
          <ul>
            <li style={{ marginLeft: '30px' }}>
              Stripe (utilizado para a funcionalidade de doação)
            </li>
            <a
              href="https://stripe.com/br/legal/cookies-policy"
              target="_blank"
              style={{ marginLeft: '30px', color: '#1155cc' }}
            >
              Stripe Cookies Policy - BR
            </a>
            <li style={{ marginLeft: '30px' }}>
              Mailchimp (utilizado para o cadastro e envio de newsletters)
            </li>
            <a
              href="https://mailchimp.com/legal/cookies/"
              target="_blank"
              style={{ marginLeft: '30px', color: '#1155cc' }}
            >
              Mailchimp’s Cookie Statement | Mailchimp
            </a>
            <li style={{ marginLeft: '30px' }}>AWS (servidor utilizado)</li>
            <a
              href="https://aws.amazon.com/pt/legal/cookies/"
              target="_blank"
              style={{ marginLeft: '30px', color: '#1155cc' }}
            >
              Aviso sobre cookies (amazon.com)
            </a>
            <li style={{ marginLeft: '30px' }}>
              Google Maps (utilizado em todos os mapas disponibilizados pelo
              Fogo Cruzado)
            </li>
            <a
              href="https://developers.google.com/maps/faq?hl=pt#api_cookies"
              target="_blank"
              style={{ marginLeft: '30px', color: '#1155cc' }}
            >
              Perguntas frequentes sobre a Plataforma Google Maps | Google
              Developers
            </a>
            <li style={{ marginLeft: '30px' }}>
              Twilio (utilizado no envio de SMS para validação de cadastro do
              aplicativo)
            </li>
            <a
              href="https://support.twilio.com/hc/en-us/articles/223136287-How-do-Twilio-cookies-work-"
              target="_blank"
              style={{ marginLeft: '30px', color: '#1155cc' }}
            >
              How do Twilio cookies work? – Twilio Support
            </a>
          </ul>
        </div>
        <h4>ARMAZENAMENTO DE DADOS</h4>
        <div>
          <p>
            O suporte de TI do Fogo Cruzado é responsável pela manutenção de
            todos os bancos de dados que dão suporte à nossa operação. O
            servidor utilizado é o AWS.
          </p>
          <p>
            A base de dados aberta sobre violência armada (disponível em grande
            parte através da nossa API) encontra-se separada das bases de dados
            onde se localizam os dados pessoais referentes aos nossos usuários
            do APP, da API e da newsletter. As bases de dados referentes aos
            usuários são usadas exclusivamente para fins de login e{' '}
            <b>
              <u>não serão compartilhadas com terceiros</u>
            </b>
            .
          </p>
        </div>
        <h4>SEUS DIREITOS</h4>
        <div>
          <p>
            Você tem o direito de acessar as informações pessoais que
            compartilhou conosco e que armazenamos em nossa base de dados,
            podendo, a qualquer momento, solicitar atualização ou exclusão dos
            dados, de acordo com a{' '}
            <a
              href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/Lei/L13709.htm"
              target="_blank"
              style={{ color: '#1155cc' }}
            >
              Lei Geral de Proteção de Dados
            </a>
            .
          </p>
          <p>
            O contato pode ser feito pelo e-mail contato@fogocruzado.org.br
            (assunto: LGPD), ou ainda através do nosso canal de suporte no site
            (
            <NavLink to="/fale-conosco" style={{ color: '#1155cc' }}>
              Fale Conosco
            </NavLink>
            ).
          </p>
        </div>
      </div>
    </Container>
  )
}

export default PoliticaDePrivacidade
