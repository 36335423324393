import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Acordion, BtnShow, BtnHide } from './style'

import { Accordion } from 'react-bootstrap'

const PerguntasFrequentes: React.FC = () => {
  const [expense, setExpense] = useState(null)
  const questions = [
    {
      id: '1',
      question: 'O que é o Fogo Cruzado',
      answer: `O Fogo Cruzado é um Instituto que usa tecnologia para produzir e divulgar dados abertos e colaborativos sobre violência armada, fortalecendo a democracia através da transformação social e da preservação da vida. 
            Com uma metodologia própria e inovadora, o laboratório de dados da instituição produz mais de 20 indicadores inéditos sobre violência armada nas regiões metropolitanas do Rio, do Recife e, em breve, em mais cidades brasileiras.
            Através de um aplicativo de celular, o Fogo Cruzado recebe e disponibiliza informações sobre tiroteios, checadas em tempo real por uma equipe especializada e local. Estas informações estão disponíveis no primeiro banco de dados aberto sobre violência armada da América Latina, que pode ser acessado gratuitamente pela API do Instituto.
            Seguindo o propósito de popularizar o conhecimento sobre segurança pública e direitos, o Fogo Cruzado usa técnicas de comunicação para tornar dados sobre violência armada mais acessíveis, visando qualificar e promover debates na sociedade.`
    },
    {
      id: '2',
      question: 'Como o Instituto Fogo Cruzado atua?',
      answer: `O Instituto atua em três diferentes frentes para conseguir tornar as cidades mais seguras e assim, fazer da segurança um direito efetivo de todos:
            Laboratório de dados - o programa aplica metodologia colaborativa para mapear tiroteios e transformá-los em um banco de dados aberto e gratuito, capaz de subsidiar inteligência para o bem público.
            Formação de lideranças - dados não geram impacto social sozinhos. É necessário um ecossistema capaz de transformá-los em informação, inteligência, ação coletiva e mudança social. Compartilhamos conhecimento, sobretudo com jovens de favelas e periferias, para que possam atuar nessa rede de forma qualificada.
            Laboratório de Comunicação - o medo é um sentimento poderoso e, por isso, o tema da violência armada mobiliza profundamente os brasileiros. Atualmente, o debate em torno dele é altamente polarizado e passional. O FC atua construindo estratégias de comunicação que favoreçam o diálogo entre grupos diferentes em prol de uma efetiva superação do problema da violência.`
    },
    {
      id: '3',
      question: 'Onde o Instituto Fogo Cruzado atua?',
      answer: `Atualmente o Instituto Fogo Cruzado opera laboratórios de dados e comunicação sobre violência armada em todas as cidades das regiões metropolitanas do Rio de Janeiro e do Recife.
                Nosso programa de Formação de lideranças e o Laboratório de Comunicação realizam atividades em todo o Brasil.`
    },
    {
      id: '4',
      question: 'Como o Fogo Cruzado se caracteriza juridicamente?',
      answer: `O Instituto Fogo Cruzado é uma organização não governamental. Nosso CNPJ é: XXXXXXX.`
    },
    {
      id: '5',
      question: 'Quem financia o Fogo Cruzado?',
      answer: `Neste link você pode ver nossos financiadores e nossos relatórios de atividades.`
    },
    {
      id: '6',
      question: 'O App do Fogo Cruzado é pago?',
      answer: `Não. O app é gratuito e disponível nos sistemas operacionais iOS e Android. `
    },
    {
      id: '7',
      question: 'Posso comprar dados do Fogo Cruzado?',
      answer: `Os dados do Fogo Cruzado são gratuitos. Você pode navegar para conhecê-los na seção Estatísticas deste site. Pode também solicitar o acesso à nossa API neste link.`
    },
    {
      id: '8',
      question:
        'Como funciona a tecnologia do aplicativo e da plataforma online?',
      answer: `O aplicativo do Fogo Cruzado usa a tecnologia do Google Maps como base. Quando uma pessoa notifica um tiroteio ou disparo de arma de fogo no aplicativo, essa informação vai para o nosso sistema de administração de dados. Neste sistema, a informação chega georreferenciada, o que permite o cruzamento de nossa base de dados com qualquer outra que também assim seja.`
    },
    {
      id: '9',
      question: 'O usuário é identificável ou pode ser rastreado?',
      answer: `Não. A preservação da identidade dos usuários é algo prioritário para o Fogo Cruzado. Não solicitamos dados pessoais e as notificações recebidas pelo sistema não rastreiam informações que possam identificar o usuário.`
    },
    {
      id: '10',
      question: 'Vocês publicam tudo que chega para vocês?',
      answer: `Não. Nós descartamos informações que são incompletas, repetidas ou que não foram confirmadas pela nossa equipe.`
    },
    {
      id: '11',
      question: 'Qual a metodologia de registro e coleta de dados?',
      answer: `Além de receber notificações de usuários diretamente via aplicativo e redes sociais, a equipe de gestão de dados do Fogo Cruzado recebe informações diretas de parceiros que atuam em campo. Neste caso só são consideradas fontes conhecidas, com as quais já existe relacionamento prévio, como coletivos, comunicadores e moradores que atuam localmente. A equipe do Fogo Cruzado também adiciona às bases de dados as informações coletadas via imprensa e canais das autoridades policiais.             Quando chega a notificação de um tiroteio ou disparo de arma de fogo, esta informação não é automaticamente publicada no mapa e nas redes sociais. Imediatamente, a equipe de gestão de dados cruza a notificação com scripts e filtros desenvolvidos para agregar informações em redes sociais sobre disparos de arma de fogo na região metropolitana do Rio de Janeiro e Recife. `
    },
    {
      id: '12',
      question: 'A checagem de tiros é feita por robôs ou por pessoas?',
      answer: `A checagem é realizada pela equipe de analistas de dados do Fogo Cruzado.`
    },
    {
      id: '13',
      question:
        'Qual a margem de tempo entre um e outro registro para ser considerado fato?',
      answer: `Cada situação envolvendo disparo de arma de fogo é registrada apenas uma vez no nosso sistema, mesmo que envolva múltiplos tiros ou que dure por alguns minutos. Existem apenas 2 exceções envolvendo situações de tiroteios. A primeira diz respeito a um critério temporal. Se ocorrer um tiroteio em uma região que dure mais de 1 hora, ele será registrado mais de uma vez - a cada hora completada desde o início do tiroteio, ele será registrado novamente, e se durar mais de 2 horas será considerado um tiroteio contínuo. A segunda exceção diz respeito a um critério espacial. Se ocorrer um tiroteio em múltiplas localidades de um conjunto de favelas, será realizado um registro para cada favela onde há tiroteio, mesmo que esteja ocorrendo ao mesmo tempo (exemplo: Tiros na Vila do João, Nova Holanda e Parque União, no Conjunto de Favelas da Maré).`
    },
    {
      id: '14',
      question: 'Por que não consideram Petrópolis na contagem?',
      answer: `Optamos por não contabilizar Petrópolis para não desorganizar a continuidade histórica da série de dados, já que a cidade passou a fazer parte da região metropolitana do Rio em 2018, depois que o Fogo Cruzado já estava em operação há 2 anos.`
    }
  ]

  return (
    <Container>
      <div>
        <Helmet title="Perguntas frequentes - Fogo Cruzado" />
        <h3>Perguntas frequentes</h3>
        <div>
          {questions.map((id: any, index: any) => {
            return (
              <Acordion
                key={id.id}
                onClick={() => {
                  setExpense(index == expense ? null : index)
                }}
              >
                <div>
                  <p>{id.question}</p>
                  {index != expense ? (
                    <BtnShow
                      onClick={() => {
                        setExpense(index == expense ? null : index)
                      }}
                    >
                      +
                    </BtnShow>
                  ) : (
                    <BtnHide
                      onClick={() => {
                        setExpense(index == expense ? null : index)
                      }}
                    >
                      -
                    </BtnHide>
                  )}
                </div>
                {index === expense && [<div>{id.answer}</div>]}
              </Acordion>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default PerguntasFrequentes
