import { Box, CircularProgress, Modal, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'

import AboutApi from '../screens/Api'
import ProduzaSeusDados from '../screens/Dados/ProduzaSeusDados'
import Relatotios from '../screens/Dados/Relatorios'
import Reports from '../screens/Dados/Relatorios/post'
import TempoReal from '../screens/Dados/TempoReal'
import FaleConosco from '../screens/FaleConosco'
import Home from '../screens/Home'
import Impacto from '../screens/Impacto'
import InformeSe from '../screens/InformeSe'
import Postagem from '../screens/InformeSe/postagem'
import NewsLatter from '../screens/NewsLatter'
import PerguntasFrequentes from '../screens/PerguntasFrequentes'
import Pesquisar from '../screens/Pesquisar'
import PesquisaSite from '../screens/PesquisaSite'
import PoliticaDePrivacidade from '../screens/PoliticaDePrivacidade'
import SejaApoiador from '../screens/SejaUmApoiador'
import Sobre from '../screens/Sobre'
import SocialMedia from '../screens/SocialMedia'
import Transparencia from '../screens/Transparencia'
import { RootState } from '../store'

import logo from '../../src/assets/logo-dark.png'
import PoliticaDePrivacidadeApp from '../screens/PoliticaDePrivacidade/politic'

const AuthRoutes: React.FC = () => {
  const [verification, setVerification] = useState(true)

  const {
    token,
    user,
    home,
    posts,
    about,
    about_api,
    transparencies,
    social_networks,
    posts_news,
    image_sm,
    reports
  } = useSelector((state: RootState) => state.clickState)
  useEffect(() => {
    if (
      token !== undefined &&
      home !== undefined &&
      posts !== undefined &&
      about !== undefined &&
      about_api !== undefined &&
      transparencies !== undefined &&
      social_networks !== undefined &&
      image_sm !== undefined &&
      user !== undefined &&
      posts_news !== undefined &&
      reports !== undefined
    ) {
      setInterval(() => setVerification(false), 5000)
    }
  }, [
    token,
    home,
    posts,
    about,
    about_api,
    transparencies,
    social_networks,
    user,
    posts_news,
    reports,
    image_sm
  ])

  const style = {
    position: 'absolute' as 'absolute',
    top: '0',
    left: '0',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#FFF'
  }

  let locality = useLocation()

  console.log(locality)

  return (
    <>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/noticias" element={<InformeSe />} />
        <Route path="/:slug" element={<Postagem />} />
        <Route path="/API" element={<AboutApi />} />
        <Route path="/transparencia" element={<Transparencia />} />
        <Route path="/fale-conosco" element={<FaleConosco />} />
        <Route path="/seja-um-apoiador" element={<SejaApoiador />} />
        <Route path="/redes-sociais" element={<SocialMedia />} />
        <Route path="/newslatter" element={<NewsLatter />} />
        <Route path="/dados/tempo-real" element={<TempoReal />} />
        <Route
          path="/dados/produza-seus-dados"
          element={<ProduzaSeusDados />}
        />
        <Route path="/dados/relatorios" element={<Relatotios />} />
        <Route path="/dados/relatorios/:slug" element={<Reports />} />
        <Route path="/perguntas-frequentes" element={<PerguntasFrequentes />} />
        <Route path="/impacto" element={<Impacto />} />
        <Route
          path="/politica-de-privacidade"
          element={<PoliticaDePrivacidade />}
        />
        <Route path="/pesquisa" element={<Pesquisar />} />
        <Route path="/pesquisa-site" element={<PesquisaSite />} />
        <Route
          path="/politica/politica-de-privacidade"
          element={<PoliticaDePrivacidade />}
        />
      </Routes>

      {locality.pathname !== '/politica/politica-de-privacidade' && (
        <Modal
          open={verification}
          onClose={() => setVerification(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, width: '100%' }}>
            <img src={logo} alt="logo" style={{ maxWidth: '70%' }} />
            <Stack spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </Box>
        </Modal>
      )}
    </>
  )
}

export default AuthRoutes
