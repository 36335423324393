import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { IProps } from './types'

export const Container = styled.div`
  width: 100%;
  padding-top: 140px;
  > div {
    margin: 0 auto;
    max-width: 1920px;
  }

  a {
    cursor: pointer;
  }

  p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
  }

  blockquote {
    border-left: 3px solid #000;
    padding: 0 0 0 8px;
    text-indent: 0px;
    text-align: justify;
    margin: 25px 0;
  }

  @media (max-width: 575.98px) {
    padding-top: 50px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding-top: 70px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding-top: 60px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding-top: 60px;
  }
`

export const Header = styled.div<IProps>`
  width: 100%;
  background-image: url(${props => `${props.background}`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 60vh;
  padding: 42px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 100px;
  }

  @media (max-width: 991.98px) {
    height: 30vh;
    padding: 16px;
    > div:nth-child(2) {
      display: block;
      padding: 0;
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
  }
`

export const HeaderResp = styled.div`
  @media (max-width: 991.98px) {
    height: 25vh;
    padding: 16px;
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 100px;
    }
  }

  @media (min-width: 992px) {
    display: none;
  }
`

export const ButtonNavBack = styled(NavLink)`
  background-color: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;

  > img {
    width: 32px;
    height: 32px;
    transform: rotateY(180deg);
  }
`

export const Download = styled.a`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.tertiary};
  background-color: #fff;

  > p {
    margin-left: 10px;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`

export const DownloadResp = styled.a`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  margin: 0 auto;
  width: 250px;
  color: ${props => props.theme.colors.tertiary};
  border: 1px solid ${props => props.theme.colors.tertiary};
  background-color: #fff;

  > p {
    margin-left: 10px;
  }
`

export const TopContent = styled.div`
  > p {
  }

  > h3 {
    font-weight: 700;
    font-size: 34px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  > div {
    display: flex;

    > div {
      display: flex;
      align-items: center;
      margin-right: 15px;

      > img {
        margin-right: 10px;
      }

      > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
    }
  }

  @media (max-width: 991.98px) {
    > h3 {
      text-align: left;
    }

    > div {
      display: none;
    }
  }
`

export const TopContentResp = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    > img {
      margin-right: 10px;
    }
  }
`

export const SocialShare = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 991.98px) {
    display: none;
  }
`

export const SocialShareResp = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
`

export const Article = styled.div`
  padding: 40px 190px;
  max-width: 1920px;
  text-align: justify;

  @media (max-width: 991.98px) {
    padding: 16px;
    margin-top: 40px;
  }
`

export const TextOneBlock = styled.div``

export const TextTwoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    width: 48%;
    height: auto;
    text-align: justify;
    margin: 20px 0;
    > blockquote {
      border-left: 3px solid #000;
      padding: 0 0 0 8px;
      text-indent: 0px;
      text-align: justify;
      margin: 25px 0;
    }
  }

  @media (max-width: 575.98px) {
    flex-direction: column;

    > div {
      width: auto;
      margin: 20px 0;
      > blockquote {
        border-left: 3px solid #000;
        padding: 0 0 0 8px;
        text-indent: 0px;
        text-align: justify;
        margin: 25px 0;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-direction: column;

    > div {
      width: auto;
      margin: 20px 0;
      > blockquote {
        border-left: 3px solid #000;
        padding: 0 0 0 8px;
        text-indent: 0px;
        text-align: justify;
        margin: 25px 0;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    > div {
      width: auto;
      margin: 20px 0;
      > blockquote {
        border-left: 3px solid #000;
        padding: 0 0 0 8px;
        text-indent: 0px;
        text-align: justify;
        margin: 25px 0;
      }
    }
  }
`

export const ImgOneBlock = styled.div`
  margin: 20px 0;
  > div {
    > img {
      width: 100%;
      height: 360px;
      object-fit: cover;
    }
    > p {
      text-align: center;
      color: #000000;
      opacity: 0.5;
    }
  }
`

export const ImgTwoBlock = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > div {
    width: 48%;
    > img {
      width: 100%;
      height: 552px;
      object-fit: cover;
    }
    > p {
      text-align: center;
      color: #000000;
      opacity: 0.5;
    }
  }

  @media (max-width: 991.98px) {
    flex-direction: column;
    > div {
      width: 100%;

      > img {
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    flex-direction: column;
    > div {
      width: 100%;

      > img {
        margin-bottom: 20px;
      }
    }
  }
`
