import styled from 'styled-components'
import CountUp from 'react-countup'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-right: 60px;
  padding-left: 60px;

  :nth-child(1) {
    border-right: 1px solid ${props => props.theme.colors.gray};
  }
  :nth-child(2) {
    border-right: 1px solid ${props => props.theme.colors.gray};
  }
  :nth-child(3) {
    border-right: 1px solid ${props => props.theme.colors.gray};
  }

  @media only screen and (max-width: 575.98px) {
    display: flex;
    flex-direction: column;

    :nth-child(1) {
      border-right: none;
    }
    :nth-child(2) {
      border-right: none;
    }
    :nth-child(3) {
      border-right: none;
    }
  }

  @media (min-width: 576px) and (max-width: 775.98px) {
    :nth-child(1) {
      border-right: none;
    }
    :nth-child(2) {
      border-right: none;
    }
    :nth-child(3) {
      border-right: none;
    }
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    padding-right: 20px;
    padding-left: 20px;

    :nth-child(1) {
      border-right: none;
    }
    :nth-child(2) {
      border-right: none;
    }
    :nth-child(3) {
      border-right: none;
    }
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 243px;

  @media (min-width: 1200px) and (max-width: 1600.98px) {
    width: 200px;
  }
`

export const CardFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Img = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 8px;
`

export const Number = styled(CountUp)`
  color: ${props => props.theme.colors.white};
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 34px;
`

export const Text = styled.p`
  color: ${props => props.theme.colors.white};
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
  opacity: 0.6;

  @media (min-width: 1200px) and (max-width: 1600.98px) {
    line-height: 28px;
  }
`
