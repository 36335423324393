import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Banner = styled.img`
  width: 100%;
  height: 189px;
  object-fit: cover;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
`

export const Title = styled.h3`
  color: #2c394190;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin: 0px 0 0 20px;

  @media only screen and (max-width: 575.98px) {
    margin-bottom: 8px !important;
    line-height: 18px;
  }
`

export const Card = styled(NavLink)`
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);
  width: 400px;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden !important;
  text-decoration: none;

  @media (max-width: 1366px) {
    width: 380px;
  }

  :hover {
    filter: drop-shadow(rgb(0 0 0 / 35%) 0px 0px 30px);
  }

  :hover ${Title} {
    color: ${props => props.theme.colors.tertiary};
    transition: 0.3s;
  }

  :hover ${Banner} {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
`

export const Region = styled.p`
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.gray};
  padding: 2px 8px;
  border-radius: 50px;
  width: max-content;
  margin: 14px 0 0px 20px;

  @media only screen and (max-width: 575.98px) {
    padding: 2px 6px;
    margin: 24px 0 5px 20px;
  }
`

export const Content = styled.div`
  padding: 0px 20px 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > div:nth-child(1) {
    > div {
      display: flex;

      align-items: center;
      margin-bottom: 10px;
      max-height: 20px;
    }
  }

  > div {
    > div {
      > p {
        color: #5a5a5a;
      }
    }
  }

  @media only screen and (max-width: 575.98px) {
    margin: 0 !important;
    padding: 16px;
  }
`

export const Icon = styled.img`
  width: 20px;
  height: 22px;
  margin-right: 10px;
`

export const Button = styled(NavLink)`
  > img {
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
  }
`

export const Close = styled.button`
  float: right;
  margin-top: -20px;
  background: none;
`

export const ButtonFilter = styled.button`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.secondary};
  padding: 13px 35px;
`

export const ButtonClear = styled.button`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};
  padding: 13px 35px;
`
