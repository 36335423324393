import styled from 'styled-components'
import { DefaultBtn } from '../../components/DefaultButton/style'
import InputMask from 'react-input-mask'

export const Container = styled.div`
  width: 100%;
  > div {
    margin: 0 auto;
    max-width: 1920px;
    padding: 190px 0px 80px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      max-width: 700px;
    }
  }

  @media (max-width: 1294.98px) {
    > div {
      padding: 190px 16px 36px 16px;
      flex-direction: column;
    }
  }
`

export const SectionForm = styled.div`
  width: 400px;
  > form {
    > button {
      padding: 12px 18px;
      background-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.white};
      font-size: 16px;
      font-weight: 500;
      width: 170px;
    }
  }

  @media (max-width: 575.98px) {
    > form {
      display: flex;
      flex-direction: column;
      align-items: left;
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    > form {
      display: flex;
      flex-direction: column;
      align-items: left;
    }
  }

  @media (min-width: 768px) and (max-width: 1294.98px) {
    > form {
      display: flex;
      flex-direction: column;
      align-items: left;
    }
  }

  @media (min-width: 1295px) {
    > form {
      display: flex;
      flex-direction: column;
      align-items: left;

      input {
        min-width: 340px;
        max-width: 400px;
      }
    }
  }
`

export const Label = styled.p`
  margin-top: 15px !important;
  margin-bottom: 5px !important;
`

export const Textarea = styled.textarea`
  min-width: 340px;
  max-width: 340px;
  min-height: 100px;
  max-height: 100px;
  margin-bottom: 12px;
  resize: none;
  padding: 11px 6px;
  border: 1px solid ${props => props.theme.colors.tertiary};
`

export const SectionData = styled.div`
  color: ${props => props.theme.colors.tertiary};
  width: 400px;

  @media (max-width: 575.98px) {
    min-width: 375px;
    max-width: 575.98px;
    padding: 0 32px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    min-width: 576px;
    max-width: 767.98px;
    padding: 0 32px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    min-width: 768px;
    max-width: 991.98px;
    padding: 0 32px;
  }
`

export const Title = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 67px;
`

export const Block = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`

export const PhoneBlock = styled.div`
  display: flex;
  align-items: center;

  > a {
    text-decoration: none;
    border: none;
    background: none;
    > img {
      margin-left: 16px;
      width: 28px;
      height: 28px;
    }
  }
`

export const ImgFooter = styled.img`
  @media (max-width: 575.98px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    display: none;
  }

  @media (min-width: 992px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
  }
`

export const ImgMid = styled.img`
  @media (max-width: 575.98px) {
    max-width: 100% !important;
    height: 100%;
    margin: 20px 0;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    max-width: 100% !important;
    height: 100%;
    margin: 20px 0;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    max-width: 100% !important;
    height: 100%;
    margin: 20px 0;
  }

  @media (min-width: 992px) {
    display: none;
  }
`

export const InputMaskStyle = styled(InputMask)`
  padding: 11px 0 11px 6px;
  border: 1px solid ${props => props.theme.colors.tertiary};
  width: 340px !important;
`

export const Input = styled.input`
  padding: 11px 0 11px 6px;
  border: 1px solid ${props => props.theme.colors.tertiary};
  width: 340px !important;
`

export const Button = styled.button`
  padding: 12px 18px;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-weight: 500;
`
