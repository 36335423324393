import React from 'react'
import { Card } from '../ValuesAbout/style'

interface IProps {
  image: string
  title: string
  text: string
}

const ValuesAbout: React.FC<IProps> = props => {
  return (
    <Card>
      <div>
        <img src={props.image} />
        <div>{props.title}</div>
      </div>
      <div>{props.text}</div>
    </Card>
  )
}

export default ValuesAbout
