import React from 'react'
import { Radio } from '@mui/material'

interface IProps {
  name: string
  value: string
  selected?: boolean
  onSelect(choisee: string): void
}

const RadioBtn: React.FC<IProps> = props => {
  return (
    <Radio
      sx={{
        color: '#233237',
        '&.Mui-checked': {
          color: '#E0733D'
        },
        '&.MuiRadio-root': {
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: '10px',
          paddingBottom: 0
        }
      }}
      name={props.name}
      value={props.value}
      onChange={event => props.onSelect(event.target.value)}
      checked={props.selected}
    />
  )
}

export default RadioBtn
