import React, { useEffect, useState } from 'react'
import {
  Container,
  ContentSearch,
  ContentSelect,
  NewsContent,
  OldPosts,
  Select,
  Paginatior,
  Indicator,
  PrevNext,
  Loading
} from './style'
import { useDispatch, useSelector } from 'react-redux'
import Dados from '..'
import CardPost from '../../../components/CardPost'
import NewsPost from '../../../components/NewsPost'
import { convertData, getRegions, getReports } from '../../../functions'
import { RootState } from '../../../store'
import { GET_REPORTS } from '../../../store/actions'

import searchBtn from '../../../assets/searchBtn.png'
import icon from '../../../assets/learnMore.png'
import Spinner from 'react-bootstrap/Spinner'
import CircularProgress from '@mui/material/CircularProgress'

interface IResultMetadata {
  total: number
  hasNextPage: boolean
  hasPreviousPage: boolean
}

const Relatotios: React.FC = () => {
  const dispatch = useDispatch()
  const { token, reports } = useSelector((state: RootState) => state.clickState)
  const [regions, setRegions] = useState<any>()
  const [order, setOrder] = useState('')
  const [page, setPage] = useState(1)
  const [take, setTake] = useState('')
  const [search, setSearch] = useState('')
  const [region, setRegion] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [resultMetada, setResultMetada] = useState<IResultMetadata>({
    total: 0,
    hasNextPage: false,
    hasPreviousPage: false
  })

  useEffect(() => {
    getRegions(token).then(resp => {
      setRegions(resp)
    })
  }, [])

  async function getAllReports() {
    setIsLoading(true)
    await getReports(token, order, page, take, region, search).then(resp => {
      dispatch({ type: GET_REPORTS, reports: resp.data })
      setResultMetada({
        total: resp.meta.itemCount,
        hasNextPage: resp.meta.hasNextPage,
        hasPreviousPage: resp.meta.hasPreviousPage
      })
    })
    setIsLoading(false)
  }

  useEffect(() => {
    getAllReports()
  }, [token, order, page, take, region, search])

  return (
    <>
      <Dados />
      <Container>
        <ContentSearch>
          <fieldset>
            <input
              type="text"
              value={search}
              onChange={e => {
                setSearch(e.target.value)
              }}
            />
            <img src={searchBtn} alt="" />
          </fieldset>
          <ContentSelect>
            <fieldset>
              <label htmlFor="">Região</label>
              <Select
                name="regiao"
                onChange={e => {
                  setPage(1)
                  setRegion(e.target.value)
                }}
              >
                <option value="">Todos</option>
                {regions?.map((id: any) => {
                  if (id?.enabled == true) {
                    return <option value={id?.id}>{id?.region}</option>
                  }
                })}
              </Select>
            </fieldset>
            <fieldset>
              <label htmlFor="">Ordenar por</label>
              <Select
                name="ordem"
                onChange={e => {
                  setPage(1)
                  setOrder(e.target.value)
                }}
              >
                <option value="DESC">Decrescente</option>
                <option value="ASC">Crescente</option>
              </Select>
            </fieldset>
          </ContentSelect>
        </ContentSearch>

        {isLoading ? (
          <Loading>
            <CircularProgress color="inherit" />
          </Loading>
        ) : (
          <>
            <NewsContent>
              <div>
                {reports
                  .filter(post => {
                    if (search == '') {
                      return post
                    } else if (
                      post?.title
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                    ) {
                      return post
                    }
                  })
                  .map((id: any, index: number) => {
                    return (
                      <>
                        {index <= 1 ? (
                          <NewsPost
                            key={index}
                            to={`/dados/relatorios/${id?.slug}`}
                            img={id?.cover?.file}
                            local={id?.region?.state}
                            title={id?.title}
                            content={id?.description}
                            author={id?.authors}
                            data={convertData(id?.updatedAt)}
                          />
                        ) : (
                          <div style={{ display: 'none' }} />
                        )}
                      </>
                    )
                  })}
              </div>
            </NewsContent>
            <OldPosts>
              <div>
                {reports
                  .filter(post => {
                    if (search == '') {
                      return post
                    } else if (
                      post?.title
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                    ) {
                      return post
                    }
                  })
                  .map((id: any, index: number) => {
                    return (
                      <>
                        {index > 1 ? (
                          <CardPost
                            key={index}
                            to={`/dados/relatorios/${id?.slug}`}
                            img={id?.cover?.file}
                            local={id?.region?.state}
                            title={id?.title}
                            author={id?.authors}
                            date={convertData(id?.date_post)}
                          />
                        ) : (
                          <div style={{ display: 'none' }} />
                        )}
                      </>
                    )
                  })}
              </div>
            </OldPosts>
          </>
        )}
        <Paginatior>
          <PrevNext
            to="prev"
            onClick={() => {
              if (resultMetada.hasPreviousPage && page > 1) {
                let cont = page - 1
                setPage(cont)
              }
            }}
            disabled={!resultMetada.hasPreviousPage}
          >
            <img src={icon} alt="" />
          </PrevNext>
          <Indicator>{page}</Indicator>
          <PrevNext
            to="next"
            onClick={() => {
              if (resultMetada.hasNextPage) {
                let cont = page + 1
                setPage(cont)
              }
            }}
            disabled={!resultMetada.hasNextPage}
          >
            <img src={icon} alt="" />
          </PrevNext>
        </Paginatior>
      </Container>
    </>
  )
}

export default Relatotios
