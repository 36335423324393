import React, { useRef, useState } from 'react'
import {
  Container,
  ContainerLink,
  Card,
  CardLink,
  CardImg,
  Content,
  ContainerBtn,
  PreviousBtn,
  NextBtn,
  Section
} from '../OurPartners/style'
import renderHTML from 'react-render-html'
import ReactElasticCarousel from 'react-elastic-carousel'

import learnMore from '../../assets/learnMore.png'

type List = {
  image: string
  description: string
  name: string
}

interface IProps {
  partners: List[]
  page: string
}

const OurPartners: React.FC<IProps> = props => {
  const [div, setDiv] = useState('')
  const [hideDiv, setHideDiv] = useState(false)
  const [nameDiv, setNameDiv] = useState('')
  const ref = useRef<any>()

  const btnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const button: HTMLButtonElement = event.currentTarget
    setDiv(button.name)

    if (hideDiv == false && nameDiv == button.name) {
      setHideDiv(true)
      setNameDiv(button.name)
    } else {
      setHideDiv(false)
      setNameDiv(button.name)
    }
  }

  const breakPointsAwards = [
    { width: 1, itemsToShow: 1, HTMLFieldSetElement },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 4 },
    { width: 1920, itemsToShow: 5 }
  ]

  function button() {
    return <button style={{ display: 'none' }} />
  }

  console.log(hideDiv, div, nameDiv, 'div')

  return (
    <Section>
      <ContainerBtn>
        <PreviousBtn
          onClick={() => {
            ref.current.slidePrev()
          }}
        >
          <img src={learnMore} />
        </PreviousBtn>
        <NextBtn
          onClick={() => {
            ref.current.slideNext()
          }}
        >
          <img src={learnMore} />
        </NextBtn>
      </ContainerBtn>
      {/* @ts-ignore */}
      <ReactElasticCarousel
        isRTL={false}
        itemsToShow={5}
        ref={ref}
        breakPoints={breakPointsAwards}
        renderArrow={button}
        pagination={false}
        autoPlaySpeed={0.2}
      >
        {props.partners.map((items: any, index: number) => {
          return (
            <>
              {props.page == 'sobre' ? (
                <>
                  <Container key={index}>
                    <Card onClick={btnHandler} name={items.title}>
                      <CardImg>
                        <img src={items?.image?.file} />
                      </CardImg>
                    </Card>
                  </Container>
                </>
              ) : (
                <ContainerLink target="_blank" href={items.description}>
                  <img src={items?.image?.file} />
                </ContainerLink>
              )}
            </>
          )
        })}
      </ReactElasticCarousel>
      {props.partners.map((items: any, index: number) => {
        return (
          <Content hidden={hideDiv} key={index}>
            {items.title == div && (
              <p>
                {items.description.length > 0
                  ? renderHTML(items.description)
                  : ''}
              </p>
            )}
          </Content>
        )
      })}
    </Section>
  )
}

export default OurPartners
