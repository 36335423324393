import React from 'react'
import { Container, Title, Button } from './style'

import facebook from '../../assets/facebookColor.png'
import twitter from '../../assets/twitterColor.png'
import telegram from '../../assets/telegramColor.png'
import instagram from '../../assets/instagramColor.png'
import youtube from '../../assets/youtubeColor.png'
import linkedin from '../../assets/linkedinColor.png'
import whatsapp from '../../assets/whatsappColor.png'

interface IProps {
  facebook: string
  twitter: string
  telegram: string
  instagram: string
  youtube: string
  linkedin: string
  whatsapp: string
  title: string
}

const Social: React.FC<IProps> = props => {
  return (
    <Container>
      <Title>{props.title}</Title>
      <div>
        {props.facebook != '' ? (
          <Button href={props.facebook} target="_blank">
            <img src={facebook} />
          </Button>
        ) : (
          <p style={{ display: 'none' }}></p>
        )}
        {props.twitter != '' ? (
          <Button href={props.twitter} target="_blank">
            <img src={twitter} />
          </Button>
        ) : (
          <p style={{ display: 'none' }}></p>
        )}
        {props.telegram != '' ? (
          <Button href={props.telegram} target="_blank">
            <img src={telegram} />
          </Button>
        ) : (
          <p style={{ display: 'none' }}></p>
        )}
        {props.instagram != '' ? (
          <Button href={props.instagram} target="_blank">
            <img src={instagram} />
          </Button>
        ) : (
          <p style={{ display: 'none' }}></p>
        )}
        {props.youtube != '' ? (
          <Button href={props.youtube} target="_blank">
            <img src={youtube} />
          </Button>
        ) : (
          <p style={{ display: 'none' }}></p>
        )}
        {props.linkedin != '' ? (
          <Button href={props.linkedin} target="_blank">
            <img src={linkedin} />
          </Button>
        ) : (
          <p style={{ display: 'none' }}></p>
        )}
        {props.whatsapp != '' ? (
          <Button href={props.whatsapp} target="_blank">
            <img src={whatsapp} />
          </Button>
        ) : (
          <p style={{ display: 'none' }}></p>
        )}
      </div>
    </Container>
  )
}

export default Social
