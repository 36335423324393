import styled from 'styled-components'

export const Card = styled.div`
  width: 305px;
  text-align: center;
  margin-top: 20px;
  > div {
    color: ${props => props.theme.colors.tertiary};
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    margin-bottom: 12px;

    > img {
      width: 48px;
      height: 48px;
      margin-right: 12px;
      background: none;
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 45px;
      text-align: center;

      color: #233237;
    }

    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;

      color: #233237;
      /* width: 265px; */
    }
  }

  @media (max-width: 690px) {
    max-width: 343px;
    > div {
      justify-content: center;
      text-align: center;

      > img {
        width: 32px;
        height: 32px;
        margin-left: -20px;
      }

      h3 {
        font-size: 24px;
        line-height: 45px;
        text-align: center;
      }
    }
    > p {
      /* margin: 10px auto; */
      text-align: center;
      font-size: 16px;
      line-height: 25px;
    }
  }
`
