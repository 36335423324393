import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import {
  Container,
  Header,
  RespText,
  Section,
  SectionContent,
  SectionImg,
  SectionText,
  Text,
  Line,
  Title,
  Card
} from './style'

type Person = {
  image?: string
  group?: string
  role?: string
  name?: string
  description?: string
  title?: string
}

interface IProps {
  team: Person[]
}

const Team: React.FC = () => {
  const { about } = useSelector((state: RootState) => state.clickState)
  const [div, setDiv] = useState('Cecília Olliveira')
  const [nameDiv, setNameDiv] = useState('Cecília Olliveira')
  const [hideDiv, setHideDiv] = useState(false)

  const btnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const button: HTMLButtonElement = event.currentTarget
    setDiv(button.name)

    if (hideDiv == false && nameDiv == button.name) {
      setHideDiv(true)
      setNameDiv(button.name)
    } else {
      setHideDiv(false)
      setNameDiv(button.name)
    }
  }

  return (
    <Container>
      {about?.map((id: any) => {
        return (
          <>
            <Header>
              <section>
                {id?.name == 'Bloco 4' && (
                  <>
                    {id?.items.map((items: any) => {
                      return (
                        <>
                          <Card>
                            <div>
                              {items.person?.map((persons: any) => {
                                return (
                                  <div>
                                    <button
                                      onClick={btnHandler}
                                      name={persons.name}
                                    >
                                      <img
                                        src={persons.image?.file}
                                        alt="img person"
                                      />
                                    </button>
                                  </div>
                                )
                              })}
                            </div>
                          </Card>
                        </>
                      )
                    })}
                  </>
                )}
              </section>
            </Header>
            {id?.name == 'Bloco 4' && (
              <>
                {id?.items.map((items: any) => {
                  return (
                    <>
                      <Section hidden={hideDiv}>
                        {items.person?.map((persons: any) => {
                          if (persons.name == div) {
                            return (
                              <>
                                <SectionContent>
                                  <SectionImg>
                                    <img
                                      src={persons.image?.file}
                                      alt="img person"
                                    />
                                  </SectionImg>
                                  <SectionText>
                                    <p>
                                      <b>{persons.name}</b>, {persons.role}
                                    </p>
                                    <Line />
                                    <Text>{persons.description}</Text>
                                  </SectionText>
                                </SectionContent>
                                <RespText>{persons.description}</RespText>
                              </>
                            )
                          }
                        })}
                      </Section>
                    </>
                  )
                })}
              </>
            )}
          </>
        )
      })}
    </Container>
  )
}

export default Team
