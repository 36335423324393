import React, { useEffect } from 'react'
import { Container, Image, Block } from './style'

import smartphone from '../../assets/phoneRight.png'
import Social from '../../components/Social'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getImageSocialMedia, getSocialNetwork } from '../../functions'
import { GET_IMAGE_SOCIAL_MEDIA, GET_SOCIAL_NETWORK } from '../../store/actions'
import { Helmet } from 'react-helmet'

const SocialMedia: React.FC = () => {
  const dispatch = useDispatch()
  const { token, social_networks, image_sm } = useSelector(
    (state: RootState) => state.clickState
  )

  useEffect(() => {
    if (social_networks == undefined) {
      getSocialNetwork(token).then(resp => {
        dispatch({ type: GET_SOCIAL_NETWORK, social_networks: resp })
        console.log(resp, 'social_networks')
      })
      getImageSocialMedia(token).then(resp => {
        dispatch({ type: GET_IMAGE_SOCIAL_MEDIA, image_sm: resp })
        console.log(resp, 'social_networks_image')
      })
    }
  }, [social_networks])

  useEffect(() => {
    console.log(social_networks, image_sm, 'social_networks')
  }, [social_networks, image_sm])

  return (
    <Container>
      <Helmet title="Redes sociais - Fogo Cruzado" />
      <div>
        <Block>
          <div>
            <h3>Redes sociais</h3>
            <div>
              {social_networks?.map((id: any) => {
                return (
                  <Social
                    title={
                      id?.region?.state == 'Geral'
                        ? 'Institucional'
                        : id?.region?.state
                    }
                    facebook={id?.facebook}
                    twitter={id?.twitter}
                    telegram={id?.telegram}
                    instagram={id?.instagram}
                    youtube={id?.youtube}
                    linkedin={id?.linkedin}
                    whatsapp={id?.whatsapp}
                  />
                )
              })}
            </div>
          </div>
          <img src={image_sm?.file} alt="smatphone" />
        </Block>
      </div>
    </Container>
  )
}

export default SocialMedia
