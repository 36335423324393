import React, { useState, useEffect } from 'react'
import { FiSearch, FiX, FiMenu } from 'react-icons/fi'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer
} from '@react-google-maps/api'

import moment from 'moment'

import TertiaryButton from '../../../components/RadiosButton'
import Occurence from '../../../components/Occurrence'
import Dados, {
  Position,
  MAP_CENTER,
  INIT_FORMAT,
  FINAL_FORMAT,
  isEmpty,
  hasShotedPeople,
  filterAvailableOccurrences
} from '..'

import mapClusterPng from '../../../assets/mapCluster.png'
import alertRedPng from '../../../assets/alertRed.png'
import alertYellowPng from '../../../assets/alertYellow.png'

import CONSTANTS from '../../../constants'
import { api } from '../../../api'
import * as S from './style'

const PERIODS = [
  { key: 'ALL', name: 'Tudo' },
  { key: 'TODAY', name: 'Hoje' },
  { key: 'YESTERDAY', name: 'Ontem' },
  { key: 'LAST_WEEK', name: 'Ultima semana' }
]

const TempoReal: React.FC = () => {
  const defaultPeriod = PERIODS[0].key

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONSTANTS.GOOGLE_MAPS_SECRET_KEY,
    language: 'pt-br',
    libraries: ['places']
  })

  const [searchParam, setSearchParam] = useState<string>('')
  const [selectedPeriod, setSelectedPeriod] = useState<string>(defaultPeriod)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true)
  const [currentRequestPage, setCurrentRequestPage] = useState<number>(1)

  const [occurrences, setOccurrences] = useState<Array<any>>([])
  const [currentPosition, setCurrentPosition] = useState<Position>(
    {} as Position
  )
  const [selectedPosition, setSelectedPosition] = useState<Position>(
    {} as Position
  )

  const handleClearSearchInput = () => {
    setOccurrences([])
    setSearchParam('')
    handleGetOccurrences('')
    setCurrentRequestPage(1)
  }

  const handleSelectMarker = ({ latLng }: any) => {
    const { lat, lng } = latLng

    setIsMenuOpen(true)
    setSelectedPosition({
      lat: lat(),
      lng: lng()
    })
  }

  const handleSelectPeriod = (period: string) => {
    setOccurrences([])
    setSelectedPeriod(period)
    setCurrentRequestPage(1)
  }

  const handleGetOccurrences = async (search: string) => {
    setIsLoading(true)
    setSelectedPosition({} as Position)

    try {
      let url = `/occurrences?page=${currentRequestPage}&`
      if (search) url += `search=${search}&`

      if (selectedPeriod !== defaultPeriod) {
        let date = moment()
        const [_, todayPeriod, yesterdayPeriod, lastWeekPeriod] = PERIODS

        if (selectedPeriod === todayPeriod.key) {
          const initialDate = date.format(INIT_FORMAT)
          const finalDate = date.format(FINAL_FORMAT)

          url += `initialdate=${initialDate}&finaldate=${finalDate}&`
        }

        if (selectedPeriod === yesterdayPeriod.key) {
          const initialDate = date.subtract(1, 'day').format(INIT_FORMAT)
          const finalDate = date.format(FINAL_FORMAT)

          url += `initialdate=${initialDate}&finaldate=${finalDate}&`
        }

        if (selectedPeriod === lastWeekPeriod.key) {
          const initialDate = date.subtract(7, 'days').format(INIT_FORMAT)
          const finalDate = date.add(7, 'days').format(FINAL_FORMAT)

          url += `initialdate=${initialDate}&finaldate=${finalDate}&`
        }
      }

      const { data: response } = await api.get(url)

      if (response && response.data.length > 0) {
        const newOccurrences = filterAvailableOccurrences(response.data)

        if (currentRequestPage > 1) {
          setOccurrences(previusOccurrences =>
            previusOccurrences.concat(newOccurrences)
          )
        } else {
          setOccurrences(newOccurrences)
        }
      }
    } catch (err) {
      console.log('Error: ', err)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
        },
        err => {
          console.log('Error while geting user location!', err)
        }
      )
    } else {
      console.log('Browser does not support geolocation feature!')
    }
  }, [])

  useEffect(() => {
    handleGetOccurrences(searchParam)
  }, [searchParam, selectedPeriod, currentRequestPage])

  return (
    // <>
    //     <Dados />

    //     <S.Container>
    //         <S.ContainerLeft isOpen={ isMenuOpen }>
    //             <S.InputBox>
    //                 <input
    //                     type="text"
    //                     placeholder="Pesquisar"
    //                     value={ searchParam }
    //                     onChange={ event => setSearchParam(event.target.value) }
    //                 />

    //                 {
    //                     searchParam ? <FiX onClick={ handleClearSearchInput } /> : <FiSearch />
    //                 }
    //             </S.InputBox>

    //             <S.SetDados>
    //                 <button>Registros próximos</button>
    //                 <button>Últimos registros</button>
    //             </S.SetDados>

    //             <S.ButtonsSearch>
    //                 {
    //                     PERIODS.map(period => (
    //                         <TertiaryButton
    //                             key={ period.key }
    //                             text={ period.name }
    //                             selected={ selectedPeriod === period.key }
    //                             onClick={ () => handleSelectPeriod(period.key) }
    //                         />
    //                     ))
    //                 }
    //             </S.ButtonsSearch>

    //             <S.ContainerOccurence>
    //                 {
    //                     isLoading ? (
    //                         <S.Message>
    //                             Carregando ocorrencias...
    //                         </S.Message>
    //                     ) : occurrences.length > 0 ? occurrences.map(occurrence => (
    //                         <Occurence
    //                             key={ occurrence.id }
    //                             occurrence={ occurrence }
    //                             selected={
    //                                 selectedPosition.lat === Number(occurrence.latitude) &&
    //                                 selectedPosition.lng === Number(occurrence.longitude)
    //                             }
    //                             onClick={
    //                                 () => setSelectedPosition({
    //                                     lat: Number(occurrence.latitude),
    //                                     lng: Number(occurrence.longitude)
    //                                 })
    //                             }
    //                         />
    //                     )) : (
    //                         <S.Message>
    //                             Sem ocorrencias!
    //                         </S.Message>
    //                     )
    //                 }

    //                 {
    //                     (!isLoading && occurrences.length > 0) && (
    //                         <S.LoadMore onClick={ () => setCurrentRequestPage(currentRequestPage + 1) }>
    //                             Carregar mais
    //                         </S.LoadMore>
    //                     )
    //                 }
    //             </S.ContainerOccurence>
    //         </S.ContainerLeft>

    //         <S.ContainerRight>
    //             <S.ToggleMenu onClick={ () => setIsMenuOpen(!isMenuOpen) }>
    //                 { isMenuOpen ? <FiX /> : <FiMenu /> }
    //             </S.ToggleMenu>

    //             {
    //                 isLoaded ? (
    //                     <S.Map>
    //                     {/* @ts-ignore */}
    //                         <GoogleMap
    //                             mapContainerStyle={{ width: '100%', height: '100%' }}
    //                             options={{ disableDefaultUI: true }}
    //                             zoom={ !isEmpty(selectedPosition) ? 15 : 10 }
    //                             center={
    //                                 !isEmpty(selectedPosition) ? selectedPosition :
    //                                 !isEmpty(currentPosition) ? currentPosition : MAP_CENTER
    //                             }
    //                         >
    //                             {/* @ts-ignore */}
    //                             <MarkerClusterer
    //                                 averageCenter={ true }
    //                                 styles={[{ width: 1, height: 1, url: mapClusterPng, className: 'map__clusterer' }]}
    //                                 children={
    //                                     //@ts-ignore
    //                                     clusterer => occurrences.map(occurrence => (
    //                                         //@ts-ignore
    //                                         <Marker
    //                                             icon={ hasShotedPeople(occurrence) ? alertRedPng : alertYellowPng }
    //                                             onClick={ handleSelectMarker }
    //                                             clusterer={ clusterer }
    //                                             position={{
    //                                                 lat: Number(occurrence.latitude),
    //                                                 lng: Number(occurrence.longitude)
    //                                             }}
    //                                         />
    //                                     ))
    //                                 }
    //                             />
    //                         </GoogleMap>
    //                     </S.Map>
    //                 ) : (
    //                     <S.Message>
    //                         Carregando mapa...
    //                     </S.Message>
    //                 )
    //             }
    //         </S.ContainerRight>
    //     </S.Container>
    // </>

    <>
      <Dados />
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <h1>Em breve!</h1>
      </div>
    </>
  )
}

export default TempoReal
