import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

export const Image = styled.img`
  width: 305px !important;
  height: 320px !important;
  object-fit: cover;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 12px;
  color: #2c394190;
`

export const Card = styled.div`
  padding: 24px;
  width: clamp(325px, 325px, 100%);
  > div {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 80%;
  }
`

export const Container = styled(NavLink)`
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);
  display: flex;
  flex-direction: row;
  width: clamp(100%, 630px, 800px);
  padding: 0;
  cursor: pointer;
  overflow: hidden !important;
  text-decoration: none;
  color: ${props => props.theme.colors.tertiary};

  :hover {
    filter: drop-shadow(rgb(0 0 0 / 35%) 0px 0px 30px);
  }
  :hover ${Title} {
    color: ${props => props.theme.colors.tertiary};
    transition: 0.3s;
  }
  :hover ${Image} {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  @media (max-width: 991.98px) {
    display: none;
  }

  @media (min-width: 992px) {
    display: flex;
  }
`

export const Locale = styled.p`
  background: ${props => props.theme.colors.gray};
  padding: 10px;
  border-radius: 50px;
  margin-bottom: 12px;
  width: max-content;
  margin-left: 5px;
`

export const Content = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  width: 100%;
`

export const Icon = styled.img`
  margin-right: 10px;
`

export const ToNotice = styled(Link)`
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 15px;
  right: 15px;

  > img {
    width: 48px;
    height: 48px;
  }
`

export const Author = styled.div`
  display: flex;
`

export const BannerResp = styled.img`
  width: 100%;
  height: 189px;
  object-fit: cover;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
`

export const TitleResp = styled.h3`
  color: #2c394190;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin: 0px 0 0 20px;

  @media only screen and (max-width: 575.98px) {
    margin-bottom: 8px !important;
    line-height: 18px;
  }
`

export const CardResp = styled(NavLink)`
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);
  width: 400px;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden !important;
  text-decoration: none;

  @media (max-width: 1366px) {
    width: 380px;
  }

  :hover {
    filter: drop-shadow(rgb(0 0 0 / 35%) 0px 0px 30px);
  }

  :hover ${TitleResp} {
    color: ${props => props.theme.colors.tertiary};
    transition: 0.3s;
  }

  :hover ${BannerResp} {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  @media (min-width: 992px) {
    display: none;
  }
`

export const RegionResp = styled.p`
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.gray};
  padding: 2px 8px;
  border-radius: 50px;
  width: max-content;
  margin: 14px 0 0px 20px;

  @media only screen and (max-width: 575.98px) {
    padding: 2px 6px;
    margin: 24px 0 5px 20px;
  }
`

export const ContentResp = styled.div`
  padding: 0px 20px 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > div:nth-child(1) {
    > div {
      display: flex;

      align-items: center;
      margin-bottom: 10px;
      max-height: 20px;
    }
  }

  > div {
    > div {
      > p {
        color: #5a5a5a;
      }
    }
  }

  @media only screen and (max-width: 575.98px) {
    margin: 0 !important;
    padding: 16px;
  }
`

export const IconResp = styled.img`
  width: 20px;
  height: 22px;
  margin-right: 10px;
`

export const ButtonResp = styled(NavLink)`
  > img {
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
  }
`

export const CloseResp = styled.button`
  float: right;
  margin-top: -20px;
  background: none;
`
