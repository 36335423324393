import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled.div`
  background-color: ${props => props.theme.colors.tertiary};
  width: 100%;
  padding: 40px 80px;
  position: relative;
  bottom: 0;

  > div {
    max-width: 1920px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 980px) {
    display: none;
    width: 0% !important;
    height: 0% !important;
  }
`

export const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Logo = styled.img`
  width: 229px;
  height: 36px;

  @media only screen and (max-width: 1214px) {
    display: none;
  }
`

export const Navegacao = styled.nav`
  display: flex;
  flex-direction: column;

  p {
    color: ${props => props.theme.colors.white};
    opacity: 0.5;
    margin-bottom: 12px;
  }
`

export const LinkNav = styled(NavLink)`
  color: ${props => props.theme.colors.white};
  padding: 8px 0;

  &:hover,
  &.active {
    color: ${props => props.theme.colors.white};
    opacity: 100;
  }
`

export const SocialMedia = styled.div`
  p {
    color: ${props => props.theme.colors.white};
    opacity: 0.5;
    margin-bottom: 12px;
  }

  button {
    background: none;
    border: none;
    width: 31px;
    height: 31px;
    margin-right: 5px;

    > a {
      img {
        height: 16px;
      }
    }
  }
`

export const NewsLetter = styled.div`
  width: 375px;
  h3 {
    color: ${props => props.theme.colors.white};
    margin-bottom: 16px;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      width: 204px;
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-top: 20px;
      margin-right: 60px;
      color: ${props => props.theme.colors.white};
    }

    button {
    }
  }
`

export const Input = styled.input`
  width: 375px;
  height: 47px;
  font-weight: 400;
  font-size: 16px;
  padding-left: 8px;
`

export const Creative = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 100px;
    height: 25px;
    margin-right: 32px;
  }

  p {
    color: ${props => props.theme.colors.white};
  }
`

export const ContainerResp = styled.div`
  background: ${props => props.theme.colors.tertiary};
  padding: 20px 16px;
  display: flex;
  flex-direction: column;

  > h4 {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 16px;
    color: ${props => props.theme.colors.white};
  }

  > h2 {
    font-size: 10px;
    line-height: 20px;
    color: ${props => props.theme.colors.white};
    opacity: 0.5;
    margin-top: 28px;
  }

  > input {
    padding: 8px 16px;
    color: ${props => props.theme.colors.tertiary};
    background-color: ${props => props.theme.colors.white};
    display: inline-block;
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;

    > p {
      font-size: 12px;
      line-height: 20px;
      color: #ffffff;
      max-width: 400px;
    }
  }

  > nav {
    display: flex;
    flex-direction: column;
    > h4 {
      font-size: 10px;
      line-height: 18px;
      color: #ffffff;
      opacity: 0.5;
      margin-bottom: 16px;
    }
  }

  > section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (min-width: 981px) {
    display: none;
    width: 0 !important;
    height: 0 !important;
  }
`

export const BtnResp = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  padding: 13px 31.5px;
`

export const LinkResp = styled(NavLink)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: ${props => props.theme.colors.white};
  margin-bottom: 16px;
`
