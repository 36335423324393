export const SET_TOKEN = 'SET_TOKEN'
export const GET_USER = 'GET_USER'
export const GET_HOME = 'GET_HOME'
export const GET_ABOUT = 'GET_ABOUT'
export const GET_ABOUT_API = 'GET_ABOUT_API'
export const GET_TEAM = 'GET_TEAM'
export const GET_PARTNERS = 'GET_PARTNERS'
export const GET_AWARDS = 'GET_AWARDS'
export const GET_NEWS = 'GET_NEWS'
export const GET_SOCIAL_NETWORK = 'GET_SOCIAL_NETWORK'
export const GET_TRANSPARENCIES = 'GET_TRANSPARENCIES'
export const GET_IMPACT = 'GET_IMPACT'
export const GET_POSTS_NEWS = 'GET_POSTS_NEWS'
export const SET_SEARCH = 'SET_SEARCH'
export const GET_REPORTS = 'GET_REPORTS'
export const SET_SLUG = 'SET_SLUG'
export const GET_IMAGE_SOCIAL_MEDIA = 'GET_IMAGE_SOCIAL_MEDIA'
export const GET_STATISTICS = 'GET_STATISTICS'
