import React, { useEffect, useState } from 'react'
import {
  Container,
  Header,
  Article,
  ButtonNavBack,
  Download,
  TopContent,
  SocialShare,
  TextOneBlock,
  TextTwoBlock,
  ImgOneBlock,
  ImgTwoBlock,
  HeaderResp,
  SocialShareResp,
  TopContentResp,
  DownloadResp
} from './style'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  convertData,
  getReports,
  getReportsBySlug
} from '../../../../functions'
import { RootState } from '../../../../store'
import { GET_REPORTS } from '../../../../store/actions'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton
} from 'react-share'
import renderHTML from 'react-render-html'

import paper from '../../../../assets/white-paper.svg'
import calendar from '../../../../assets/white-calendar.svg'
import paperDark from '../../../../assets/paper.png'
import calendarDark from '../../../../assets/calendar.png'
import downloadIcon from '../../../../assets/download.svg'
import learnMore from '../../../../assets/learnMore.png'
import facebook from '../../../../assets/facebook.png'
import twitter from '../../../../assets/twitter.png'
import telegram from '../../../../assets/telegram.png'
import linkedin from '../../../../assets/linkedin.png'
import facebookDark from '../../../../assets/facebook-dark.png'
import twitterDark from '../../../../assets/twitter-dark.png'
import telegramDark from '../../../../assets/telegram-dark.png'
import linkedinDark from '../../../../assets/linkedin-dark.png'

const Reports: React.FC = () => {
  const dispatch = useDispatch()
  const { token, reports } = useSelector((state: RootState) => state.clickState)
  const [post, setPost] = useState<any>({
    title: '',
    author: '',
    region: '',
    file: {
      id: '',
      file: undefined
    },
    cover: {
      id: '',
      file: ''
    },
    file_width: '',
    file_name: '',
    thumbnail: {
      id: '',
      file: ''
    },
    date_post: '',
    authors: '',
    description: '',
    type: '',
    emphasis: false,
    items: [
      {
        group: '',
        group_items: [
          {
            image: {
              id: '',
              file: ''
            },
            description: '',
            content: ''
          }
        ]
      }
    ]
  })

  let { slug } = useParams()

  useEffect(() => {
    getReports(token, 'DESC', 1, '4').then(resp => {
      dispatch({ type: GET_REPORTS, reports: resp.data })
    })
    getReportsBySlug(token, slug).then(resp => {
      setPost(resp)
    })
  }, [])

  return (
    <Container>
      <div>
        {[post]?.map((id: any) => {
          const haveFile = id?.file?.file === undefined ? false : true
          return (
            <>
              <Header background={id?.cover?.file}>
                <div>
                  <ButtonNavBack to="/dados/relatorios">
                    <img src={learnMore} alt="" />
                  </ButtonNavBack>
                  {haveFile ? (
                    <Download
                      href={id?.file?.file}
                      download={id?.file_name}
                      target="_blank"
                    >
                      <img src={downloadIcon} alt="" />
                      <p>Baixar relatório</p>
                    </Download>
                  ) : null}
                </div>
                <div>
                  <TopContent>
                    <p>{id?.region?.state}</p>
                    <h3>{id?.title}</h3>
                    <div>
                      <div>
                        <img src={paper} alt="" />
                        <p>{id?.authors}</p>
                      </div>
                      <div>
                        <img src={calendar} alt="" />
                        <p>{convertData(id?.date_post)}</p>
                      </div>
                    </div>
                  </TopContent>
                  <SocialShare>
                  <FacebookShareButton
                    url={`https://webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
>
  <img
    src={facebook}
    alt="Facebook"
    style={{ width: '10.53px', height: '' }}
  />
</FacebookShareButton>
<TwitterShareButton
  url={`https://webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
>
  <img
    src={twitter}
    alt="Twitter"
    style={{ width: '10.53px', height: '' }}
  />
</TwitterShareButton>
                    <TelegramShareButton
                      url={`webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
                      title={id.title}
                    >
                      <img
                        src={telegram}
                        alt=""
                        style={{ width: '26.53px', height: '' }}
                      />
                    </TelegramShareButton>
                    <LinkedinShareButton
                      url={`webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
                      title={id.title}
                    >
                      <img
                        src={linkedin}
                        alt=""
                        style={{ width: '26.53px', height: '' }}
                      />
                    </LinkedinShareButton>
                  </SocialShare>
                </div>
              </Header>
              <HeaderResp>
                <TopContentResp>
                  <div>
                    <img src={paperDark} alt="" width={16.4} height={20} />
                    <p>{id?.authors}</p>
                  </div>
                  <div>
                    <img src={calendarDark} alt="" width={20} height={20} />
                    <p>{convertData(id?.updatedAt)}</p>
                  </div>
                </TopContentResp>

                <DownloadResp href={id?.file?.file} download={id?.file_name} target="_blank">
                  <img src={downloadIcon} alt="" />
                  <p>Baixar relatório</p>
                </DownloadResp>

                <SocialShareResp>
                <FacebookShareButton url={`webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
>
  <img
    src={facebookDark}
    alt="Facebook"
    style={{ width: '20px', height: '' }}
  />
</FacebookShareButton>

                  <TwitterShareButton
                    url={`webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
                    title={id.title}
                  >
                    <img
                      src={twitterDark}
                      alt=""
                      style={{ width: '26.53px', height: '' }}
                    />
                  </TwitterShareButton>
                  <TelegramShareButton
                    url={`webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
                    title={id.title}
                  >
                    <img
                      src={telegramDark}
                      alt=""
                      style={{ width: '26.53px', height: '' }}
                    />
                  </TelegramShareButton>
                  <LinkedinShareButton
                    url={`webstage.fogocruzado.org.br/dados/relatorios/${id?.id}`}
                    title={id.title}
                  >
                    <img
                      src={linkedinDark}
                      alt=""
                      style={{ width: '26.53px', height: '' }}
                    />
                  </LinkedinShareButton>
                </SocialShareResp>
              </HeaderResp>
              <Article>
                {id?.items.map((item: any) => {
                  if (item?.quantBlock == 1 && item?.typeBlock == 'text') {
                    return (
                      <TextOneBlock>
                        {item?.group_items.map((item_id: any) => {
                          return (
                            <div>
                              {item_id?.content.length > 0
                                ? renderHTML(item_id?.content)
                                : ''}
                            </div>
                          )
                        })}
                      </TextOneBlock>
                    )
                  }
                  if (item?.quantBlock == 2 && item?.typeBlock == 'text') {
                    return (
                      <TextTwoBlock>
                        {item?.group_items.map((item_id: any) => {
                          return (
                            <div>
                              {item_id?.content.length > 0
                                ? renderHTML(item_id?.content)
                                : ''}
                            </div>
                          )
                        })}
                      </TextTwoBlock>
                    )
                  }
                  if (item?.quantBlock == 1 && item?.typeBlock == 'image') {
                    return (
                      <ImgOneBlock>
                        {item?.group_items.map((item_id: any) => {
                          return (
                            <div>
                              <img src={item_id.image?.file} alt="" />
                              <p>{item_id?.description}</p>
                            </div>
                          )
                        })}
                      </ImgOneBlock>
                    )
                  }
                  if (item?.quantBlock == 2 && item?.typeBlock == 'image') {
                    return (
                      <ImgTwoBlock>
                        {item?.group_items.map((item_id: any) => {
                          return (
                            <div>
                              <img src={item_id.image?.file} alt="" />
                              <p>{item_id?.description}</p>
                            </div>
                          )
                        })}
                      </ImgTwoBlock>
                    )
                  }
                })}
              </Article>
            </>
          )
        })}
      </div>
    </Container>
  )
}

export default Reports
