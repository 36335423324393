import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

interface IProps {
  status?: any
  message?: any
  onValidated: any
}

const CustomForm: React.FC<IProps> = props => {
  const [email, setEmail] = useState('')

  const handleSubmit = (e: any) => {
    e.preventDefault()
    email &&
      email.indexOf('@') > -1 &&
      props.onValidated({
        EMAIL: email
      })
  }

  const DefaultBtn = styled.button`
    max-height: 47px;
    display: inline-block;
    color: ${props => props.theme.colors.white};
    padding: 12px 16px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    line-height: 23px;
    text-decoration: none;
    background: ${props => props.theme.colors.secondary};
  `

  useEffect(() => {
    if (props.status === 'success') setEmail('')
  }, [props.status])

  return (
    <>
      <form
        onSubmit={e => handleSubmit(e)}
        method="POST"
        action="https://fogocruzado.us5.list-manage.com/subscribe/post"
      >
        {props.status === 'sending' && (
          <p style={{ color: '#FFF' }}>Enviando cadastro...</p>
        )}
        {props.status === 'error' && (
          <p style={{ color: '#BF091F' }}>Erro ao enviar cadastro</p>
        )}
        {props.status === 'success' && (
          <p style={{ color: '#45BD5F' }}>Cadastro enviado com sucesso!!</p>
        )}
        <input type="hidden" name="u" value="82d9fdb2cb6bf9ddd084fc1a8"></input>
        <input type="hidden" name="id" value="b1b410212e"></input>
        <fieldset
          style={{
            display: 'flex'
          }}
        >
          <input
            style={{
              width: '100%',
              height: '47px',
              fontWeight: '400',
              fontSize: '16px',
              paddingLeft: '8px'
            }}
            onChange={e => setEmail(e.target.value)}
            type="email"
            value={email}
            placeholder="Email"
            id="MERGE0"
            name="MERGE0"
          />
          <DefaultBtn type="submit">Cadastrar</DefaultBtn>
        </fieldset>
      </form>
    </>
  )
}

export default CustomForm
