import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 180px;
  width: 100%;

  > div {
    > h3 {
      margin-bottom: 20px;
      text-align: center;
      color: #233237;
      font-weight: 700;
      font-size: 20px;
      line-height: 67px;
    }

    max-width: 1920px;
    margin: 0 auto;
    padding: 20px 80px;
    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  @media (max-width: 575.98px) {
    margin: 70px auto;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    margin: 70px auto;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin: 70px auto;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    margin: 70px auto;
  }
`

export const Acordion = styled.div`
  width: 50%;
  border: 1px solid ${props => props.theme.colors.tertiary};
  margin: 10px auto;
  cursor: pointer;

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;

    > p {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
    }
  }

  > div:nth-child(2) {
    border-top: 1px solid ${props => props.theme.colors.tertiary};
    padding: 14px 16px;
  }

  @media (max-width: 575.98px) {
    margin: 10px auto;
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    margin: 10px auto;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    margin: 20px auto;
    width: 100%;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    margin: 20px auto;
    width: 100%;
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    margin: 20px auto;
    width: 100%;
  }
`

export const BtnShow = styled.button`
  font-size: 25px;
  background: none;
  color: ${props => props.theme.colors.secondary};
`

export const BtnHide = styled.button`
  font-size: 25px;
  background: none;
  color: #aeaeae;
`
