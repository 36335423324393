import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 70px;
  padding: 100px 100px 40px 100px;
  text-align: justify;

  > div {
    max-width: 1920px;
    margin: 0 auto;
  }

  * {
    font-family: 'Raleway', sans-serif !important;
  }

  li {
    margin-left: 15px;
  }

  a {
    color: ${props => props.theme.colors.secondary};
  }

  @media (max-width: 575.98px) {
    padding: 36px 16px;
    margin-top: 0;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding: 36px 16px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 36px 16px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding: 36px 16px;
  }
`

export const Back = styled(Link)`
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  z-index: 2;
  margin-bottom: -90px;
  margin-left: 30px;

  > img {
    transform: scaleX(-1);
    rotate: right;
    width: 32px;
    height: 32px;
  }

  @media (max-width: 575.99px) {
    position: absolute;
    top: 10px;
    left: 10px;
    margin-left: 0;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  position: relative;

  > img {
    width: 100%;
    /* max-height: 50vh; */
    object-fit: cover;
  }

  @media (max-width: 991.98px) {
    > img {
      height: 20vh;
    }
  }
`

export const Content = styled.div`
  padding: 40px 0;

  > div:nth-child(1) {
    color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.gray};
    padding: 4px 8px;
    border-radius: 50px;
    margin-bottom: 8px;
    width: max-content;
    margin-bottom: 20px;
  }

  > h3 {
    font-size: 28px;
    line-height: 27px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      width: 200px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: 575.98px) {
    padding: 0 !important;
    margin-top: 15px;

    > h3 {
      font-size: 16px;
      line-height: 20px;
    }

    > div {
      flex-direction: column;
      align-items: flex-start;

      > div:nth-child(2) {
        width: 100%;
        margin-top: 10px;
        > a {
          > img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding: 0;
    margin-top: 15px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 0;
    margin-top: 15px;
  }
`

export const Div = styled.div`
  display: flex;
  width: 520px;

  > div {
    display: flex;
    align-items: center;
    :nth-child(2) {
      margin-left: 80px;
    }
  }

  @media (max-width: 575.98px) {
    flex-direction: column;
    align-items: left;
    width: auto;

    > div {
      display: flex;
      align-items: left;
      :nth-child(2) {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-direction: column;
    align-items: left;
    width: auto;

    > div {
      display: flex;
      align-items: left;
      :nth-child(2) {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
`

export const Post = styled.div`
  padding: 0;

  p {
    line-height: 32px;
  }

  @media (max-width: 575.98px) {
    padding: 0;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 0;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    padding: 0;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  > div {
    text-align: justify;
    > blockquote {
      border-left: 3px solid #000;
      padding: 0 0 0 8px;
      text-indent: 0px;
      text-align: justify;
      margin: 25px 0;
    }
  }
`

export const TwoBlocks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    width: 48%;
    height: auto;
    text-align: justify;
    margin: 20px 0;
    > blockquote {
      border-left: 3px solid #000;
      padding: 0 0 0 8px;
      text-indent: 0px;
      text-align: justify;
      margin: 25px 0;
    }
  }

  @media (max-width: 575.98px) {
    flex-direction: column;

    > div {
      width: auto;
      margin: 20px 0;
      > blockquote {
        border-left: 3px solid #000;
        padding: 0 0 0 8px;
        text-indent: 0px;
        text-align: justify;
        margin: 25px 0;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-direction: column;

    > div {
      width: auto;
      margin: 20px 0;
      > blockquote {
        border-left: 3px solid #000;
        padding: 0 0 0 8px;
        text-indent: 0px;
        text-align: justify;
        margin: 25px 0;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    > div {
      width: auto;
      margin: 20px 0;
      > blockquote {
        border-left: 3px solid #000;
        padding: 0 0 0 8px;
        text-indent: 0px;
        text-align: justify;
        margin: 25px 0;
      }
    }
  }
`

export const ImgPost = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
`

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

export const ImgContent = styled.div`
  margin: 0 auto;

  > div {
    text-align: center;
    color: #00000090;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
  }
`

export const Line = styled.div`
  background: #c4c4c4;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  height: 10px;

  margin-bottom: 20px;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 575.98px) {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
  }
`

export const ContentHTML = styled.div`
  & > p {
    text-indent: 20px;
    text-align: justify;
  }

  & > blockquote {
    border-left: 3px solid #000;
    padding: 10px 20px;
    text-indent: 20px;
    text-align: justify;
  }
`

export const ImgPostSolo = styled.div`
  > img {
    width: 100%;
    height: 420px;
    object-fit: cover;
  }

  > div {
    text-align: center;
    color: #00000090;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
  }
`
