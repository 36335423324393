import React, { useEffect, useRef } from 'react'
import {
  Container,
  HeaderSection,
  Paragraph,
  SectionValues,
  SectionStory,
  SectionOurTeam,
  SectionAwards,
  SectionPartners,
  SectionDownload,
  DownloadBlock,
  AboutValues,
  Card,
  ContainerBtn,
  PreviousBtn,
  NextBtn,
  Content,
  Image,
  CardBlock
} from '../Sobre/style'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getAbout } from '../../functions'
import { GET_ABOUT } from '../../store/actions'
import renderHTML from 'react-render-html'
import learnMore from '../../assets/learnMore.png'

import ValuesAbout from '../../components/ValuesAbout'
import OurTeam from '../../components/OurTeam'
import OurPartners from '../../components/OurPartners'

import circleDown from '../../assets/circleDown.png'
import blackGooglePlay from '../../assets/blackGooglePlay.png'
import blackPlayStore from '../../assets/blackPlayStore.png'
import smartphoneLeft from '../../assets/smartphoneLeft.png'
import smartphoneRight from '../../assets/smartphoneRight.png'

import ReactElasticCarousel from 'react-elastic-carousel'
import { Helmet } from 'react-helmet'

const Sobre: React.FC = () => {
  const dispatch = useDispatch()
  const { token, about } = useSelector((state: RootState) => state.clickState)
  const ref = useRef<any>()

  useEffect(() => {
    getAbout(token).then(resp => {
      dispatch({ type: GET_ABOUT, about: resp })
    })
  }, [])

  const breakPoints = [
    { width: 1, itemsToShow: 1, HTMLFieldSetElement },
    { width: 550, itemsToShow: 1, itemsToScroll: 2 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 4 },
    { width: 1920, itemsToShow: 5 }
  ]

  function button() {
    return <button style={{ display: 'none' }} />
  }

  console.log(about, 'about')

  return (
    <Container>
      <Helmet title="Sobre - Fogo Cruzado" />
      {about?.map((id: any) => {
        return (
          <>
            {id?.name == 'Abertura' && (
              <>
                {id?.items.map((item: any) => {
                  return (
                    <>
                      <HeaderSection>
                        <div>
                          <div>
                            <div>
                              {item?.title.length > 0
                                ? renderHTML(item?.title)
                                : ''}
                            </div>
                            <button onClick={() => window.scrollTo(0, 500)}>
                              <img src={circleDown} />
                            </button>
                          </div>
                          <img src={item?.image?.file} />
                        </div>
                      </HeaderSection>
                      <Paragraph>
                        {item?.description.length > 0
                          ? renderHTML(item?.description)
                          : ''}
                      </Paragraph>
                    </>
                  )
                })}
              </>
            )}
            {id?.name == 'Bloco 1' && (
              <AboutValues>
                {id?.items.map((item: any) => {
                  return (
                    <div>
                      <div>
                        {item?.title?.length > 0 ? renderHTML(item?.title) : ''}
                      </div>
                      <div>
                        {item?.description?.length > 0
                          ? renderHTML(item?.description)
                          : ''}
                      </div>
                    </div>
                  )
                })}
              </AboutValues>
            )}
            {id?.name == 'Bloco 2' && (
              <SectionValues>
                <div>
                  <div>
                    {id?.title?.length > 0 ? renderHTML(id?.title) : ''}
                  </div>
                  <div>
                    {id?.items.map((item: any) => {
                      return (
                        <ValuesAbout
                          image={item?.image?.file}
                          title={
                            item?.title?.length > 0
                              ? renderHTML(item?.title)
                              : ''
                          }
                          text={
                            item?.description?.length > 0
                              ? renderHTML(item?.description)
                              : ''
                          }
                        />
                      )
                    })}
                  </div>
                </div>
              </SectionValues>
            )}
            {id?.name == 'Bloco 3' && (
              <SectionStory>
                {id?.items.map((item: any) => {
                  console.log(item, 'item')
                  return (
                    <>
                      <div>
                        {item?.title?.length > 0 ? renderHTML(item?.title) : ''}
                      </div>
                      <section>
                        {item.order == 1 ? (
                          <>
                            <div>
                              {item?.description?.length > 0
                                ? renderHTML(item?.description)
                                : ''}
                            </div>
                            <img src={item?.image?.file} />
                          </>
                        ) : (
                          <>
                            <img src={item?.image?.file} />
                            <div>
                              {item?.description?.length > 0
                                ? renderHTML(item?.description)
                                : ''}
                            </div>
                          </>
                        )}
                      </section>
                    </>
                  )
                })}
              </SectionStory>
            )}
            {id?.name == 'Bloco 4' && (
              <SectionOurTeam>
                <div>
                  <h3>Nossa equipe</h3>
                  <div>
                    <OurTeam />
                  </div>
                </div>
              </SectionOurTeam>
            )}
            {id?.name == 'Bloco 5' && (
              <SectionAwards>
                <div>{id?.title?.length > 0 ? renderHTML(id?.title) : ''}</div>
                <ContainerBtn>
                  <PreviousBtn
                    onClick={() => {
                      ref.current.slidePrev()
                    }}
                  >
                    <img src={learnMore} />
                  </PreviousBtn>
                  <NextBtn
                    onClick={() => {
                      ref.current.slideNext()
                    }}
                  >
                    <img src={learnMore} />
                  </NextBtn>
                </ContainerBtn>
                {/* @ts-ignore */}
                <ReactElasticCarousel
                  isRTL={false}
                  itemsToShow={5}
                  ref={ref}
                  breakPoints={breakPoints}
                  renderArrow={button}
                  pagination={false}
                  autoPlaySpeed={0.2}
                >
                  {id?.items?.map((item: any) => {
                    return (
                      <>
                        <Card id="slide">
                          <Image src={item?.image?.file} />
                          <div>
                            {item?.title?.length > 0
                              ? renderHTML(item?.title)
                              : ''}
                          </div>
                        </Card>
                        <Content>
                          <div>
                            {item?.description?.length > 0
                              ? renderHTML(item?.description)
                              : ''}
                          </div>
                        </Content>
                      </>
                    )
                  })}
                </ReactElasticCarousel>
              </SectionAwards>
            )}
            {id?.name == 'Bloco 6' && (
              <SectionPartners>
                <div>
                  <h3>Nossos parceiros</h3>
                  <OurPartners partners={id?.items} page="sobre" />
                </div>
              </SectionPartners>
            )}
          </>
        )
      })}
      <SectionDownload>
        <div>
          <div className="container-inner">
            <div className="container-text">
              <h3>Baixe nosso aplicativo</h3>

              <p>
                Disponível na <b>Apple Store</b> e no <b>Google play</b>.
              </p>
            </div>
            <div className="container-flex">
              <button>
                <img src={blackPlayStore} alt="apple icon" />
                <div>
                  <span>Download via</span>
                  <p>Apple Store</p>
                </div>
              </button>
              <button>
                <img src={blackGooglePlay} alt="google play icon" />
                <div>
                  <span>Download via</span>
                  <p>Google Play</p>
                </div>
              </button>
            </div>
          </div>
          <div className="container-imgs">
            <div className="image-right">
              <img src={smartphoneLeft} alt="smartphone" />
            </div>
            <div className="image-right-last">
              <img src={smartphoneRight} alt="smartphone" />
            </div>
          </div>
        </div>
      </SectionDownload>
    </Container>
  )
}

export default Sobre
