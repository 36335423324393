import React, { useState } from 'react'
import {
  Container,
  SectionForm,
  Textarea,
  SectionData,
  Title,
  Block,
  PhoneBlock,
  Label,
  ImgFooter,
  ImgMid,
  Input,
  InputMaskStyle,
  Button
} from '../FaleConosco/style'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import { useForm } from 'react-hook-form'
import { init, send } from '@emailjs/browser'

import wppIcon from '../../assets/wppIcon.png'
import faleconosco from '../../assets/faleconosco-ong.png'
import { Helmet } from 'react-helmet'

const FaleConosco: React.FC = () => {
  init('moaYUUGfOYYO7beuN')
  const [isModal, setIsModal] = useState(false)

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  })

  function onSubmit(e: any) {
    console.log(e, 'email')

    send('service_mbfut2l', 'template_9w6vgv9', e, 'moaYUUGfOYYO7beuN')
      .then((result: any) => {
        setIsModal(true)
        console.log(result.text, 'successo ao enviar')
      })
      .catch((error: any) => {
        console.log(error.text, 'erro')
      })
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '48%',
    left: '48%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'background.paper',
    border: '2px solid #E0733D',
    boxShadow: 24,
    p: 4
  }

  return (
    <>
      <Helmet title="Fale conosco - Fogo Cruzado" />
      <Container>
        <div>
          <SectionForm>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h3>Fale conosco</h3>
              <fieldset>
                <Label>Nome</Label>
                <Input
                  type="text"
                  placeholder="Digite seu nome"
                  {...register('name')}
                />
              </fieldset>
              <fieldset>
                <Label>E-mail</Label>
                <Input
                  type="email"
                  placeholder="Digite seu email"
                  {...register('email')}
                  required={true}
                />
              </fieldset>
              <fieldset>
                <Label>Telefone (ddd + número)</Label>
                <InputMaskStyle
                  type="text"
                  placeholder="Digite seu telefone"
                  mask={'(99) 99999-9999'}
                  {...register('phone', {
                    required: true,
                    pattern: /\(\d{2}\)\s\d{5}\-\d{4}/i
                  })}
                />
              </fieldset>
              <fieldset>
                <Label>Como podemos ajudar você?</Label>
                <Textarea
                  rows={5}
                  cols={35}
                  placeholder="Digite sua mensagem"
                  {...register('message')}
                />
              </fieldset>
              <button type="submit">Enviar mensagem</button>
            </form>
          </SectionForm>
          <ImgMid src={faleconosco} alt="" />
          {/* <SectionData>
                        <Title>Horário de atentimendo</Title>
                        <Block>
                            <p>Segundas as sextas - <strong>8h ás 19h</strong></p>
                            <p>Sábados - <strong>8h ás 12h</strong></p>
                        </Block>
                        <PhoneBlock>
                            <p>Telefone<strong>+55 (21) 99024-6578</strong></p>
                            <a href="https://api.whatsapp.com/send?phone=5521990246578" target="_blank">
                                <img src={wppIcon} />
                            </a>
                        </PhoneBlock>
                        
                        <Block>
                            <p><strong>Pedidos fora do horario:</strong></p>
                            <p>riodejaneiro@fogocruzado.org.br</p>
                            <p>recife@fogocruzado.org.br</p>
                        </Block>

                        <Title>Assessorias</Title>
                        <Block>
                            <p><strong>Gabrielli Thomaz </strong>(Imprensa)</p>
                            <p><strong>Loren Ipson Loren </strong>(Dados)</p>
                            <p><strong>Loren Ipson Loren </strong>(TI)</p>
                        </Block>

                        <Title>Trabalhe conosco</Title>
                        <p>Envie um e-mail para:</p>
                        <p><strong>contato@fogocruzado.org.br</strong></p>
                    </SectionData>
                     */}
          <ImgFooter src={faleconosco} alt="" />
        </div>
      </Container>
      <Modal
        open={isModal}
        onClose={() => setIsModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 300 }}>
          <p
            id="child-modal-description"
            style={{ marginBottom: 20, textAlign: 'center', fontSize: '24px' }}
          >
            Mensagem enviado com sucesso!
          </p>
          <Button onClick={() => setIsModal(false)}>Fechar</Button>
        </Box>
      </Modal>
    </>
  )
}

export default FaleConosco
