import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  backdrop-filter: blur(2px);
  width: 305px !important;
  height: 107px !important;
  padding: 24px 0 !important;
  background: rgba(44, 57, 65, 0.05);
  backdrop-filter: blur(10px);
  margin-right: 20px;

  > div {
    display: flex;
    width: 254px;
    margin-bottom: 24px;
    margin-left: 20px;
  }
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #233237;
  margin-top: 0 !important;
  margin-bottom: 12px;
  margin-left: 20px;
`

export const Button = styled.a`
  > img {
    width: 40px;
    height: 40px;
  }
`
