import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import * as S from './style'

export type Position = {
  lat: number
  lng: number
}

export type State = {
  id: string
  name: string
}

export type City = {
  id: string
  name: string
  state: State
}

export const INIT_FORMAT = 'YYYY-MM-DD[T00:00:00.000Z]'
export const FINAL_FORMAT = 'YYYY-MM-DD[T23:59:59.000Z]'

export const MAP_CENTER = {
  lat: -22.9132525,
  lng: -43.7261813
}

export const isEmpty = (object: any) => Object.keys(object).length === 0

export const filterAvailableOccurrences = (occurrences: Array<any>) =>
  occurrences.filter(
    occur => occur.main_reason && occur.latitude && occur.longitude
  )

export const hasShotedPeople = (occurence: any) => {
  return (
    occurence.number_civilians_dead ||
    occurence.number_civilians_wounded ||
    occurence.number_agent_dead ||
    occurence.number_agent_wounded
  )
}

const LINKS = [
  {
    id: 1,
    to: '/dados/tempo-real',
    name: 'Tempo real'
  },
  {
    id: 2,
    to: '/dados/produza-seus-dados',
    name: 'Produza seus dados'
  },
  {
    id: 3,
    to: '/dados/relatorios',
    name: 'Relatórios'
  }
]

const Dados: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <S.Container>
      <Helmet title="Dados - Fogo Cruzado" />
      <div>
        {LINKS.map(link => (
          <S.Button
            key={link.id.toString()}
            isActive={link.to === pathname}
            to={link.to}
          >
            {link.name}
          </S.Button>
        ))}
      </div>
    </S.Container>
  )
}

export default Dados
