import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  margin-left: auto;
  width: 280px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
`

export const Bakc = styled(Link)`
  > img {
    width: 8px;
    height: 14px;
  }
`

export const Number = styled(Link)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  background-color: ${props => props.theme.colors.secondary};
  padding: 10px 15px;
  border-radius: 100%;
`

export const Previous = styled(Link)`
  > img {
    width: 8px;
    height: 14px;
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
`
