import styled from 'styled-components'
import { IProps } from './types'

export const Container = styled.div`
  max-width: 100vw;
  overflow: hidden;
  margin-top: 135px;
  margin: 0 auto;
  * {
    font-family: 'Raleway', sans-serif !important;
    /* line-height: 32px; */
  }

  @media only screen and (max-width: 575.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 1200px) {
    margin-top: 143px;
  }
`

export const SectionHeader = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    /* max-width: 1920px; */
    width: 100vw;
    height: calc(100vh - 133px);
    margin: 0 auto;
    overflow: hidden;
  }

  @media (max-width: 575.98px) {
    > div {
      flex-direction: column;
      height: 710px;
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      flex-direction: column;
      height: auto;
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    > div {
      flex-direction: column;
      height: auto;
    }
  }
  @media (min-width: 1200px) {
    width: 100%;

    > div {
      max-width: 1920px;
      max-height: 800px;
    }
  }
`

export const SectionHeaderLeft = styled.div<IProps>`
  width: 50%;
  background: black;
  background-image: url(${props => {
    return `${props.image}`
  }});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 575.98px) {
    width: 100% !important;
    height: 400px;
    background-size: cover;
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    width: 100% !important;
    height: 398px;
    background-size: cover;
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    width: 100% !important;
    background-size: cover;
  }
`

export const SectionHeaderTitle = styled.div`
  margin: 30% auto 0;
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:nth-child(1) {
    padding: 20px;
    color: ${props => props.theme.colors.white};
    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 100;
      width: 369px;
      margin-left: 84px;
      line-height: 48px;
    }
    strong {
      background: #e0733d90;
      padding: 5px;
      line-height: 48px;
      color: white;
    }
  }

  > div:nth-child(2) {
    color: ${props => props.theme.colors.white};
    p {
      text-align: center;
      float: center;
      width: 424px;
      font-size: 20px;
      line-height: 30px;
      //opacity: 0.9;
      margin-top: 20px;
      //margin-left: 2px;
      background: #e0733d90;
    }
  }

  > button {
    width: 48px;
    height: 48px;
    background: none;
    margin-left: 0;
    margin-top: 65px;
    text-decoration: none;
    cursor: pointer;

    img {
      width: 48px;
      height: 48px;
    }
  }

  @media (max-width: 575.98px) {
    width: auto !important;
    height: 400px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    div:nth-child(1) {
      > h1,
      h2,
      h3,
      h4,
      h5 {
        padding: 0 20px;
        font-size: 32px;
        margin-left: 0 !important;
        text-align: center;
        width: auto;
      }
    }

    div:nth-child(2) {
      > p {
        font-size: 14px;
        margin-left: 0 !important;
        text-align: center;
        padding: 10px 50px;
        width: auto;
      }
    }

    > button {
      display: none;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    width: auto !important;
    max-height: 50vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    > div:nth-child(1) {
      color: ${props => props.theme.colors.white};
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 48px;
        text-align: center;
        height: auto !important;
        font-weight: 100;
        width: 450px;
        line-height: 60px;
      }
      strong {
        background: ${props => props.theme.colors.secondary};
        padding: 5px;
        line-height: 48px;
        color: white;
      }
    }

    > div:nth-child(2) {
      color: ${props => props.theme.colors.white};
      p {
        width: 424px;
        font-size: 20px;
        line-height: 28px;
        opacity: 0.9;
        margin-top: 20px;
        margin-left: 82px;
      }
    }

    > button {
      display: none;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    width: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10% 0 !important;

    > div:nth-child(1) {
      color: ${props => props.theme.colors.white};
      > h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 48px;
        text-align: center;
        height: auto !important;
        font-weight: 100;
        width: 450px;
        line-height: 60px;
      }
      strong {
        background: ${props => props.theme.colors.secondary};
        padding: 5px;
        line-height: 48px;
        color: white;
      }
    }

    > div:nth-child(2) {
      color: ${props => props.theme.colors.white};
      p {
        width: 424px;
        font-size: 20px;
        line-height: 28px;
        opacity: 0.9;
        margin-top: 20px;
        margin-left: 82px;
      }
    }

    > button {
      display: none;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @media (min-width: 1200px) {
    width: auto !important;
    max-height: 20vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const News = styled.div`
  height: 100%;
  padding: 0px;
  > div:nth-child(4) {
    display: none;
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    width: 100% !important;

    > div {
      /* margin-bottom: 12px; */
    }
  }
  @media (min-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 575.98px) {
    /* height: 312px ; */
  }
`

export const SectionOurData = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #2c3841 8.01%, #23526f 126.28%);

  @media (min-width: 1070px) {
    padding-bottom: 200px;
  }

  > div {
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
    z-index: 8;
  }

  @media (max-width: 575.98px) {
    > div {
      display: flex;
      flex-direction: column;

      > p {
        font-weight: 600;
        font-size: 24px;
        line-height: 45px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      margin-top: 0;
    }
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    > div {
      margin-top: 0;
    }
  }
  @media (min-width: 1200px) and (max-width: 1600.98px) {
    > div {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
`

export const TitleData = styled.p`
  color: ${props => props.theme.colors.white};
  font-weight: 700;
  font-size: 48px;
  padding-top: 40px;
  text-align: center;
`

export const ContainerOurData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
  margin-bottom: 140px;

  @media only screen and (max-width: 575.98px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    flex-wrap: wrap;
    margin-bottom: 40px;

    > div {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    flex-wrap: wrap;
    margin-bottom: 40px;

    > div {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1600.98px) {
  }
`

export const SmarthPhone = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -400px;
  margin-bottom: -300px;

  @media (max-width: 1200px) {
    display: none;
  }
`

export const SmartPhoneResp = styled.div`
  display: none;

  @media only screen and (max-width: 575.98px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px !important;
    padding-bottom: 300px;
    width: 100% !important;
    height: 997.42px !important;
    margin-bottom: -300px;

    > img {
      width: 2000px !important;
      height: auto !important;
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px !important;
    padding-bottom: 300px;
    width: 100% !important;
    height: 997.42px !important;

    > img {
      width: 2000px !important;
      height: auto !important;
    }
  }

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px !important;
    padding-bottom: 300px;
    width: 100% !important;
    height: 997.42px !important;

    > img {
      width: 2000px !important;
      height: auto !important;
    }
  }
`

export const SectionDownloadApp = styled.div`
  @media (min-width: 576px) and (max-width: 989.98px) {
    margin-top: -100px;
  }
  @media (min-width: 990px) and (max-width: 1199.98px) {
    margin-top: -100px;
  }
`

export const SectionNewsBlog = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  > div {
    max-width: 1920px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 40px;

    @media (min-width: 1070px) {
      padding-top: 80px;
    }

    margin: 0 auto;

    > h3 {
      color: ${props => props.theme.colors.tertiary};
      padding-top: 10px;
      font-size: 32px;
      text-align: center;
      margin-bottom: 30px;
    }

    > section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      gap: 20px;
    }
  }

  @media (max-width: 575.98px) {
    > div {
      padding: 40px 16px;
      > section {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          margin-bottom: 10px;
        }

        > div:nth-child(4) {
          display: none;
        }
      }

      > div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      padding: 40px 16px;
      > section {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          margin-bottom: 10px;
        }

        > div:nth-child(4) {
          display: none;
        }
      }

      > div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    > div {
      padding-right: 40px;
      padding-left: 40px;
      > section {
        flex-wrap: wrap;
        align-items: center;

        > div {
          margin-bottom: 20px;
        }

        > div:nth-child(4) {
          display: block;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1600.98px) {
    > div {
      > section {
        > div:nth-child(4) {
          display: none;
        }
      }
    }
  }
`

export const SectionAPI = styled.div`
  width: 100%;

  > div {
    max-width: 1920px;
    margin: 0 auto;
    padding-right: 80px;
    padding-left: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 250px;

    > div {
      color: ${props => props.theme.colors.tertiary};
      width: 509px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 40px;
      }

      > p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        opacity: 0.8;
        margin-bottom: 40px;
      }

      > div {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      > div:nth-child(3) {
        margin: 0 auto 20px;
      }
    }

    > img {
      width: 628.3px;
    }
  }

  @media only screen and (max-width: 575.98px) {
    > div {
      padding: 40px 16px;
      flex-direction: column;

      > div {
        width: auto;
      }

      > img {
        height: auto;
        width: 100%;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 989.98px) {
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 16px;

      > img {
        max-width: 767.98px;
        height: auto;
        margin-top: 20px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    > div {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  @media (min-width: 1200px) {
    > div {
      > div {
        > div:nth-child(3) {
          margin: 0 !important;
        }
      }
    }
  }
`

export const TitleApi = styled.div`
  @media only screen and (max-width: 575.98px) {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    margin: 20px auto;

    > h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: center;
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    font-size: 32px;
    line-height: 45px;
    margin: 20px auto;

    > h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: center;
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    font-size: 32px;
    line-height: 45px;
    margin: 20px auto;

    > h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: left !important;
      margin: 0 !important;
    }
  }

  @media (min-width: 1200px) and (max-width: 1700.98px) {
    font-size: 32px;
    line-height: 45px;
    margin: 20px 0;

    > h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: left !important;
    }
  }

  @media (min-width: 1701px) {
    font-size: 32px;
    line-height: 45px;
    margin: 20px 0;

    > h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: left !important;
    }
  }
`

export const SubtitleApi = styled.div`
  @media only screen and (max-width: 575.98px) {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    opacity: 0.8;
    margin: 0 auto 10px;

    > p {
      text-align: center;
    }
  }
  @media (min-width: 576px) and (max-width: 775.98px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    font-size: 20px;
    line-height: 32px;
    margin: 0;

    > p {
      text-align: left;
    }
  }
  @media (min-width: 1200px) and (max-width: 1700.98px) {
    font-size: 20px;
    line-height: 32px;
    margin: 0;

    > p {
      text-align: left;
    }
  }
  @media (min-width: 1701px) {
    font-size: 20px;
    line-height: 32px;
    margin: 0;

    > p {
      text-align: left;
    }
  }
`

export const Supporter = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #202020 8.01%, #23526f 126.28%);
  > div {
    max-width: 1920px;
    margin: 0 auto;
    color: ${props => props.theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      padding-left: 80px;
      padding-right: 70px;
      padding-top: 110px;
      padding-bottom: 70px;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1280px) {
        width: 60%;
      }

      @media (max-width: 690px) {
        width: 100%;
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;

        h3 {
          margin-bottom: 10px !important;
        }

        p {
          font-size: 16px !important;
          line-height: 26px !important;
        }
      }

      > div {
        > h3 {
          width: 294px;
          font-weight: bold;
          font-size: 32px;
          line-height: 45px;
          margin-bottom: 40px;
          text-align: center;
        }

        > p {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 32px;
          margin-bottom: 40px;
        }
        > div {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

    > img {
      width: 50vw;
      max-height: 751px;
      object-fit: cover;
      margin-top: -90px;

      @media (max-width: 690px) {
        width: 100%;
        margin: 0 !important;
      }
    }

    @media (max-width: 690px) {
      flex-direction: column;

      > div {
        > div {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        padding: 40px 16px;

        > div {
          > h3 {
            width: auto;
            line-height: 45px;
            margin-bottom: 40px;
          }

          > p {
            width: auto;
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 60px;
            text-align: center;
          }
        }

        > div:nth-child(3) {
          margin: 0 auto;
        }
      }

      > img {
        margin-top: 0 !important;
        height: auto;
        width: 100%;
      }
    }
  }
`
