import { GET_POSTS_NEWS, GET_TRANSPARENCIES } from './../actions/index'
import {
  SET_TOKEN,
  GET_USER,
  GET_NEWS,
  GET_HOME,
  GET_ABOUT,
  GET_TEAM,
  GET_PARTNERS,
  GET_AWARDS,
  GET_ABOUT_API,
  GET_SOCIAL_NETWORK,
  GET_IMPACT,
  SET_SEARCH,
  GET_REPORTS,
  SET_SLUG,
  GET_IMAGE_SOCIAL_MEDIA,
  GET_STATISTICS
} from '../actions/index'
import { initialState } from '../states'

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.token }

    case GET_USER:
      return { ...state, user: action.user }

    case GET_IMAGE_SOCIAL_MEDIA:
      return { ...state, image_sm: action.image_sm }

    case GET_HOME:
      return { ...state, home: action.home }

    case GET_ABOUT:
      return { ...state, about: action.about }

    case GET_ABOUT_API:
      return { ...state, about_api: action.about_api }

    case GET_TEAM:
      return { ...state, team: action.team }

    case GET_PARTNERS:
      return { ...state, partners: action.partners }

    case GET_AWARDS:
      return { ...state, awards: action.awards }

    case GET_NEWS:
      return { ...state, posts: action.posts }

    case GET_SOCIAL_NETWORK:
      return { ...state, social_networks: action.social_networks }

    case GET_TRANSPARENCIES:
      return { ...state, transparencies: action.transparencies }

    case GET_IMPACT:
      return { ...state, impact: action.impact }

    case GET_POSTS_NEWS:
      return { ...state, posts_news: action.posts_news }

    case SET_SEARCH:
      return { ...state, seachSite: action.seachSite }
    case GET_REPORTS:
      return { ...state, reports: action.reports }
    case SET_SLUG:
      return { ...state, slug: action.slug }

    case GET_STATISTICS:
      return { ...state, statistics: action.statistics }
    default:
      return { ...state }
  }
}
