import styled from 'styled-components'
import DefaultButton from '../../components/DefaultButton'
import { DefaultBtn } from '../../components/DefaultButton/style'

export const Container = styled.div`
  margin-top: 143px;
  display: flex;
`

export const SectionRight = styled.div`
  margin-left: 100px;
  padding-bottom: 50px;
  padding-top: 50px;
`

export const SectionLeft = styled.div`
  background: ${props => props.theme.colors.tertiary};
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 214px;
    height: 156px;
    margin-left: 100px;
    margin-right: 100px;
  }
`

export const Title = styled.h3`
  width: 461px;
  height: 192px;
  color: ${props => props.theme.colors.tertiary};
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 67px;
  margin-bottom: 32px;

  > strong {
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white};
    padding: 3px;
  }
`

export const Input = styled.input`
  background: ${props => props.theme.colors.grayLight};
  border: 1px solid rgba(44, 57, 65, 0.5);
  box-sizing: border-box;
  padding: 16px 24px;
  width: 607px;
  margin-bottom: 32px;
`

export const Assine = styled.p`
  width: 613px;
  margin-bottom: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  color: ${props => props.theme.colors.tertiary};
  opacity: 0.9;
`

export const Button = styled(DefaultBtn)`
  background-color: ${props => props.theme.colors.secondary};
  width: 700px;
  text-align: center;
  margin-top: 10px;
`
