import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  width: 1020px;
  max-width: 1020px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (min-width: 1090px) {
  }

  @media only screen and (max-width: 575.98px) {
    width: 100vw;
    display: block;
  }

  .container-inner {
    height: 167px;
    width: 100%;
    background: ${props => props.theme.colors.secondary};
    color: white;
    padding: 40px 80px;
    display: flex;
    gap: 80px;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 1090px) {
      /* border: 4px solid white; */
      margin-top: -120px;
    }

    h3 {
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 12px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      white-space: nowrap;
    }

    .container-text {
      width: 100%;
    }

    .container-flex {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;

      button {
        padding: 12px 16px;
        border: 1px solid white;
        background: transparent;
        color: white;
        width: 176px;
        height: 60px;
        display: flex;
        gap: 12px;
        img {
          width: 23px;
        }

        > div {
          text-align: left;
          p {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575.98px) {
    .container-inner {
      height: 227px;
      width: 100vw;
      background: ${props => props.theme.colors.secondary};
      color: white;
      padding: 32px 16px;
      flex-direction: column;
      gap: 0px;
      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 45px;
        margin-bottom: 0px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 34px;
      }
    }

    .container-flex {
      width: 100%;
      margin-top: 20px;
      height: 60px;
      display: flex;
      gap: 16px;
      button {
        padding: 12px 16px;
        background: transparent;
        color: white;
        border: 1px solid white;
        width: 163px;
        display: flex;
        gap: 12px;
        img {
          width: 23px;
        }
        > div {
          text-align: left;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
          }

          p {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
`

export const Card = styled.div`
  border: 4px solid black;
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  padding: 40px 80px;
  margin-top: -85px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 575.98px) {
    flex-direction: column;
    padding: 16px 32px;
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
  }
`

export const SectionLeft = styled.div`
  margin-right: 80px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    margin-bottom: auto;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
  }

  @media only screen and (max-width: 575.98px) {
    margin-right: 0 !important;
    h3 {
      font-size: 20px;
      line-height: 25px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    text-align: center;
    margin-right: 0;
    h3 {
      font-size: 32px;
      line-height: 28px;
    }

    p {
      margin-top: 0;
      font-size: 20px;
      line-height: 34px;
    }
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    margin-left: -50px;
  }
`

export const SectionRigth = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  > a {
    cursor: pointer;
    text-decoration: none;
    background: none;
    color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.white};
    margin-left: 20px;
    padding: 14px 20px;

    img {
      margin-right: 12px;
    }

    div {
      flex-direction: column;

      > h3 {
        font-weight: 700;
      }
    }
  }

  @media only screen and (max-width: 575.98px) {
    display: flex;
    flex-direction: row;
    width: 400px;

    > a {
      height: 60px;
      padding: 13px 5px;
      width: 150px;

      img {
        margin-right: 12px;
        margin-left: 12px;
      }

      div {
        margin-top: -15px;
        > p {
          font-size: 12px;
          margin: 5px 0 0 0 !important;
        }

        > h3 {
          font-size: 12px;
          line-height: 5px !important;
        }
      }
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 430px;
    margin-top: 20px;
  }

  @media (min-width: 990px) and (max-width: 1199.98px) {
    width: 430px;
  }
`
