export default {
  title: 'themeColor',

  colors: {
    primary: '#F83600',
    secondary: '#E0733D',
    tertiary: '#2C3941',

    black: '#233237',
    white: '#FFFFFF',
    grayLight: '#F8F8F8',
    gray: '#DFDFDF',
    orange: '#E0733D',
    rose: '#E1B7AD',
    lightYellow: '#EEEADE',
    blue: '#0AA1D0',
    red: '#BF091F',
    brown: '#DA8300',

    success: '#45BD5F',
    info: '#5bc0de',
    warning: '#f0ad4e',
    danger: '#d9534f'
  }
}
