import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const DefaultBtn = styled(Link)`
  max-height: 47px;
  display: inline-block;
  color: ${props => props.theme.colors.white};
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;
  background: ${props => props.theme.colors.secondary};
`
