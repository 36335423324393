import React from 'react'
import {
  Container,
  Image,
  Card,
  Locale,
  Title,
  Content,
  Author,
  Icon,
  ToNotice,
  BannerResp,
  TitleResp,
  CardResp,
  RegionResp,
  ContentResp,
  ButtonResp,
  IconResp
} from '../NewsPost/style'

import paper from '../../assets/paper.png'
import calendar from '../../assets/calendar.png'
import learnMore from '../../assets/learnMore.png'

interface IProps {
  to: string
  img: string
  local: string
  title: string
  content: string
  author: string
  data: string
}

const NewsPost: React.FC<IProps> = props => {
  return (
    <>
      <Container to={props.to}>
        <Image src={props.img} />
        <Card>
          <Locale>{props.local}</Locale>
          <div>
            <Title>{props.title}</Title>
            <Content>{props.content}</Content>
            <Author>
              <Icon src={paper} width="14px" height="17.5px" />
              <p>{props.author}</p>
            </Author>
            <Author>
              <Icon src={calendar} width="16.67px" height="16.67px" />
              <p>{props.data}</p>
            </Author>
            <ToNotice to={props.to}>
              <img src={learnMore} />
            </ToNotice>
          </div>
        </Card>
      </Container>

      <CardResp to={props.to}>
        <BannerResp src={props.img} />
        <RegionResp>{props.local}</RegionResp>
        <TitleResp>{props.title}</TitleResp>
        <ContentResp>
          <div>
            <div>
              <IconResp src={paper} />
              <p>{props.author}</p>
            </div>
            <div>
              <IconResp src={calendar} />
              <p>{props.data}</p>
            </div>
          </div>
          <ButtonResp to={props.to}>
            <img src={learnMore} />
          </ButtonResp>
        </ContentResp>
      </CardResp>
    </>
  )
}

export default NewsPost
