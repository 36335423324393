import React, { useEffect } from 'react';

const AboutApi: React.FC = () => {
  useEffect(() => {
    window.location.replace('https://api.fogocruzado.org.br');
  }, []);

  return null;
};

export default AboutApi;
