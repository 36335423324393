import styled from 'styled-components'

export const Container = styled.div`
  padding: 140px 80px 40px 80px;
  color: ${props => props.theme.colors.tertiary};
  > h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px !important;
    line-height: 67px;
    margin-top: 25px;
  }

  > p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px !important;
    margin-bottom: 60px;
  }

  @media (max-width: 575.98px) {
    padding: 40px 16px;

    > h3,
    > p {
      text-align: center;
    }
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 40px 16px;

    > h3,
    > p {
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding: 40px 16px;

    > h3,
    > p {
      text-align: center;
    }
  }
`

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  width: 580px;
`

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgba(69, 69, 69, 0.6);
  box-sizing: border-box;
  padding: 12px 20px;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  .section__title {
    margin-bottom: 16px;
  }

  .section__radios {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .radio {
      display: flex;
      align-items: center;

      > label {
        font-size: 14px;
      }
    }
  }

  .section__buttons {
    display: flex;
    column-gap: 16px;
  }

  .donation__value {
    margin-top: 16px;
  }

  input {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(69, 69, 69, 0.6);
    box-sizing: border-box;
    padding: 12px 20px;
  }

  .finish__button {
    width: 170px;
    padding: 12px 0;
    border: none;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.secondary};
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s ease all;

    :disabled {
      background-color: #e0e0e0;
      cursor: default;
    }
  }
`
