import React, { useEffect, useState } from 'react'
import {
  Container,
  SectionSearch,
  SectionOldPost,
  SectionPaginator,
  Filters,
  Select,
  InputData,
  Search,
  Card,
  Banner,
  Region,
  Title,
  Content,
  Icon,
  Button,
  Close,
  ButtonClear,
  ButtonFilter,
  NewsContent
} from '../InformeSe/style'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import {
  getNews,
  convertData,
  getAllNewsByParams,
  getRegions
} from '../../functions'
import { GET_NEWS } from '../../store/actions'

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import NewsPost from '../../components/NewsPost'
import Paginator from '../../components/Paginator'

import paper from '../../assets/paper.svg'
import dateImg from '../../assets/date.svg'
import learnMore from '../../assets/learnMore.png'
import searchBtn from '../../assets/searchBtn.png'
import filter from '../../assets/filter.png'
import close from '../../assets/closeDark.png'
import { Helmet } from 'react-helmet'
import CardPost from '../../components/CardPost'

const InformeSe: React.FC = () => {
  const dispatch = useDispatch()
  const { token, posts } = useSelector((state: RootState) => state.clickState)
  const [regions, setRegions] = useState<any>()
  const [modal, setModal] = useState(false)
  const [order, setOrder] = useState('')
  const [page, setPage] = useState('')
  const [take, setTake] = useState('')
  const [date, setDate] = useState('')
  const [title, setTitle] = useState('')
  const [region, setRegion] = useState('')
  const [authors, setAuthors] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    getAllNewsByParams(
      token,
      order,
      page,
      take,
      date != '' ? `${date}T00:01:00.000Z` : undefined,
      date != '' ? `${date}T23:59:00.000Z` : undefined,
      title,
      region,
      authors,
      type
    ).then(resp => {
      dispatch({ type: GET_NEWS, posts: resp.data })
    })
  }, [token, order, page, take, date, title, region, authors, type])

  useEffect(() => {
    getRegions(token).then(resp => {
      setRegions(resp)
    })
  }, [])

  console.log(posts, 'weojknvfw')

  return (
    <>
      <Helmet title="Notícias - Fogo Cruzado" />
      <Modal onClose={() => setModal(false)} open={modal}>
        <Box>
          <Filters>
            <h3>Filtro</h3>
            <Close onClick={() => setModal(false)}>
              <img src={close} />
            </Close>
            <div>
              <label htmlFor="">Região</label>
              <Select
                name="regiao"
                onChange={e => {
                  setRegion(e.target.value)
                }}
              >
                <option value="">Todas as regiões</option>
                {regions?.map((id: any) => {
                  if (id?.enabled == true) {
                    return <option value={id?.id}>{id?.region}</option>
                  }
                })}
              </Select>
            </div>
            <div>
              <label htmlFor="">Criador de conteúdo</label>
              <InputData
                placeholder="Tudo"
                name="criadores"
                onChange={e => {
                  setAuthors(e.target.value)
                }}
              />
            </div>
            <div>
              <label htmlFor="">Data</label>
              <InputData
                type="date"
                name="data"
                onChange={e => {
                  setDate(e.target.value)
                }}
              />
            </div>
            <section>
              <ButtonFilter onClick={() => setModal(false)}>
                Filtrar
              </ButtonFilter>
              <ButtonClear
                onClick={() => {
                  setRegion('')
                  setAuthors('')
                  setDate('')
                  setModal(false)
                }}
              >
                Limpar filtros
              </ButtonClear>
            </section>
          </Filters>
        </Box>
      </Modal>
      <Container>
        <h3>Notícias</h3>
        <Search>
          <div>
            <input
              type="search"
              onChange={e => {
                setTitle(e.target.value)
              }}
            />
            <img src={searchBtn} />
          </div>
          <button onClick={() => setModal(true)}>
            <img src={filter} />
          </button>
        </Search>
        <SectionSearch>
          <div>
            <div>
              <label>Região</label>
              <Select
                onChange={e => {
                  setRegion(e.target.value)
                }}
              >
                <option value="">Todas as regiões</option>
                {regions?.map((id: any) => {
                  if (id?.enabled == true) {
                    return <option value={id?.id}>{id?.region}</option>
                  }
                })}
              </Select>
            </div>
            <div>
              <label htmlFor="">Criador de conteúdo</label>
              <InputData
                placeholder="Tudo"
                onChange={e => {
                  setAuthors(e.target.value)
                }}
              />
            </div>
            <div>
              <label htmlFor="">Data</label>
              <InputData
                type="date"
                onChange={e => {
                  setDate(e.target.value)
                }}
              />
            </div>
          </div>
        </SectionSearch>
        <NewsContent>
          <div>
            {posts?.map((id: any, index: number) => {
              if (index <= 1) {
                return (
                  <NewsPost
                    key={index}
                    to={`/${id?.slug}`}
                    img={id?.cover?.file}
                    local={id?.region?.state}
                    title={id?.title}
                    content={id?.description}
                    author={id?.authors}
                    data={
                      id?.date_post == ''
                        ? convertData(id?.updatedAt)
                        : convertData(id?.date_post)
                    }
                  />
                )
              }
            })}
          </div>
        </NewsContent>
        <SectionOldPost>
          <div>
            {posts?.map((id: any, index: number) => {
              if (index >= 2) {
                return (
                  <CardPost
                    key={index}
                    to={`/${id?.slug}`}
                    img={id?.cover?.file}
                    local={id?.region?.state}
                    title={id?.title}
                    author={id?.authors}
                    date={
                      id?.date_post == ''
                        ? convertData(id?.updatedAt)
                        : convertData(id?.date_post)
                    }
                  />
                )
              }
            })}
          </div>
        </SectionOldPost>
        <SectionPaginator>
          <Paginator to="" number={1} />
        </SectionPaginator>
      </Container>
    </>
  )
}

export default InformeSe
