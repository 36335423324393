import React, { useState, useEffect } from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  MarkerClusterer
} from '@react-google-maps/api'

import moment from 'moment'

import Dados, {
  Position,
  State,
  City,
  MAP_CENTER,
  INIT_FORMAT,
  FINAL_FORMAT,
  hasShotedPeople,
  isEmpty,
  filterAvailableOccurrences
} from '..'
import Select from '../../../components/Select'
import InputData from '../../../components/inputData'
import TinyBarChart from '../../../components/barChart'
import { InputRadio, InputRadioVertical } from '../../../components/InputRadio'

import user from '../../../assets/user.png'
import arowIcon from '../../../assets/arowIcon.png'
import learnMore from '../../../assets/learnMore.png'
import police from '../../../assets/carbon_police.png'
import alertRedPng from '../../../assets/alertRed.png'
import mapClusterPng from '../../../assets/mapCluster.png'
import alertYellowPng from '../../../assets/alertYellow.png'
import icons_download from '../../../assets/icons_download.png'

import CONSTANTS from '../../../constants'
import { api } from '../../../api'
import * as S from './style'

type ChartData = {
  name: string
  ['Ocorrências']: number
}

type GeneralChartData = {
  kills: {
    total: number
    civilians: number
    policemans: number
  }
  injureds: {
    total: number
    civilians: number
    policemans: number
  }
}

type Victim = {
  policeman: string
  adolescent: string
  adult: string
  elderly: string
}

const MONTHS = [
  '',
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
]

const ProduzaSeusDados: React.FC = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONSTANTS.GOOGLE_MAPS_SECRET_KEY,
    language: 'pt-br',
    libraries: ['places']
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [cities, setCities] = useState<Array<City>>([])
  const [states, setStates] = useState<Array<State>>([])

  const [selectedCity, setSelectedCity] = useState<string>('ALL')
  const [selectedState, setSelectedState] = useState<string>('ALL')
  const [selectedInitialDate, setSelectedInitialDate] = useState<string>(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  )
  const [selectedFinalDate, setSelectedFinalDate] = useState<string>(
    moment().format('YYYY-MM-DD')
  )

  const [withPolicePresence, setWithPolicePresence] = useState<string>('NO')
  const [withSlaughter, setWithSlaughter] = useState<string>('NO')
  const [withStrayBullet, setWithStrayBullet] = useState<string>('NO')
  const [victimParams, setVictimParams] = useState<Victim>({
    policeman: 'NO',
    adolescent: 'NO',
    adult: 'NO',
    elderly: 'NO'
  })

  const [occurrences, setOccurrences] = useState<Array<any>>([])
  const [currentPosition, setCurrentPosition] = useState<Position>(
    {} as Position
  )

  const [chartAllOccurrences, setChartAllOccurrences] = useState<
    Array<ChartData>
  >([])
  const [chartOccurrencesWithShoted, setChartOccurrencesWithShoted] = useState<
    Array<ChartData>
  >([])
  const [chartOccurrencesWithoutShoted, setChartOccurrencesWithoutShoted] =
    useState<Array<ChartData>>([])

  const [generalChartData, setGeneralChartData] = useState<GeneralChartData>({
    kills: { total: 0, civilians: 0, policemans: 0 },
    injureds: { total: 0, civilians: 0, policemans: 0 }
  })

  const handleFormatToSelectInput = (array: Array<State> | Array<City>) => {
    const newArray = array.map(item => {
      return { value: item.id, label: item.name }
    })

    newArray.unshift({ value: 'ALL', label: 'Todos' })
    return newArray
  }

  const handleSumOccurrences = (occus: Array<any>) => {
    return occus
      .map(occurrence => occurrence['Ocorrências'])
      .reduce((sum, a) => sum + a, 0)
  }

  const handleGetShotedPeople = (occus: Array<any>) => {
    return occus.filter(
      occurence =>
        occurence.number_civilians_dead ||
        occurence.number_civilians_wounded ||
        occurence.number_agent_dead ||
        occurence.number_agent_wounded
    )
  }

  const handleGetUnharmedPeople = (occus: Array<any>) => {
    return occus.filter(
      occurence =>
        occurence.number_civilians_dead === 0 &&
        occurence.number_civilians_wounded === 0 &&
        occurence.number_agent_dead === 0 &&
        occurence.number_agent_wounded === 0
    )
  }

  const handleFilterChartData = (occus: Array<any>) => {
    const date = new Date()

    const allOccurrences: Array<ChartData> = new Array()
    const withShotedOccurrences: Array<ChartData> = new Array()
    const withoutShotedOccurrences: Array<ChartData> = new Array()

    for (let index = 1; index < 13; index++) {
      let firstDayOfTheMonth = `${date.getFullYear()}-${index}-1`
      let month = moment(firstDayOfTheMonth).format('YYYY-MM')

      let occurrencesByMonth = occus.filter(occurrence =>
        occurrence.date.startsWith(month)
      )

      allOccurrences.push({
        name: MONTHS[index],
        Ocorrências: occurrencesByMonth.length
      })

      withShotedOccurrences.push({
        name: MONTHS[index],
        Ocorrências: handleGetShotedPeople(occurrencesByMonth).length
      })

      withoutShotedOccurrences.push({
        name: MONTHS[index],
        Ocorrências: handleGetUnharmedPeople(occurrencesByMonth).length
      })
    }

    setChartAllOccurrences(allOccurrences)
    setChartOccurrencesWithShoted(withShotedOccurrences)
    setChartOccurrencesWithoutShoted(withoutShotedOccurrences)

    const generalData: GeneralChartData = {
      kills: { total: 0, civilians: 0, policemans: 0 },
      injureds: { total: 0, civilians: 0, policemans: 0 }
    }

    occus.forEach(occurrence => {
      generalData.kills.total +=
        occurrence.number_civilians_dead + occurrence.number_agent_dead
      generalData.kills.civilians += occurrence.number_civilians_dead
      generalData.kills.policemans += occurrence.number_agent_dead

      generalData.injureds.total +=
        occurrence.number_civilians_wounded + occurrence.number_agent_wounded
      generalData.injureds.civilians += occurrence.number_civilians_wounded
      generalData.injureds.policemans += occurrence.number_agent_wounded
    })

    setGeneralChartData(generalData)
  }

  const handleGetOccurrences = async (useFilters: boolean = true) => {
    setIsLoading(true)

    try {
      let url = `/occurrences?take=10000000&`

      if (!useFilters || (!selectedInitialDate && !selectedFinalDate)) {
        const initialDate = moment().subtract(12, 'months').format(INIT_FORMAT)
        const finalDate = moment().format(FINAL_FORMAT)

        url += `initialdate=${initialDate}&finaldate=${finalDate}&`
      } else {
        if (useFilters && selectedInitialDate) {
          const initialDate = moment(selectedInitialDate).format(INIT_FORMAT)
          url += `initialdate=${initialDate}&`
        }

        if (useFilters && selectedFinalDate) {
          const finalDate = moment(selectedFinalDate).format(FINAL_FORMAT)
          url += `finaldate=${finalDate}&`
        }
      }

      if (useFilters) {
        if (selectedState && selectedState !== 'ALL')
          url += `state=${selectedState}&`
        if (selectedCity && selectedCity !== 'ALL')
          url += `city=${selectedCity}&`
      }

      const { data: response } = await api.get(url)

      if (response && response.data.length > 0) {
        let newOccurrences = filterAvailableOccurrences(response.data)

        if (useFilters) {
          if (withPolicePresence === 'YES') {
            newOccurrences = newOccurrences.filter(
              (occurence: any) => occurence.agent_presence === true
            )
          }

          if (withSlaughter === 'YES') {
            newOccurrences = newOccurrences.filter(
              (occurence: any) => occurence.massacre === true
            )
          }

          if (withStrayBullet === 'YES') {
            newOccurrences = handleGetShotedPeople(newOccurrences)
          }

          if (victimParams.policeman === 'YES') {
            newOccurrences = newOccurrences.filter((occurence: any) => {
              if (occurence.victims.length > 0) {
                return (
                  occurence.victims.filter(
                    (victim: any) => victim.type_person === 'Agent'
                  ).length > 0
                )
              }
            })
          }

          if (victimParams.adolescent === 'YES') {
            newOccurrences = newOccurrences.filter((occurence: any) => {
              if (occurence.victims.length > 0) {
                return (
                  occurence.victims.filter(
                    (victim: any) =>
                      victim.age && victim.age >= 12 && victim.age <= 17
                  ).length > 0
                )
              }
            })
          }

          if (victimParams.adult === 'YES') {
            newOccurrences = newOccurrences.filter((occurence: any) => {
              if (occurence.victims.length > 0) {
                return (
                  occurence.victims.filter(
                    (victim: any) =>
                      victim.age && victim.age >= 18 && victim.age <= 69
                  ).length > 0
                )
              }
            })
          }

          if (victimParams.elderly === 'YES') {
            newOccurrences = newOccurrences.filter((occurence: any) => {
              if (occurence.victims.length > 0) {
                return (
                  occurence.victims.filter(
                    (victim: any) => victim.age && victim.age >= 70
                  ).length > 0
                )
              }
            })
          }
        }

        setOccurrences(newOccurrences)
        handleFilterChartData(newOccurrences)
      } else {
        setOccurrences([])
        handleFilterChartData([])
      }
    } catch (err) {
      console.log('Error: ', err)
    }

    setIsLoading(false)
  }

  const handleClearFilters = () => {
    setSelectedInitialDate('')
    setSelectedFinalDate('')

    setSelectedCity('ALL')
    setSelectedState('ALL')

    setWithPolicePresence('NO')
    setWithSlaughter('NO')
    setWithStrayBullet('NO')

    setVictimParams({
      policeman: 'NO',
      adolescent: 'NO',
      adult: 'NO',
      elderly: 'NO'
    })

    handleGetOccurrences(false)
  }

  useEffect(() => {
    const getStates = async () => {
      try {
        const response = await api.get('/states')
        if (response && response.data.length > 0) setStates(response.data)
      } catch (err) {
        console.log('Error: ', err)
      }
    }

    getStates()
  }, [])

  useEffect(() => {
    const getCitites = async () => {
      try {
        const response = await api.get('/cities')

        if (response && response.data.length > 0) {
          if (selectedState !== 'ALL') {
            const foundedCitites = response.data.filter(
              (city: any) => city.state.id === selectedState
            )
            if (foundedCitites.length === 0) setSelectedCity('ALL')
            setCities(foundedCitites)
          } else {
            setCities(response.data)
          }
        }
      } catch (err) {
        console.log('Error: ', err)
      }
    }

    getCitites()
  }, [selectedState])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
        },
        err => {
          console.log('Error while geting user location!', err)
        }
      )
    } else {
      console.log('Browser does not support geolocation feature!')
    }
  }, [])

  useEffect(() => {
    handleGetOccurrences()
  }, [])

  return (
    // <>
    //     <Dados />

    //     <div>
    //         <S.Container>
    //             <S.Filters>
    //                 <strong>
    //                     Filtros:
    //                 </strong>

    //                 <S.SelectContent>
    //                     <Select
    //                         name="Estado"
    //                         text="Estados"
    //                         value={ selectedState }
    //                         valueOptions={ handleFormatToSelectInput(states) }
    //                         onSelect={ value => setSelectedState(value) }
    //                     />
    //                     <Select
    //                         name="Cidades"
    //                         text="Cidades"
    //                         value={ selectedCity }
    //                         valueOptions={ handleFormatToSelectInput(cities) }
    //                         onSelect={ value => setSelectedCity(value) }
    //                     />

    //                     <S.DataContent>
    //                         <InputData
    //                             label="Data inicial"
    //                             type="date"
    //                             value={ selectedInitialDate }
    //                             onChange={ event => setSelectedInitialDate(event.target.value) }
    //                             max={ moment().format('YYYY-MM-DD') }
    //                         />

    //                         <InputData
    //                             label="Data final"
    //                             type="date"
    //                             value={ selectedFinalDate }
    //                             onChange={ event => setSelectedFinalDate(event.target.value) }
    //                             min={ selectedInitialDate }
    //                             max={ moment().format('YYYY-MM-DD') }
    //                         />
    //                     </S.DataContent>
    //                 </S.SelectContent>

    //                 <div>
    //                     <S.Radios>
    //                         <InputRadio
    //                             value={ withPolicePresence }
    //                             onSelect={ choisee => setWithPolicePresence(choisee) }
    //                             name="withPolicePresence"
    //                             labelTop="Presença policial"
    //                         />

    //                         <InputRadio
    //                             value={ withSlaughter }
    //                             onSelect={ choisee => setWithSlaughter(choisee) }
    //                             name="withSlaughter"
    //                             labelTop="Chacina"
    //                         />

    //                         <InputRadio
    //                             value={ withStrayBullet }
    //                             onSelect={ choisee => setWithStrayBullet(choisee) }
    //                             name="withStrayBullet"
    //                             labelTop="Bala perdida"
    //                         />
    //                     </S.Radios>

    //                     <p style={{ marginBottom: '10px' }}>
    //                         Vitimas
    //                     </p>

    //                     <div>
    //                         <InputRadioVertical
    //                             value={ victimParams.policeman }
    //                             onSelect={ choisee => setVictimParams(previusState => ({ ...previusState, policeman: choisee })) }
    //                             name="victimPoliceman"
    //                             labelLeft="Policial"
    //                         />

    //                         <InputRadioVertical
    //                             value={ victimParams.adolescent }
    //                             onSelect={ choisee => setVictimParams(previusState => ({ ...previusState, adolescent: choisee })) }
    //                             name="victimAdolescent"
    //                             labelLeft="Adolescente"
    //                         />

    //                         <InputRadioVertical
    //                             value={ victimParams.adult }
    //                             onSelect={ choisee => setVictimParams(previusState => ({ ...previusState, adult: choisee })) }
    //                             name="victimAdult"
    //                             labelLeft="Adultos"
    //                         />

    //                         <InputRadioVertical
    //                             value={ victimParams.elderly }
    //                             onSelect={ choisee => setVictimParams(previusState => ({ ...previusState, elderly: choisee })) }
    //                             name="victimElderly"
    //                             labelLeft="Idosos"
    //                         />
    //                     </div>
    //                 </div>

    //                 <S.ClearFiltersButton onClick={ handleClearFilters }>
    //                     Limpar Filtros
    //                 </S.ClearFiltersButton>

    //                 <S.FilterButton onClick={ () => handleGetOccurrences() }>
    //                     { isLoading ? 'Filtrando Dados...' : 'Filtrar' }
    //                 </S.FilterButton>
    //             </S.Filters>

    //             <S.Maps>
    //                 {
    //                     isLoaded ? (
    //                         <>
    //                             {
    //                             (isLoading || occurrences.length === 0) && (
    //                                 <S.MapMessage>
    //                                     <span>
    //                                         {
    //                                             isLoading ? 'Carregando dados...' :
    //                                             occurrences.length === 0 ? 'Nenhuma ocorrência encontrada!' :
    //                                             'Algo de errado aconteceu! Tente novamente'
    //                                         }
    //                                     </span>
    //                                 </S.MapMessage>
    //                             )
    //                             }
    //                             {/* @ts-ignore */}
    //                             <GoogleMap
    //                                 zoom={ 10 }
    //                                 mapContainerStyle={{ width: '100%', height: '100%' }}
    //                                 options={{ disableDefaultUI: true }}
    //                                 center={ !isEmpty(currentPosition) ? currentPosition : MAP_CENTER }
    //                             >
    //                                 {/* @ts-ignore */}
    //                                 <MarkerClusterer
    //                                     averageCenter={ true }
    //                                     styles={[{ width: 1, height: 1, url: mapClusterPng, className: 'map__clusterer' }]}
    //                                     children={
    //                                         //@ts-ignore
    //                                         clusterer => occurrences.map(occurrence => (
    //                                             //@ts-ignore
    //                                             <Marker
    //                                                 icon={ hasShotedPeople(occurrence) ? alertRedPng : alertYellowPng }
    //                                                 clusterer={ clusterer }
    //                                                 position={{
    //                                                     lat: Number(occurrence.latitude),
    //                                                     lng: Number(occurrence.longitude)
    //                                                 }}
    //                                             />
    //                                         ))
    //                                     }
    //                                 />
    //                             </GoogleMap>
    //                         </>
    //                     ) : (
    //                         <p>Carregando mapa...</p>
    //                     )
    //                 }
    //             </S.Maps>

    //             <S.Charts>
    //                 <S.Content>
    //                     <strong>Gráficos</strong>

    //                     <div>
    //                         <button>
    //                             <img src={learnMore} alt="" style={{"transform": "rotateY(180deg)"}} />
    //                         </button>

    //                         <button>
    //                             <img src={learnMore} alt="" />
    //                         </button>
    //                     </div>
    //                 </S.Content>

    //                 <TinyBarChart
    //                     title="Ocorrências totais"
    //                     color="#6663FF"
    //                     width={ window.screen.width <= 450 ? 450 : 480 }
    //                     height={ 150 }
    //                     data={ chartAllOccurrences }
    //                     total={ handleSumOccurrences(chartAllOccurrences) }
    //                 />

    //                 <TinyBarChart
    //                     title="Tiros com baleados"
    //                     color="#BF091F"
    //                     width={ window.screen.width <= 450 ? 450 : 480 }
    //                     height={ 150 }
    //                     data={ chartOccurrencesWithShoted }
    //                     total={ handleSumOccurrences(chartOccurrencesWithShoted) }
    //                 />

    //                 <TinyBarChart
    //                     title="Tiros sem baleados"
    //                     color="#FE8C00"
    //                     width={ window.screen.width <= 450 ? 450 : 480 }
    //                     height={ 150 }
    //                     data={ chartOccurrencesWithoutShoted }
    //                     total={ handleSumOccurrences(chartOccurrencesWithoutShoted) }
    //                 />
    //             </S.Charts>
    //         </S.Container>

    //         <S.Report>
    //             <S.ReportLeft>
    //                 <S.ReportLeftContainer>
    //                     <div>
    //                         <h3>397</h3>
    //                         <p>Tiroteios/disparos de arma de fogo </p>
    //                     </div>
    //                     <S.ReportSpan>Informações referentes ao período de <strong>01/01/2021</strong> a <strong>30/08/2021</strong></S.ReportSpan>
    //                 </S.ReportLeftContainer>
    //                 <button>
    //                     <img src={arowIcon} alt="" />
    //                 </button>
    //             </S.ReportLeft>

    //             <S.ReportBlock>
    //                 <S.Red>
    //                     <strong>{ generalChartData.kills.total }</strong>
    //                     <p>Mortes</p>
    //                 </S.Red>

    //                 <S.BlockContent>
    //                     <div>
    //                         <img src={police} alt="" width={25}/>
    //                         <p><strong>{ generalChartData.kills.civilians }</strong> civis</p>
    //                     </div>
    //                     <div>
    //                         <img src={user} alt="" width={25} height={22.5}/>
    //                         <p><strong>{ generalChartData.kills.policemans }</strong> agentes de segurança</p>
    //                     </div>
    //                 </S.BlockContent>
    //             </S.ReportBlock>

    //             <S.ReportBlock>
    //                 <S.Yellow>
    //                     <strong>{ generalChartData.injureds.total }</strong>
    //                     <p>Feridos</p>
    //                 </S.Yellow>

    //                 <S.BlockContent>
    //                     <div>
    //                         <img src={police} alt="" width={25}/>
    //                         <p><strong>{ generalChartData.injureds.civilians }</strong> civis</p>
    //                     </div>
    //                     <div>
    //                         <img src={user} alt="" width={25} height={22.5}/>
    //                         <p><strong>{ generalChartData.injureds.policemans }</strong> agentes de segurança</p>
    //                     </div>
    //                 </S.BlockContent>
    //             </S.ReportBlock>

    //             <S.ReportDownload disabled={ true }>
    //                 <img src={icons_download} />
    //                 <p>Baixar relatório (em breve)</p>
    //             </S.ReportDownload>
    //         </S.Report>
    //     </div>

    // </>

    <>
      <Dados />
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <h1>Em breve!</h1>
      </div>
    </>
  )
}

export default ProduzaSeusDados
