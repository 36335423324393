import React from 'react'
import { DefaultBtn } from '../DefaultButton/style'

interface IProps {
  text: string
  to: string
}

const DefaultButton: React.FC<IProps> = props => {
  return <DefaultBtn to={props.to}>{props.text}</DefaultBtn>
}

export default DefaultButton
