import styled from 'styled-components'

export const Container = styled.div`
  width: auto;

  > p {
    margin-bottom: 6px;
  }
`

export const SelectItem = styled.select`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid rgba(69, 69, 69, 0.6);
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  width: 100%;

  > option {
    background-color: ${props => props.theme.colors.white};
  }
`
