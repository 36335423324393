import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  > div {
    margin: 0 auto;
    max-width: 1920px;
    margin-top: 142px;
    border-top: 2px solid ${props => props.theme.colors.gray};
    border-bottom: 2px solid ${props => props.theme.colors.gray};
    display: flex;
    align-items: center;
  }

  @media (max-width: 1199.98px) {
    > div {
      margin-top: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`

export const Button = styled(Link)<{ isActive?: boolean }>`
  padding: 18px 30px;
  background: none;
  text-decoration: none;
  color: ${props => props.theme.colors.tertiary};
  background: ${props =>
    props.isActive ? props.theme.colors.gray : 'transparent'};

  @media (max-width: 575.98px) {
    width: auto;
  }
`
