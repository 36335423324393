import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

    * {
        font-family: 'Libre Franklin', sans-serif !important;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, h6, p, a, button, strong, b, i, div, label, ol, ul, li {
        font-family: 'Libre Franklin', sans-serif !important;

        ::selection {
            background-color: ${props =>
              props.theme.colors.secondary} !important;
            color: ${props => props.theme.colors.white} !important;
        }
    }

    img {
        background: none !important;
    }

    html, body, #root {
        height: 100%;
    }
    *, button, input {
        border: 0;
        outline: 0;
    }
    
    button {
        cursor: pointer;
    }
    
    html {
        scroll-behavior: smooth;
    }

    a {
        color: ${props => props.theme.colors.secondary};
    }
`
