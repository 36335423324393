import React from 'react'
import {
  Container,
  SectionRight,
  SectionLeft,
  Title,
  Input,
  Assine,
  Button
} from './style'
import DefaultButton from '../../components/DefaultButton'

import logo from '../../assets/logoVertical.png'

const NewsLatter: React.FC = () => {
  return (
    <Container>
      <SectionLeft>
        <img src={logo} />
      </SectionLeft>
      <SectionRight>
        <Title>
          Quer receber <strong>notícias exclusivas</strong> do Fogo Cruzado?
        </Title>
        <Assine>
          Assine nossa newsletter gratuitamente e não perca nenhuma novidade.
        </Assine>
        <Input placeholder="Digite seu email" />
        <p>Ao se cadastrar você concorda com os termos de uso</p>
        <Button to="">Cadastrar</Button>
      </SectionRight>
    </Container>
  )
}

export default NewsLatter
