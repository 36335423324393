import { api } from '../api'
import moment from 'moment'

export function convertData(data: string) {
  let date = moment(data).format('DD/MM/YYYY')
  return `${date}`
}

export async function getRegions(token: string) {
  try {
    const resp = await api.get('/regions', {
      headers: { Authorization: `Bearer ${token}` }
    })
    return resp.data
  } catch (erro) {
    console.warn(erro)
  }
}

export async function getHome(token: any) {
  try {
    const resp = await api.get('/home', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error, 'erro')
  }
}

export async function getAbout(token: string) {
  try {
    const resp = await api.get('/about', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getTeam(token: string) {
  try {
    const resp = await api.get('/teams', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getPartners(token: string) {
  try {
    const resp = await api.get('/partners', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getAwards(token: string) {
  try {
    const resp = await api.get('/awards', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getAboutApi(token: string) {
  try {
    const resp = await api.get('/about-api', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getNews(token: string) {
  try {
    const reps = await api.get('/posts', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        type: 'Publicada'
      }
    })
    return reps.data
  } catch (error) {
    console.log(error, 'erro')
  }
}

export async function getNewsById(token: string, id: any) {
  try {
    const resp = await api.get(`/posts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        type: 'Publicada'
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getNewsByParam(
  token: any,
  order?: string,
  page?: string,
  take?: string
) {
  try {
    let params = new URLSearchParams()
    if (order != undefined) {
      params.append('oder', order)
    } else {
      params.append('order', 'DESC')
    }
    if (page != undefined) {
      params.append('page', page)
    } else {
      params.append('page', '1')
    }
    if (take != undefined) {
      params.append('take', take)
    } else {
      params.append('take', '4')
    }

    params.append('type', 'Publicada')

    const reps = await api.get('/posts', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: params
    })
    return reps.data
  } catch (error) {
    console.log(error)
  }
}

export async function getAllNewsByParams(
  token: any,
  order?: string,
  page?: string,
  take?: string,
  initialdate?: string,
  finaldate?: string,
  title?: string,
  region?: string,
  authors?: string,
  type?: string
) {
  try {
    let params = new URLSearchParams()

    if (order != undefined && order != '') {
      params.append('oder', order)
    } else {
      params.append('order', 'DESC')
    }
    if (page != undefined && page != '') {
      params.append('page', page.toString())
    } else {
      params.append('page', '1')
    }
    if (take != undefined && take != '') {
      params.append('take', take.toString())
    } else {
      params.append('take', '20')
    }
    if (initialdate != undefined && initialdate != '') {
      params.append('initialdate', initialdate)
    }
    if (finaldate != undefined && finaldate != '') {
      params.append('finaldate', finaldate)
    }
    if (title != undefined && title != '') {
      params.append('title', title)
    }
    if (region != undefined && region != '') {
      params.append('region', region)
    }
    if (authors != undefined && authors != '') {
      params.append('authors', authors)
    }

    params.append('type', 'Publicada')

    const reps = await api.get('/posts', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: params
    })
    return reps.data
  } catch (error) {
    console.log(error)
  }
}

export async function getPostsBySlug(token: string, slug?: string) {
  try {
    const resp = await api.get(`/posts/slug/${slug}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getSocialNetwork(token: string) {
  try {
    const reps = await api.get('/social-networks', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return reps.data
  } catch (error) {
    console.log(error, 'erro')
  }
}

export async function getTransparencies(token: string) {
  try {
    const resp = await api.get('/transparencies', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getImpact(
  token: string,
  order?: string,
  page?: number,
  take?: string,
  title?: string,
  media_type?: string,
  scope?: string,
  state?: string,
  field_knowledge?: string,
  year?: string,
  document_type?: string,
  subject_area?: string,
  federative_power?: string,
  search?: string
) {
  try {
    let params = new URLSearchParams()
    if (order != undefined && order != '') {
      params.append('oder', order)
    } else {
      params.append('order', 'DESC')
    }
    if (page != undefined && page != 0) {
      params.append('page', page.toString())
    } else {
      params.append('page', '1')
    }
    if (take != undefined && take != '') {
      params.append('take', take)
    } else {
      params.append('take', '20')
    }
    if (title != undefined && title != '') {
      params.append('title', title)
    }
    if (media_type != undefined && media_type != '') {
      params.append('media_type', media_type)
    }
    if (scope != undefined && scope != '') {
      params.append('scope', scope)
    }
    if (state != undefined && state != '') {
      params.append('state', state)
    }
    if (field_knowledge != undefined && field_knowledge != '') {
      params.append('field_knowledge', field_knowledge)
    }
    if (year != undefined && year != '') {
      params.append('year', year)
    }
    if (document_type != undefined && document_type != '') {
      params.append('document_type', document_type)
    }
    if (subject_area != undefined && subject_area != '') {
      params.append('subject_area', subject_area)
    }
    if (federative_power != undefined && federative_power != '') {
      params.append('federative_power', federative_power)
    }
    if (search != undefined && search != '') {
      params.append('search', search)
    }

    const resp = await api.get('/articles', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: params
    })
    return resp.data.data
  } catch (error) {
    console.log(error)
  }
}

export async function getReports(
  token?: string,
  order?: string,
  page?: number,
  take?: string,
  region?: string,
  search?: string
) {
  try {
    let params = new URLSearchParams()
    if (order != undefined && order != '') {
      params.append('order', order)
    }
    if (page != undefined && page != 0) {
      params.append('page', page.toString())
    } else {
      params.append('page', '1')
    }
    if (take != undefined && take != '') {
      params.append('take', '20')
    }
    if (region != undefined && region != '') {
      params.append('region', region)
    }
    if (search != undefined && region != '') {
      params.append('search', search)
    }

    const resp = await api.get(`/reports`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: params
    })

    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getReportsBySlug(token: string, slug?: string) {
  try {
    const resp = await api.get(`/reports/slug/${slug}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getImageSocialMedia(token: string, id?: string) {
  try {
    let param = ''

    if (id != undefined && id != null && id != '') {
      param = id
    } else {
      param = '6ad5ac7d-a6a0-4dfa-b140-5d6ea20e6695'
    }

    const resp = await api.get(`/uploads/${param}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}

export async function getStatistics(token: string) {
  try {
    const resp = await api.get('/occurrences/summary', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return resp.data
  } catch (error) {
    console.log(error)
  }
}
