import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Store } from './store'
import App from './App'
import { ThemeProvider } from 'styled-components'
import colors from './style/theme/colors'
import GlobalStyles from './style/GlobalStyles'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={colors}>
      <GlobalStyles />
      <Provider store={Store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
