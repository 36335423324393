import React, { useEffect, useState } from 'react'
import {
  Container,
  Header,
  Back,
  Content,
  Div,
  Section,
  Icon,
  TwoBlocks,
  ImgPost,
  ImgContent,
  Post,
  Line,
  Footer,
  ImgPostSolo
} from './style'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { useParams } from 'react-router'
import {
  convertData,
  getNews,
  getNewsByParam,
  getPostsBySlug
} from '../../../functions'
import { GET_NEWS } from '../../../store/actions'
import renderHTML from 'react-render-html'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton
} from 'react-share'

import paper from '../../../assets/paper.png'
import date from '../../../assets/calendar.png'
import learnMore from '../../../assets/learnMore.png'
import facebook from '../../../assets/facebook-dark.png'
import twitter from '../../../assets/twitter-dark.png'
import telegram from '../../../assets/telegram-dark.png'
import linkedin from '../../../assets/linkedin-dark.png'
import CardPost from '../../../components/CardPost'

const Postagem: React.FC = () => {
  const dispatch = useDispatch()
  const { token, posts } = useSelector((state: RootState) => state.clickState)
  const [post, setPost] = useState<any>({
    title: '',
    slug: '',
    author: {
      id: '',
      name: '',
      nickname: null,
      email: ''
    },
    region: {
      id: '',
      region: '',
      state: '',
      enabled: false
    },
    cover: {
      id: '',
      file: ''
    },
    emphasis: '',
    type: '',
    items: [
      {
        group: '',
        typeBlock: '',
        quantBlock: '',
        group_items: [
          {
            image: {
              id: '',
              file: ''
            },
            description: '',
            content: ''
          }
        ]
      }
    ],
    createdAt: '',
    updatedAt: '',
    id: '',
    authors: ''
  })

  let { slug } = useParams()

  useEffect(() => {
    getNewsByParam(token, 'DESC', '1', '4').then(resp => {
      dispatch({ type: GET_NEWS, posts: resp.data })
    })
    getPostsBySlug(token, slug).then(resp => {
      setPost(resp)
    })
  }, [slug])

  return (
    <Container>
      <div>
        {[post]?.map((id: any) => {
          return (
            <>
              <Helmet title={id?.title} />
              <Header>
                <Back to="/noticias">
                  <img src={learnMore} />
                </Back>
                <img src={id?.cover?.file} />
              </Header>
              <Content>
                <div>
                  {id?.region?.state?.length > 0
                    ? renderHTML(id?.region?.state)
                    : ''}
                </div>
                <h3>{id?.title}</h3>
                <div>
                  <Div>
                    <div>
                      <Icon src={paper} />
                      <div>
                        {id?.authors?.length > 0 ? renderHTML(id?.authors) : ''}
                      </div>
                    </div>
                    <div>
                      <Icon src={date} />
                      <p>
                        {id?.date_post == ''
                          ? convertData(id?.updatedAt)
                          : convertData(id?.date_post)}
                      </p>
                    </div>
                  </Div>
                  <div>
                    <FacebookShareButton url={`webstage.fogocruzado.org.br/noticias/${id?.id}`}>
      <img
        src={facebook}
        alt="Facebook"
        style={{ width: '20px', height: '20px' }} // Ajuste as dimensões conforme necessário
      />
    </FacebookShareButton>
                    <TwitterShareButton
                      url={`webstage.fogocruzado.org.br/noticias/${id?.id}`}
                      title={id.title}
                    >
                      <img src={twitter} alt="" />
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={`webstage.fogocruzado.org.br/noticias/${id?.id}`}
                      title={id.title}
                    >
                      <img src={telegram} alt="" />
                    </TelegramShareButton>
                    <LinkedinShareButton
                      url={`webstage.fogocruzado.org.br/noticias/${id?.id}`}
                      title={id.title}
                    >
                      <img src={linkedin} alt="" />
                    </LinkedinShareButton>
                  </div>
                </div>
              </Content>
              <Post>
                {id?.items?.map((items: any) => {
                  if (items.group == 'bloco 1') {
                    return (
                      <Section>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <>
                              <ImgContent>
                                {group_items?.image?.file != '' &&
                                group_items?.image?.file != undefined ? (
                                  <ImgPost src={group_items?.image?.file} />
                                ) : (
                                  <div style={{ display: 'none' }} />
                                )}
                                <div>
                                  {group_items?.description?.length > 0
                                    ? renderHTML(group_items?.description)
                                    : ''}
                                </div>
                              </ImgContent>
                              <div className="app">
                                {group_items?.content?.length > 0
                                  ? renderHTML(group_items?.content)
                                  : ''}
                              </div>
                            </>
                          )
                        })}
                      </Section>
                    )
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '2' &&
                    items.typeBlock == 'image'
                  ) {
                    return (
                      <TwoBlocks>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <ImgPostSolo>
                              {group_items?.image?.file != '' &&
                              group_items?.image?.file != undefined ? (
                                <img src={group_items?.image?.file} />
                              ) : (
                                <div style={{ display: 'none' }} />
                              )}
                              <div>
                                {group_items?.description?.length > 0
                                  ? renderHTML(group_items?.description)
                                  : ''}
                              </div>
                            </ImgPostSolo>
                          )
                        })}
                      </TwoBlocks>
                    )
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '2' &&
                    items.typeBlock == 'text'
                  ) {
                    return (
                      <TwoBlocks>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <div className="app">
                              {group_items?.content?.length > 0
                                ? renderHTML(group_items?.content)
                                : ''}
                            </div>
                          )
                        })}
                      </TwoBlocks>
                    )
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '2' &&
                    items.typeBlock == 'code'
                  ) {
                    return (
                      <TwoBlocks>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <div className="app">
                              {group_items?.content?.length > 0
                                ? renderHTML(group_items?.content)
                                : ''}
                            </div>
                          )
                        })}
                      </TwoBlocks>
                    )
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '1' &&
                    items.typeBlock == 'image'
                  ) {
                    return (
                      <ImgPostSolo>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <>
                              {group_items?.image?.file != '' &&
                              group_items?.image?.file != undefined ? (
                                <img src={group_items?.image?.file} />
                              ) : (
                                <div style={{ display: 'none' }} />
                              )}
                              <div>
                                {group_items?.description?.length > 0
                                  ? renderHTML(group_items?.description)
                                  : ''}
                              </div>
                            </>
                          )
                        })}
                      </ImgPostSolo>
                    )
                  }
                })}
              </Post>
              <Line />
              <Footer>
                {posts.map((id: any) => {
                  return (
                    <CardPost
                      img={id?.cover?.file}
                      to={`/${id?.slug}`}
                      local={
                        id?.region?.state?.length > 0
                          ? renderHTML(id?.region?.state)
                          : ''
                      }
                      title={id?.title.length > 0 ? renderHTML(id?.title) : ''}
                      author={
                        id?.authors?.length > 0 ? renderHTML(id?.authors) : ''
                      }
                      date={
                        id?.date_post == ''
                          ? convertData(id?.updatedAt)
                          : convertData(id?.date_post)
                      }
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  )
                })}
              </Footer>
            </>
          )
        })}
      </div>
    </Container>
  )
}

export default Postagem
