import * as React from 'react'
import { Container, DivFlex, Column, RadioGroup, Select, Input } from './style'

const Pesquisar: React.FC = () => {
  return (
    <Container>
      <h3>API {'>'} Pesquisar</h3>
      <form>
        <div>
          <DivFlex>
            <Column>
              <label htmlFor="">Estados</label>
              <Select name="" id="">
                <option value="">Todos</option>
              </Select>
            </Column>
            <Column>
              <label htmlFor="">Estados</label>
              <Select name="" id="">
                <option value="">Todos</option>
              </Select>
            </Column>
            <Column>
              <label htmlFor="">Data inicial</label>
              <Input type="date" name="" id="" placeholder="Data inicial" />
            </Column>
            <Column>
              <label htmlFor="">Data final</label>
              <Input type="date" name="" id="" placeholder="Data final" />
            </Column>
          </DivFlex>
          <DivFlex>
            <Column>
              <label htmlFor="">Presença policial</label>
              <RadioGroup>
                <label htmlFor="presencaPoliciaSim">Sim</label>
                <input
                  type="radio"
                  id="presencaPolicia"
                  name="presencaPolicia"
                  value="sim"
                  checked
                />
                <label htmlFor="presencaPoliciaNao">Não</label>
                <input
                  type="radio"
                  id="presencaPolicia"
                  name="presencaPolicia"
                  value="nao"
                />
              </RadioGroup>
            </Column>
            <Column>
              <label htmlFor="">Chacina</label>
              <RadioGroup>
                <label htmlFor="chachinaSim">Sim</label>
                <input
                  type="radio"
                  id="chachina"
                  name="chachina"
                  value="sim"
                  checked
                />
                <label htmlFor="chachinaNao">Não</label>
                <input type="radio" id="chachina" name="chachina" value="nao" />
              </RadioGroup>
            </Column>
            <Column>
              <label htmlFor="">Bala perdida</label>
              <RadioGroup>
                <label htmlFor="balaPerdidaSim">Sim</label>
                <input
                  type="radio"
                  id="balaPerdida"
                  name="balaPerdida"
                  value="sim"
                  checked
                />
                <label htmlFor="balaPerdidaNao">Não</label>
                <input
                  type="radio"
                  id="balaPerdida"
                  name="balaPerdida"
                  value="nao"
                />
              </RadioGroup>
            </Column>
          </DivFlex>
          <DivFlex>
            <fieldset>
              <h5>Vitimas</h5>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Policial</label>
                  </div>
                  <div>
                    <label htmlFor="policialSim">Sim</label>
                    <input
                      type="radio"
                      id="policial"
                      name="policial"
                      value="sim"
                      checked
                    />
                    <label htmlFor="policialNao">Não</label>
                    <input
                      type="radio"
                      id="policial"
                      name="policial"
                      value="nao"
                    />
                  </div>
                </RadioGroup>
              </fieldset>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Adolecente</label>
                  </div>
                  <div>
                    <label htmlFor="adolecenteSim">Sim</label>
                    <input
                      type="radio"
                      id="adolecente"
                      name="adolecente"
                      value="sim"
                      checked
                    />
                    <label htmlFor="adolecenteNao">Não</label>
                    <input
                      type="radio"
                      id="adolecente"
                      name="adolecente"
                      value="nao"
                    />
                  </div>
                </RadioGroup>
              </fieldset>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Adulto</label>
                  </div>
                  <div>
                    <label htmlFor="adultaSim">Sim</label>
                    <input
                      type="radio"
                      id="adulta"
                      name="adulta"
                      value="sim"
                      checked
                    />
                    <label htmlFor="adultaNao">Não</label>
                    <input type="radio" id="adulta" name="adulta" value="nao" />
                  </div>
                </RadioGroup>
              </fieldset>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Idoso</label>
                  </div>
                  <div>
                    <label htmlFor="idosoSim">Sim</label>
                    <input
                      type="radio"
                      id="idoso"
                      name="idoso"
                      value="sim"
                      checked
                    />
                    <label htmlFor="idosoNao">Não</label>
                    <input type="radio" id="idoso" name="idoso" value="nao" />
                  </div>
                </RadioGroup>
              </fieldset>
            </fieldset>
            <fieldset>
              <h5>Local</h5>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Residência</label>
                  </div>
                  <div>
                    <label htmlFor="residenciaSim">Sim</label>
                    <input
                      type="radio"
                      id="residencia"
                      name="residencia"
                      value="sim"
                      checked
                    />
                    <label htmlFor="residenciaNao">Não</label>
                    <input
                      type="radio"
                      id="residencia"
                      name="residencia"
                      value="nao"
                    />
                  </div>
                </RadioGroup>
              </fieldset>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Instituição escolar</label>
                  </div>
                  <div>
                    <label htmlFor="escolaSim">Sim</label>
                    <input
                      type="radio"
                      id="escola"
                      name="escola"
                      value="sim"
                      checked
                    />
                    <label htmlFor="escolaNao">Não</label>
                    <input type="radio" id="escola" name="escola" value="nao" />
                  </div>
                </RadioGroup>
              </fieldset>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Transporte publico</label>
                  </div>
                  <div>
                    <label htmlFor="transPublicoSim">Sim</label>
                    <input
                      type="radio"
                      id="transPublico"
                      name="transPublico"
                      value="sim"
                      checked
                    />
                    <label htmlFor="transPublicoNao">Não</label>
                    <input
                      type="radio"
                      id="transPublico"
                      name="transPublico"
                      value="nao"
                    />
                  </div>
                </RadioGroup>
              </fieldset>
              <fieldset>
                <RadioGroup>
                  <div>
                    <label htmlFor="">Via parada</label>
                  </div>
                  <div>
                    <label htmlFor="viaParadaSim">Sim</label>
                    <input
                      type="radio"
                      id="viaParada"
                      name="viaParada"
                      value="sim"
                      checked
                    />
                    <label htmlFor="viaParadaNao">Não</label>
                    <input
                      type="radio"
                      id="viaParada"
                      name="viaParada"
                      value="nao"
                    />
                  </div>
                </RadioGroup>
              </fieldset>
            </fieldset>
            <fieldset>
              <button type="submit">Filtrar</button>
            </fieldset>
          </DivFlex>
        </div>
        <div>
          <button type="button">Fazer download .CSV</button>
          <button type="button" style={{ marginLeft: '5px' }}>
            Fazer download Excel
          </button>
        </div>
      </form>
    </Container>
  )
}

export default Pesquisar
