import React, { useState, useEffect } from 'react'
import { Container } from './style'

import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import CardPost from '../../components/CardPost'
import { convertData } from '../../functions'

const PesquisaSite: React.FC = () => {
  let { posts, seachSite } = useSelector((state: RootState) => state.clickState)

  return (
    <>
      <Container>
        <div>
          <h3>Resultados da pesquisa por: {seachSite}</h3>
        </div>

        <section>
          {posts?.map((id: any) => {
            return (
              <CardPost
                author={id?.authors}
                date={convertData(id?.createdAt)}
                img={id?.cover?.file}
                local={id?.region?.state}
                title={id?.title}
                to={`/${id?.slug}`}
              />
            )
          })}
        </section>
      </Container>
    </>
  )
}

export default PesquisaSite
