import styled from 'styled-components'

export const Container = styled.button<{ selected: boolean }>`
  padding: 8px 12px;
  background: none;
  border-radius: 20px;
  transition: 0.3s ease all;
  background-color: ${props =>
    props.selected ? props.theme.colors.secondary : 'transparent'};
  color: ${props =>
    props.selected ? props.theme.colors.white : props.theme.colors.tertiary};
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.colors.secondary
        : props.theme.colors.tertiary};
`
