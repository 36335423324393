import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fff !important;
  width: 100%;
  padding: 20px 0 40px;

  > h3 {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 67px;
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 80px;
  }

  > div {
    max-width: 1920px;
    padding: 0 80px;
    margin: 0 auto;
  }

  @media (max-width: 767.98px) {
    padding: 80px 20px;

    > div {
      padding: 0;
    }
  }
`

export const ContentSearch = styled.div`
  > fieldset {
    width: 100%;
    padding: 16px 29px;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    margin: 24px 0;

    > input {
      width: 100%;
    }

    > img {
      width: 28px;
      height: 28px;
    }
  }

  @media (max-width: 767.98px) {
    > fieldset {
    }
  }
`

export const ContentSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > fieldset {
    display: flex;
    flex-direction: column;
    margin-left: 18px;

    > label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #454545;
      margin-bottom: 5px;
    }
  }

  @media (max-width: 767.98px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    > fieldset {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    justify-content: center;
    align-items: center;
  }
`

export const NewsContent = styled.div`
  > div {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991.98px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > a {
        margin-bottom: 40px;
      }
    }

    @media (min-width: 992px) and (max-width: 1599.98px) {
      display: flex;
      flex-direction: column;

      > a {
        width: 100%;
      }

      > a:nth-child(1) {
        margin-bottom: 20px;
      }
    }

    @media (min-width: 1600px) and (max-width: 1919.98px) {
      > a {
        width: calc(50% - 28px);
      }
    }

    @media (min-width: 1920px) {
      > a {
        width: calc(50% - 28px);
      }
    }
  }
`

export const OldPosts = styled.div`
  > div {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: space-between;
    gap: 40px 0;

    > img {
      width: 300px;
    }

    > div {
      width: 300px;
    }

    @media (max-width: 991.98px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -20px;
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-around;
      gap: 100px 20px;
    }

    @media (min-width: 1200px) and (max-width: 1820px) {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-between;
    }

    @media (min-width: 1821px) {
      display: grid;
      grid-template-columns: auto auto auto auto;
      justify-content: space-between;
    }
  }
`

export const Loading = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
`

export const Select = styled.select`
  border: 1px solid ${props => props.theme.colors.tertiary};
  background-color: #fff !important;
  width: 305px;
  padding: 11px 16px;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`

export const Paginatior = styled.div`
  height: 100px;
  padding: 40px 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 575.98px) {
    justify-content: center;
  }
`
export const PrevNext = styled.button<{ to: string; disabled: boolean }>`
  background-color: transparent;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: none;
  opacity: ${props => (props.disabled ? 0.3 : 1)};

  > img {
    width: 40px;
    height: 40px;
    ${props => {
      if (props.to == 'next') {
        return `
          transform: rotate(0deg);
        `
      } else if (props.to == 'prev') {
        return `
          transform: rotate(190deg);
        `
      }
    }}
  }
`

export const Indicator = styled.p`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.secondary};
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
`
