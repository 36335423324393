import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'

import { useFormik } from 'formik'

import TypeButton from './TypeButton'
import RadioButton from '../../components/RadioButton'

import { donationValidationSchema, donationValueMask } from './validations'
import * as S from './style'
import { Helmet } from 'react-helmet'

const SejaApoiador: React.FC = () => {
  const [showDonationValueInput, setShowDonationValueInput] =
    useState<boolean>(false)

  const handlePayment = async (values: any) => {
    console.log('Values: ', values)
  }

  const handleSelectDonationValue = (selectedValue: string) => {
    form.setFieldValue('donationValue', selectedValue)
    setShowDonationValueInput(false)
  }

  const form = useFormik({
    initialValues: {
      donationType: 'MONTHLY',
      donationValue: '',
      email: ''
    },
    validateOnMount: true,
    validationSchema: donationValidationSchema,
    onSubmit: handlePayment
  })

  return (
    <S.Container>
      <Helmet title="Seja um apoiador - Fogo Cruzado" />
      <h3>Seja um apoiador</h3>
      <p>
        Tecnologia e dados abertos podem salvar vidas. Ajude o Fogo Cruzado a
        produzir mais informações e a gerar mais mobilização.
      </p>

      <S.ContainerForm>
        <S.Section>
          <p className="section__title">
            <strong>Tipo</strong> de doação
          </p>

          <div className="section__buttons">
            <TypeButton
              text="Doar mensalmente"
              selected={form.values.donationType === 'MONTHLY'}
              onSelect={() => form.setFieldValue('donationType', 'MONTHLY')}
            />

            <TypeButton
              text="Doar anualmente"
              selected={form.values.donationType === 'ANNUALLY'}
              onSelect={() => form.setFieldValue('donationType', 'ANNUALLY')}
            />

            <TypeButton
              text="Doar uma única vez"
              selected={form.values.donationType === 'ONLY_ONCE'}
              onSelect={() => form.setFieldValue('donationType', 'ONLY_ONCE')}
            />
          </div>
        </S.Section>

        <S.Section>
          <p className="section__title">
            <strong>Valor</strong> de doação (R$)
          </p>

          <div className="section__radios">
            <div className="radio">
              <RadioButton
                selected={form.values.donationValue === 'R$10,00'}
                onSelect={() => handleSelectDonationValue('R$10,00')}
                name="donationValue"
                value="10"
              />

              <label>R$ 10,00</label>
            </div>

            <div className="radio">
              <RadioButton
                selected={form.values.donationValue === 'R$50,00'}
                onSelect={() => handleSelectDonationValue('R$50,00')}
                name="donationValue"
                value="50"
              />

              <label>R$ 50,00</label>
            </div>

            <div className="radio">
              <RadioButton
                selected={form.values.donationValue === 'R$100,00'}
                onSelect={() => handleSelectDonationValue('R$100,00')}
                name="donationValue"
                value="100"
              />

              <label>R$ 100,00</label>
            </div>

            <div className="radio">
              <RadioButton
                selected={form.values.donationValue === 'R$500,00'}
                onSelect={() => handleSelectDonationValue('R$500,00')}
                name="donationValue"
                value="500"
              />

              <label>R$ 500,00</label>
            </div>

            <div className="radio">
              <RadioButton
                selected={form.values.donationValue === 'other'}
                onSelect={() => {
                  form.setFieldValue('donationValue', 'other')
                  setShowDonationValueInput(true)
                }}
                name="donationValue"
                value="other"
              />

              <label>Outro</label>
            </div>
          </div>

          {showDonationValueInput && (
            <ReactInputMask
              placeholder="Valor"
              id="donationValue"
              name="donationValue"
              className="donation__value"
              mask={'R$ 999.999,99'}
              value={form.values.donationValue}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
          )}
        </S.Section>

        <S.Section>
          <p className="section__title">
            <strong>Seu</strong> e-mail para contato
          </p>

          <S.Input
            placeholder="Seu e-mail aqui"
            type="email"
            id="email"
            name="email"
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </S.Section>

        <S.Section>
          <p className="section__title">
            <strong>Finalizar</strong> sua contribuição
          </p>

          <button
            className="finish__button"
            disabled={!form.isValid} // @ts-ignore
            onClick={form.handleSubmit}
          >
            {form.isSubmitting ? 'Carregando...' : 'Finalizar'}
          </button>
        </S.Section>
      </S.ContainerForm>
    </S.Container>
  )
}

export default SejaApoiador
