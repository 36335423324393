import React, { useState } from 'react'
import {
  Container,
  ContentTop,
  Logo,
  Navegacao,
  LinkNav,
  SocialMedia,
  NewsLetter,
  Input,
  Creative,
  ContainerResp,
  BtnResp,
  LinkResp
} from '../Footer/style'
import DefaultButton from '../DefaultButton'
import { Link } from 'react-router-dom'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import MailchimpFormContainer from '../MailchimpFormContainer'
import CustomForm from '../MailchimpFormContainer/customForm'

import logo from '../../assets/logo-branco.png'
import creativeCommonsImg from '../../assets/cc-zero.png'
import facebook from '../../assets/facebook.png'
import twitter from '../../assets/twitter.png'
import instagram from '../../assets/instagram.png'
import youtube from '../../assets/youtube.png'
import linkedin from '../../assets/linkedin.png'

const Footer: React.FC = () => {
  return (
    <>
      <Container>
        <div>
          <ContentTop>
            <Link to="">
              <Logo src={logo} />
            </Link>
            <Navegacao>
              <p>Informativos</p>
              <LinkNav to="/politica-de-privacidade">
                Política de privacidade
              </LinkNav>
              <LinkNav to="/fale-conosco">Fale conosco</LinkNav>
              <LinkNav to="/perguntas-frequentes">Perguntas frequentes</LinkNav>
            </Navegacao>
            <SocialMedia>
              <p>Nossas redes sociais</p>
              <div>
                <button>
                  <a href="http://bit.ly/face-Fogo" target="_blank">
                    <img alt="icon" src={facebook} />
                  </a>
                </button>
                <button>
                  <a href="https://bit.ly/twitter-Fogo" target="_blank">
                    <img alt="icon" src={twitter} />
                  </a>
                </button>
                <button>
                  <a href="http://bit.ly/IG-FogoCruzado" target="_blank">
                    <img alt="icon" src={instagram} />
                  </a>
                </button>
                <button>
                  <a href="https://www.youtube.com/fogocruzado" target="_blank">
                    <img alt="icon" src={youtube} />
                  </a>
                </button>
                <button>
                  <a
                    href="https://www.linkedin.com/company/fogo-cruzado"
                    target="_blank"
                  >
                    <img alt="icon" src={linkedin} />
                  </a>
                </button>
              </div>
            </SocialMedia>
            <NewsLetter>
              <h3>Cadastre-se em nossa newsletter gratuitamente!</h3>
              <MailchimpFormContainer />
              <div>
                <p>Ao se cadastrar você concorda com os termos de uso</p>
              </div>
            </NewsLetter>
          </ContentTop>
          <Creative>
            <img alt="icon" src={creativeCommonsImg} />
            <p>
              Este projeto é feito colaborativamente para comunidades. Por isso,
              o uso do seu conteúdo é livre.
            </p>
          </Creative>
        </div>
      </Container>

      <ContainerResp>
        <h4>Cadastre-se em nossa newsletter gratuitamente!</h4>
        <MailchimpFormContainer />

        <div>
          <p>
            {' '}
            Ao se cadastrar, você concorda com nossos{' '}
            <Link to="/termos-de-uso" style={{ color: '#FFF' }}>
              {' '}
              termos de uso
            </Link>
            .
          </p>
        </div>
        <nav>
          <h4>Informativos</h4>
          <LinkResp to="/politica-de-privacidade">
            {' '}
            Politica de privacidadde
          </LinkResp>
          <LinkResp to="/fale-conosco">Fale conosco</LinkResp>
          <LinkResp to="/perguntas-frequentes">Perguntas frequentes</LinkResp>
        </nav>
        <h2>Nossas redes institucionais</h2>
        <section>
          <a href="http://bit.ly/face-Fogo" target="_blank">
            <img alt="icon" src={facebook} width="10.5px" height="22.5px" />
          </a>
          <a href="https://bit.ly/twitter-Fogo" target="_blank">
            <img alt="icon" src={twitter} width="25px" height="20.32px" />
          </a>
          <a href="http://bit.ly/IG-FogoCruzado" target="_blank">
            <img alt="icon" src={instagram} width="25.02px" height="25.3px" />
          </a>
          <a href="https://www.youtube.com/fogocruzado" target="_blank">
            <img alt="icon" src={youtube} width="24.04px" height="17.44px" />
          </a>
          <a
            href="https://www.linkedin.com/company/fogo-cruzado"
            target="_blank"
          >
            <img alt="icon" src={linkedin} width="25.12px" height="24.01" />
          </a>
        </section>
      </ContainerResp>
    </>
  )
}

export default Footer

function onValidated(arg0: { EMAIL: string }) {
  throw new Error('Function not implemented.')
}
