import React from 'react'
import {
  Card,
  Banner,
  Region,
  Title,
  Content,
  Icon,
  Button
} from '../CardPost/style'

import paper from '../../assets/paper.png'
import date from '../../assets/calendar.png'
import learnMore from '../../assets/learnMore.png'

interface IProps {
  to: string
  img: string
  local: string
  title: string
  author: string
  date: string
  onClick?: () => void
}

const CardPost: React.FC<IProps> = props => {
  return (
    <Card to={props.to}>
      <Banner src={props.img} />
      <Region>{props.local}</Region>
      <Title>{props.title}</Title>
      <Content>
        <div>
          <div>
            <Icon src={paper} />
            <p>{props.author}</p>
          </div>
          <div>
            <Icon src={date} />
            <p>{props.date}</p>
          </div>
        </div>
        <Button to={props.to} onClick={props.onClick}>
          <img src={learnMore} />
        </Button>
      </Content>
    </Card>
  )
}

export default CardPost
