import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  color: ${props => props.theme.colors.tertiary};
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  height: 310px;

  > img {
    width: 300px;
    height: 160px;
  }

  > span {
    padding: 24px;

    > h3 {
      margin-top: 4px;
    }
  }
`

export const Card = styled.div`
  padding: 24px;
  width: 100%;

  > div {
    padding-bottom: 24px;
    > h5 {
      float: right;
      margin-bottom: 20px;
    }
  }
`

export const Download = styled.a`
  float: right;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.tertiary};
`
