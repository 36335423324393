import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 150px;
  width: 100%;

  > div {
    max-width: 1920px;
    margin: 0 auto;
    padding: 40px 80px;
  }

  > section {
    padding: 0 80px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
