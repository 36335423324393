export const initialState = {
  seachSite: '',
  token: '',
  slug: '',
  user: {
    nickname: '',
    password: '',
    region: '',
    security_question: '',
    security_answer: '',
    trusted: true,
    active: true,
    name: '',
    email: '',
    phone_number: '',
    state: '',
    city: '',
    role: '',
    createdAt: '',
    updatedAt: '',
    id: ''
  },
  home: [
    {
      id: '',
      title: '',
      description: '',
      image: {
        id: '',
        file: ''
      }
    }
  ],
  image_sm: {
    id: '',
    file: ''
  },
  about: [
    {
      id: '',
      name: '',
      title: '',
      number: 0,
      items: [
        {
          image: {
            id: '',
            file: ''
          },
          title: '',
          description: '',
          order: 0,
          person: [
            {
              name: '',
              role: '',
              group: '',
              image: {
                id: '',
                file: ''
              },
              order: 0,
              description: ''
            }
          ]
        }
      ]
    }
  ],
  about_api: [
    {
      id: '',
      name: '',
      title: '',
      description: '',
      image: {
        id: '',
        file: ''
      },
      items: [
        {
          title: '',
          description: '',
          image: {
            id: '',
            file: ''
          }
        }
      ]
    }
  ],
  team: [
    {
      name: '',
      description: '',
      role: '',
      group: '',
      order: 0,
      image: '',
      id: ''
    }
  ],
  partners: [
    {
      name: '',
      description: '',
      image: '',
      id: '',
      order: 0
    }
  ],
  awards: [
    {
      title: '',
      description: '',
      image: '',
      id: '',
      order: 0
    }
  ],
  posts: [
    {
      title: '',
      slug: '',
      author: {
        id: '',
        name: '',
        nickname: null,
        email: ''
      },
      region: {
        id: '',
        region: '',
        state: '',
        enabled: false
      },
      cover: {
        id: '',
        file: ''
      },
      thumbnail: {
        id: '',
        file: ''
      },
      emphasis: '',
      type: '',
      items: [
        {
          group: '',
          typeBlock: '',
          quantBlock: '',
          group_items: [
            {
              image: {
                id: '',
                file: ''
              },
              description: '',
              content: ''
            }
          ]
        }
      ],
      createdAt: '',
      updatedAt: '',
      id: '',
      authors: ''
    }
  ],
  posts_news: [
    {
      title: '',
      author: {
        id: '',
        name: '',
        nickname: '',
        email: ''
      },
      region: {
        id: '',
        region: '',
        state: '',
        enabled: false
      },
      cover: {
        id: '',
        file: ''
      },
      thumbnail: {
        id: '',
        file: ''
      },
      type: '',
      emphasis: '',
      items: [
        {
          group: '',
          typeBlock: '',
          quantBlock: '',
          group_items: [
            {
              image: {
                id: '',
                file: ''
              },
              description: '',
              content: ''
            }
          ]
        }
      ],
      createdAt: '',
      updatedAt: '',
      id: '',
      authors: ''
    }
  ],
  social_networks: [
    {
      region: '',
      facebook: '',
      twitter: '',
      telegram: '',
      instagram: '',
      youtube: '',
      linkedin: '',
      whatsapp: '',
      image: {
        id: '',
        file: ''
      },
      id: ''
    }
  ],
  impact: [
    {
      id: '',
      publication_date: '',
      title: '',
      link: '',
      theme: '',
      indicators_fc: '',
      sub_identifiers_fc: '',
      keyword: '',
      media_type: '',
      vehicle_name: '',
      program_or_section: '',
      access_type: '',
      scope: '',
      continent: '',
      country: '',
      state: '',
      city: '',
      place: '',
      emphasis: '',
      spokesman: '',
      exclusive: false,
      hook: '',
      digital: false,
      type_of_contact: '',
      contact_channel: '',
      bait: '',
      axis_fc: '',
      year: 0,
      federative_power: '',
      document_type: '',
      status: '',
      document_details: '',
      subject_area: '',
      public_reference: '',
      contact: '',
      fc_influence: '',
      type_material: '',
      field_knowledge: '',
      associated_theme_fc: '',
      inputs_used: '',
      author: '',
      institutional_binding: '',
      magazine_name: '',
      type: '',
      createdAt: '',
      updatedAt: ''
    }
  ],
  transparencies: [
    {
      name: '',
      title: '',
      description: '',
      image: '',
      items: [
        {
          title: '',
          description: '',
          image: {
            id: '',
            file: ''
          },
          reports: [
            {
              file: '',
              image: '',
              title: '',
              createAt: '',
              updateAt: '',
              file_width: ''
            }
          ]
        }
      ]
    }
  ],
  reports: [
    {
      title: '',
      author: '',
      region: {
        region: '',
        state: '',
        enabled: false,
        id: ''
      },
      file: {
        id: '',
        file: ''
      },
      cover: {
        id: '',
        file: ''
      },
      file_width: '',
      file_name: '',
      thumbnail: {
        id: '',
        file: ''
      },
      date_post: '',
      authors: '',
      description: '',
      type: '',
      emphasis: false,
      items: [
        {
          group: '',
          group_items: [
            {
              image: {
                id: '',
                file: ''
              },
              description: '',
              content: ''
            }
          ]
        }
      ]
    }
  ],
  statistics: {
    total_occurrences: 0,
    total_police_operation: 0,
    total_wounded: 0,
    total_deads: 0
  }
}
