import styled from 'styled-components'

export const Container = styled.div`
  max-width: 100vw;
  overflow: hidden;
  * {
    font-family: 'Raleway', sans-serif !important;
    /* line-height: 32px; */
  }

  @media (max-width: 575.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    margin-top: 60px !important;
  }

  @media (min-width: 1200px) {
    margin-top: 143px;
  }
`

export const Paragraph = styled.div`
  p {
    padding: 40px 16px 30px 16px;
    @media (max-width: 690px) {
      font-size: 15px;
      line-height: 25px;
    }

    @media (min-width: 990px) {
      font-size: 18px;
      line-height: 32px;
      /* or 178% */

      /* Primaria */

      color: #233237;
      padding: 50px 80px 80px 80px;
      max-width: 1920px;
      margin: 0 auto;
    }
  }
`

export const HeaderSection = styled.div`
  background: linear-gradient(180deg, #202020 8.01%, #23526f 126.28%);
  width: 100%;
  min-height: 610px;
  @media (max-width: 690px) {
    height: 325px;
    max-height: 340px;
    min-height: 340px;
    > div {
      position: relative;
      > div {
        > div {
          z-index: 2;
          padding: 100px 16px;
          > h1,
          h2,
          h3,
          h4,
          h5,
          p {
            font-weight: 400;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;

            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 3px;
            }
          }
        }
        > button {
          display: none;
        }
      }

      > img {
        object-fit: cover;
        z-index: 1;
        width: 527px;
        opacity: 0.1;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 989.98px) {
    max-height: 500px;
    min-height: 400px;
    > div {
      position: relative;
      > div {
        > div {
          z-index: 2;
          padding: 100px 16px;
          > h1,
          h2,
          h3,
          h4,
          h5,
          p {
            font-weight: 400;
            height: 300px;
            margin-top: 60px;
            font-size: 38px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;

            > strong {
              background-color: ${props => props.theme.colors.secondary};
              padding: 3px;
            }
          }
        }
        > button {
          display: none;
        }
      }

      > img {
        object-fit: cover;
        z-index: 1;
        width: 100%;
        opacity: 0.1;
        position: absolute;
        left: 0;
        bottom: 55px;
      }
    }
  }

  @media (min-width: 990px) {
    height: auto;
    width: 100%;

    overflow: hidden;
    position: relative;
    > div {
      padding: 120px 80px 40px 80px;
      max-width: 1920px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      color: ${props => props.theme.colors.white};

      > div {
        width: 48%;
        > div {
          width: 80%;

          > h1,
          h2,
          h3,
          h4,
          h5,
          p {
            font-weight: 600;
            font-size: 40px;
            line-height: 46px;
            text-align: left;
            margin-top: 20px;
          }
        }

        > button {
          background: none;
          margin-top: 150px;
          > img {
            width: 48px;
          }
        }
      }

      > img {
        object-fit: cover;
        height: 615px;
        width: 48%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`

export const AboutValues = styled.div`
  > div {
    padding: 32px 16px;
    text-align: center;

    > div:nth-child(1) {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      margin-bottom: 15px;
    }

    > div:nth-child(2) {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
  }

  @media (min-width: 990px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    max-width: 1920px;
    margin: 0 auto;

    > div {
      padding: 32px 50px;
      width: 48%;
      text-align: left;
    }
  }
`

export const SectionValues = styled.div`
  padding: 40px 32px;
  text-align: center;
  background: #f8f8f8;

  > div {
    align-items: center;

    > div:nth-child(1) {
      font-size: 22px;
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }

  @media (min-width: 769px) {
    padding: 150px 80px;
    > div {
      max-width: 1920px;
      margin: 0 auto;
      > div:nth-child(1) {
        font-size: 28px;
        text-align: left;
      }
      > div:nth-child(2) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
`

export const SectionStory = styled.div`
  padding: 32px 16px;

  @media (max-width: 690px) {
    max-width: 100vw;
  }
  > div {
    > h1,
    h2,
    h3,
    h4,
    h5,
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      text-align: center;
      color: #233237;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 690px) {
    p {
      font-size: 15px;
      padding: 10px;
    }
  }

  > section {
    > div {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #233237;
      margin: 20px 0;
    }
    > img {
      width: 100% !important;
    }
  }

  @media (min-width: 992px) {
    max-width: 1920px;
    margin: 0 auto;
    padding: 32px 80px;

    > div {
      > h1,
      h2,
      h3,
      h4,
      h5,
      p {
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        text-align: left;
      }
    }
    > section {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;

      > div {
        width: 58%;
      }

      > img {
        height: auto !important;
        max-height: 500px;
        width: 500px !important;
      }

      > div:nth-child(1) {
        margin-right: 20px;
      }

      > div:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
`

export const SectionOurTeam = styled.div`
  background-color: #f8f8f8;
  padding: 16px 32px;
  > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    color: #233237;
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    width: 100%;
    padding: 32px 0;
    margin-bottom: 20px;
    > div {
      padding: 32px 80px;
      max-width: 1920px;
      margin: 0 auto;
      > h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
        text-align: left;
      }
    }
  }
`

export const SectionAwards = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  > div:nth-child(1) {
    > h1,
    h2,
    h3,
    h4,
    h5,
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      text-align: center;
      color: #233237;
      margin-bottom: 30px;
    }
  }
`

export const SectionPartners = styled.div`
  width: 100%;
  > div {
    margin: 0 auto;
    max-width: 1920px;
    padding: 32px 16px;
    text-align: center;
    margin-top: 40px;
    z-index: 5;
    > h3 {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      color: #233237;
    }
  }
`

export const SectionDownload = styled.div`
  background: linear-gradient(180deg, #202020 8.01%, #23526f 126.28%);
  width: 100%;
  > div {
    margin: 0 auto;
    max-width: 1920px;
    color: ${props => props.theme.colors.white};
    margin-top: -80px;
    display: flex;
  }

  @media (max-width: 690px) {
    > div {
      flex-direction: column;
      margin-top: 0px;
    }
  }
  .container-inner {
    width: 60%;
    padding-top: 238px;
    padding-bottom: 99px;
    padding-left: 80px;

    @media (max-width: 690px) {
      width: 100%;
      padding-top: 40px;
      padding-bottom: 10px;
      padding-left: 16px;
    }
  }
  .container-imgs {
    width: 50%;
    display: flex;
  }

  @media (max-width: 690px) {
    .container-imgs {
      width: 100%;
    }
  }

  .container-text {
    h3 {
      font-weight: 700;
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 26px;
      @media (max-width: 690px) {
        font-size: 24px;
        line-height: 45px;
        margin-bottom: 8px;
      }
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;

      @media (max-width: 690px) {
        font-size: 14px;
        line-height: 34px;
      }
    }
  }

  .container-flex {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    height: 60px;

    button {
      background: #ffffff;
      width: 176px;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 16px 21px;
      text-align: left;

      @media (max-width: 690px) {
        width: 164px;
        padding: 13px 17px;
      }
      img {
        width: 23px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 0px !important;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-inline-start: 0px;
        margin-block-start: 0px;
        padding-block-start: 0px;
        color: #233237;
        @media (max-width: 690px) {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }

  .image-right {
    width: 50%;
    height: 392px;
    display: inline-flex;
    margin-top: 100px;
    img {
      position: static;
      height: 100%;
    }
  }

  @media (max-width: 690px) {
    .image-right {
      width: 30%;
      height: 222px;
      display: inline-flex;
      margin-top: 34px;
      img {
        position: static;

        height: 100%;
      }
    }
  }

  .image-right-last {
    width: 50%;
    display: inline-flex;
    position: relative;
    img {
      position: absolute;
      top: 177px;
      right: 0px;
      height: 485px;
    }
  }

  @media (max-width: 690px) {
    .container-imgs {
      display: flex;
      position: relative;
      padding-bottom: 90px;
    }

    .image-right {
      width: 50%;
    }
    .image-right-last {
      width: 50%;
      display: inline-flex;
      position: relative;

      img {
        position: absolute;
        top: 50px;
        right: -40px;
        height: 343px;
      }
    }
  }
`

export const DownloadBlock = styled.div`
  display: flex;
  justify-content: space-around;

  > a {
    text-decoration: none;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.tertiary};
    padding: 13px 20px;
    display: flex;
    align-items: center;

    > img {
      margin-right: 12px;
    }
  }

  > a:nth-child(1) {
    margin-right: 50px;
  }

  @media (min-width: 992px) {
    > a {
      > p,
      strong {
        font-size: 20px;
      }
    }
  }
`

export const Content = styled.div`
  width: 305px;
  height: 240px;
  background: ${props => props.theme.colors.tertiary};
  color: ${props => props.theme.colors.white};
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  display: none;

  > div {
    > p,
    strong {
      padding: 19px 20px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
  }
`

export const Image = styled.img`
  width: 100%;
`

export const Card = styled.div`
  width: 305px;
  height: 240px;
  background: ${props => props.theme.colors.white};
  border: 1px solid rgba(196, 196, 196, 0.5);
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);

  > div {
    > p {
      padding-top: 10px;
      text-align: center;
    }
  }

  &:active {
    display: none;
  }

  &:active + ${Content} {
    display: block;
  }
`

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 80px;
  margin-bottom: 48px;

  @media (max-width: 575.98px) {
    display: none;
  }
`

export const PreviousBtn = styled.button`
  background: none;
  width: 32px;
  height: 32px;
  margin-right: 10px;

  > img {
    width: 32px;
    height: 32px;
    transform: rotateY(180deg);
  }
`

export const NextBtn = styled.button`
  background: none;
  width: 32px;
  height: 32px;

  > img {
    width: 32px;
    height: 32px;
  }
`

export const CardBlock = styled.div``
