import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 143px;
  padding: 40px 80px;

  input[type='radio'] {
    accent-color: ${props => props.theme.colors.secondary};
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  button {
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white};
    padding: 12px 16px;
  }

  > form {
    > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  }
`

export const DivFlex = styled.fieldset`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`

export const Column = styled.fieldset`
  display: flex;
  flex-direction: column;
`

export const Select = styled.select`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid rgba(69, 69, 69, 0.6);
  box-sizing: border-box;
  padding: 12px 16px;
  width: 300px;
`

export const Input = styled.input`
  border: 1px solid rgba(69, 69, 69, 0.6);
  box-sizing: border-box;
  padding: 12px 16px;
  width: 300px;
`

export const RadioGroup = styled.fieldset`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  label,
  input {
    margin-right: 5px;
  }

  > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  > div:nth-child(2) {
    margin-left: 68px;
  }
`
