import React from 'react'
import { Container, SelectItem } from '../Select/style'

type Option = {
  value: string
  label: string
}

interface ISelect {
  text: string
  name: string
  value: string
  valueOptions: Array<Option>
  onSelect(value: string): void
}

const Select: React.FC<ISelect> = ({
  text,
  name,
  value,
  valueOptions,
  onSelect
}) => {
  return (
    <Container>
      <p>{text}</p>

      <SelectItem
        name={name}
        value={value}
        onChange={event => onSelect(event.target.value)}
      >
        {valueOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectItem>
    </Container>
  )
}

export default Select
