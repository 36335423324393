import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { IProps } from './types'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  @media only screen and (max-width: 1199.98px) {
    display: none;
    width: 0% !important;
    height: 0% !important;
  }
`

export const Nav = styled.nav`
  height: 143px;
  background-color: ${props => props.theme.colors.tertiary};
`

export const NavHigher = styled.nav`
  width: 100%;
  > div {
    padding-right: 80px;
    padding-top: 12px;
    padding-bottom: 12px;
    height: 50px;
    max-width: 1920px;
    margin: 0 auto;
    background-color: ${props => props.theme.colors.tertiary};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`

export const NavBottom = styled.div`
  width: 100%;
  background-color: rgba(35, 35, 35, 0.8);
  > div {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 80px;
  }
`

export const Logo = styled.img`
  width: 229px;
  height: 36px;
`

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  position: relative;
`

export const LinkNav = styled(NavLink)`
  color: ${props => props.theme.colors.white};
  display: inline-block;
  opacity: 0.5;
  text-decoration: none;
  margin-right: 24px;
  cursor: pointer;

  &:hover,
  &.active {
    color: ${props => props.theme.colors.white};
    opacity: 100;
    text-decoration: none;
    border-bottom: solid 1px white;
  }
`

export const Search = styled.button`
  width: 43px;
  height: 43px;
  cursor: pointer;
  border: none;
  background: none;
`

export const ContainerResp = styled.nav<IProps>`
  @media only screen and (min-width: 1199.98px) {
    display: none;
  }

  background-color: ${props => props.theme.colors.tertiary};
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 9;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    > div {
      @media only screen and (max-width: 800px) {
        display: none;
      }
    }

    > button {
      background: none;
      border: none;
    }
  }

  > div:nth-child(2) {
    > div {
      @media only screen and (min-width: 800px) {
        display: none;
      }
    }
    > nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 20px;
      background-color: ${props => props.theme.colors.tertiary};
      ${props => {
        if (props.active == false) {
          return `
                        position: absolute;
                    `
        } else {
          return
        }
      }}
    }
  }
`

export const LinkNavResp = styled(NavLink)`
  background-color: #31464d;
  border-bottom: 1px solid ${props => props.theme.colors.tertiary};
  padding: 17px 16px;
  color: ${props => props.theme.colors.white};
  text-decoration: none;

  &:hover,
  &.active {
    color: ${props => props.theme.colors.white};
    opacity: 100;
    text-decoration: none;
  }
`

export const SearchResp = styled.div`
  border: 1px solid ${props => props.theme.colors.white};
  box-sizing: border-box;
  border-radius: 100px;
  width: 343px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0 auto;

  > input {
    background: none;
    border: none;
    margin-left: 15px;
    color: ${props => props.theme.colors.white};
  }
  > button {
    margin-right: 10px;
    background: none;
    > img {
      width: 24px;
      height: 24px;
    }
  }
`

export const LogoResp = styled.img`
  width: 124px;
  height: 18px;
`

export const Patners = styled(NavLink)`
  padding: 8px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.secondary};
  font-size: 12px;
  text-decoration: none;
  margin-right: 10px;
`

export const InputSearch = styled(NavLink)`
  border: 1px solid '#fff' !important;
  color: '#fff' !important;
  position: absolute;
  top: 5px;
  left: 400px;

  input {
    font-size: 16px;
    background: none;
    padding: 5px;
    color: #fff !important;
    background-color: none;
    border-bottom: 2px solid ${props => props.theme.colors.white};
    width: 120px;
  }
  input::placeholder {
    color: #fff;
  }
`

export const Input = styled.input`
  font-size: 16px;
  background: none;
  padding: 5px;
  color: #fff !important;
  background-color: none;
  border-bottom: 2px solid ${props => props.theme.colors.white};
  width: 120px;
  input::placeholder {
    color: #fff;
  }
`
