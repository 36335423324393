import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  > div {
    margin: 0 auto;
    max-width: 1920px;
    margin-top: 70px;
    padding-left: 40px;
    padding-bottom: 0;
  }

  @media (max-width: 575.98px) {
    > div {
      margin-top: -20px;
      padding: 36px 14px;
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    > div {
      margin-top: 30px;
      padding: 36px 14px;
      > h3 {
        text-align: center;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    > div {
      margin-top: 30px;
      padding: 36px 14px;
      > h3 {
        text-align: center;
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1600.98px) {
    > div {
      margin-top: 80px;
      padding: 36px 14px;
      > h3 {
        text-align: center;
      }
    }
  }
`

export const Block = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    > h3 {
      margin-top: 80px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px !important;
      line-height: 67px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  @media (max-width: 575.98px) {
    flex-direction: column;

    > div {
      > div {
        flex-direction: column;
      }
    }

    > img {
      width: 100%;
    }
  }

  @media (min-width: 576px) and (max-width: 991.98px) {
    flex-direction: column;

    > div {
      > h3 {
        margin-left: 15px;
        margin-top: 10px;
      }

      > div {
        flex-wrap: wrap;
      }
    }

    > img {
      width: 100%;
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    flex-direction: column;

    > div {
      > h3 {
        margin-left: 15px;
        margin-top: 10px;
      }

      > div {
        flex-wrap: wrap;
      }
    }

    > img {
      width: 100%;
      margin-top: 40px;
      padding-right: 40px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1600.98px) {
    > div {
      > h3 {
        margin-left: 15px;
      }

      > div {
        flex-wrap: wrap;
      }
    }

    > img {
      width: 650px;
      object-fit: cover;
      margin-top: 40px;
    }
  }
  @media (min-width: 1601px) {
    > div {
      > h3 {
        margin-left: 15px;
      }

      > div {
        flex-wrap: wrap;
      }
    }

    > img {
      width: 650px;
      object-fit: cover;
      margin-top: 40px;
    }
  }
`

export const Image = styled.img`
  height: 600px;
  width: 750px;
`
