import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Header,
  Navigation,
  Button,
  SearchBar,
  SearchBarMid,
  Content,
  Search,
  SearchButton,
  ContainerSearch,
  BoxModal,
  Paginatior,
  PrevBtn,
  NextBtn,
  Indicator
} from '../Impacto/style'

import { useDispatch, useSelector } from 'react-redux'
import { convertData, getImpact } from '../../functions'
import { RootState } from '../../store'
import { GET_IMPACT } from '../../store/actions'

import { Modal } from '@mui/material'

import CardTertiary from '../../components/CardTertiary'

import searchImg from '../../assets/searchBtn.png'
import filterImg from '../../assets/filter.png'
import close from '../../assets/closeDark.png'
import icon from '../../assets/learnMore.png'
import { Helmet } from 'react-helmet'

const Impacto: React.FC = () => {
  const dispatch = useDispatch()
  const { token, impact } = useSelector((state: RootState) => state.clickState)
  const [showImprensa, setShowImprensa] = useState(false)
  const [showPP, setShowPP] = useState(false)
  const [showPA, setShowPA] = useState(false)
  const [contImprensa, setContImprensa] = useState(0)
  const [contAcad, setContAcad] = useState(0)
  const [contPP, setContPP] = useState(0)
  const [imprensa, setImprensa] = useState(true)
  const [polPublicas, setPolPublicas] = useState(false)
  const [academica, setAcademica] = useState(false)
  const [order, setOrder] = useState('')
  const [page, setPage] = useState(1)
  const [take, setTake] = useState('')
  const [title, setTitle] = useState('')
  const [mediaType, setMediaType] = useState('')
  const [scope, setScope] = useState('')
  const [state, setState] = useState('')
  const [fieldKnowledge, setFieldKnowledge] = useState('')
  const [year, setYear] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [subjectArea, setSubjectArea] = useState('')
  const [federativePower, setFederativePower] = useState('')
  const [search, setSearch] = useState('')
  const refSearch = useRef()

  useEffect(() => {
    getImpact(
      token,
      order,
      page,
      take,
      title,
      mediaType,
      scope,
      state,
      fieldKnowledge,
      year,
      documentType,
      subjectArea,
      federativePower,
      search
    ).then(resp => {
      dispatch({ type: GET_IMPACT, impact: resp })
    })
  }, [
    order,
    page,
    take,
    title,
    mediaType,
    scope,
    state,
    fieldKnowledge,
    year,
    documentType,
    subjectArea,
    federativePower,
    search
  ])

  useEffect(() => {
    getImpact(token, undefined, undefined, '10000').then(resp => {
      let aux1: any[] = []
      let aux2: any[] = []
      let aux3: any[] = []
      resp.forEach((id: any) => {
        if (id.type == 'Imprensa') {
          aux1.push(id)
        }
        if (id.type == 'Politicas Públicas') {
          aux2.push(id)
        }
        if (id.type == 'Pesquisa Acadêmica') {
          aux3.push(id)
        }
      })
      setContImprensa(aux1.length)
      setContPP(aux2.length)
      setContAcad(aux3.length)
    })
  }, [])

  let currentTime = new Date()
  let maxYear = currentTime.getFullYear()

  let escopoImprensa = [
    { label: 'Internacional', value: 'internacional' },
    { label: 'Nacional', value: 'nacional' },
    { label: 'Regional', value: 'regional' },
    { label: 'Local', value: 'local' }
  ]

  let mediaImprensa = [
    { label: 'Impresso', value: 'impresso' },
    { label: 'TV', value: 'tv' },
    { label: 'Rádio', value: 'radio' },
    { label: 'Podcast', value: 'podcast' },
    { label: 'Vídeo em redes sociais', value: 'videoSocial' },
    { label: 'Outros', value: 'outros' },
    { label: 'Online', value: 'online' }
  ]

  let escopoPolitica = [
    { label: 'Federal', value: 'federal' },
    { label: 'Estadual', value: 'estadual' },
    { label: 'Municipal', value: 'municipal' },
    { label: 'Global', value: 'global' }
  ]

  let mediaTypes = [
    { label: 'Artigo em periódicos', value: 'Artigo em periódicos' },
    { label: 'Capítulo de livro', value: 'Capítulo de livro' },
    { label: 'Blog', value: 'Blog' },
    { label: 'Eventos', value: 'Eventos' },
    { label: 'Entrevistas', value: 'Entrevistas' },
    { label: 'Artigo eletrônico', value: 'Artigo eletrônico' },
    { label: 'Manual', value: 'Manual' },
    { label: 'Tese', value: 'Tese' },
    { label: 'Dissertação', value: 'Dissertação' },
    { label: 'Monografia/TCC', value: 'Monografia/TCC' },
    { label: 'Relatório', value: 'Relatório' },
    { label: 'Livro', value: 'Livro' }
  ]

  let powerFederative = [
    { label: 'Executivo', value: 'Executivo' },
    { label: 'Legislativo', value: 'Legislativo' },
    { label: 'Judiciário', value: 'Judiciário' },
    { label: 'Nenhum', value: 'Nenhum' }
  ]

  let tipoPP = [
    { label: 'Lei', value: 'lei' },
    { label: 'Ação Civil Pública', value: 'acaoCivilPublica' },
    {
      label: 'Arguição de Descumprimento de Preceito Fundamental',
      value: 'adpf'
    },
    { label: 'Recomendação Pública', value: 'recPublcia' },
    { label: 'Apresentação Pública', value: 'apresentacaoPublica' },
    { label: 'Projeto', value: 'projeto' },
    { label: 'Audiência Pública', value: 'audPublica' },
    { label: 'Projeto de Lei', value: 'projDeLei' }
  ]

  let areaPP = [
    { label: 'Educação', value: 'educacao' },
    { label: 'Segurança Pública', value: 'segurancaPublica' },
    { label: 'Combate à Pobreza', value: 'combateAProbreza' },
    { label: 'Crianças e Adolescentes', value: 'crinacaEAdolecente' }
  ]

  let camposConhecimento = [
    { label: 'Sociologia', value: 'Sociologia' },
    { label: 'Antropologia', value: 'antropologia' },
    { label: 'Ciência política', value: 'cienciaPolitica' },
    { label: 'Economia', value: 'economia' },
    { label: 'Psicologia', value: 'psicologia' },
    { label: 'Matemática', value: 'matematica' },
    { label: 'Educação', value: 'educacao' },
    { label: 'Relações internacionais', value: 'relacoesInt' },
    { label: 'Administração', value: 'adm' },
    { label: 'Geografia', value: 'geo' },
    { label: 'Comunicação', value: 'comunicacao' },
    { label: 'Arquitetura e urbanismo', value: 'arqEUrb' },
    { label: 'Serviço social', value: 'servicoSocial' },
    { label: 'Gestão pública', value: 'gestaoPublica' },
    { label: 'Direito', value: 'direito' },
    { label: 'Estatística', value: 'estatistica' }
  ]

  return (
    // <Container>
    //     <Helmet
    //         title="Impacto - Fogo Cruzado"
    //     />
    //     <Header>
    //         <div>
    //             <p>Nos últimos <strong>12 meses</strong> o Fogo Cruzado foi mencionado em:</p>
    //             <div>
    //                 <strong>{contImprensa}</strong>
    //                 <p>Matérias na imprensa</p>
    //             </div>
    //             <div>
    //                 <strong>{contPP}</strong>
    //                 <p>Políticas Públicas</p>
    //             </div>
    //             <div>
    //                 <strong>{contAcad}</strong>
    //                 <p>Pesquisas acadêmicas</p>
    //             </div>
    //         </div>
    //     </Header>
    //     <Navigation>
    //         <Button
    //             selected={imprensa}
    //             onClick={() => {
    //                 setImprensa(true);
    //                 setPolPublicas(false);
    //                 setAcademica(false);
    //             }}
    //         >Imprensa</Button>
    //         <Button
    //             selected={polPublicas}
    //             onClick={() => {
    //                 setImprensa(false);
    //                 setPolPublicas(true);
    //                 setAcademica(false);
    //             }}
    //         >Politicas Publicas</Button>
    //         <Button
    //             selected={academica}
    //             onClick={() => {
    //                 setImprensa(false);
    //                 setPolPublicas(false);
    //                 setAcademica(true);
    //             }}
    //         >Politicas academicas</Button>
    //     </Navigation>
    //     {imprensa === true && (
    //         <>
    //             <ContainerSearch>
    //                 <Search>
    //                     <input type="search"
    //                         onChange={(e) => {
    //                             setTitle(e.target.value)
    //                         }}
    //                     />
    //                     <button onClick={() => refSearch}>
    //                         <img src={searchImg} alt="" />
    //                     </button>
    //                 </Search>
    //                 <SearchButton
    //                     onClick={() => setShowImprensa(true)}
    //                 >
    //                     <img src={filterImg} alt="" />
    //                 </SearchButton>
    //             </ContainerSearch>
    //             <SearchBar>
    //                 <fieldset>
    //                     <label htmlFor="">Tipo de mídia</label>
    //                     <select name="" id="" onChange={(e) => {setMediaType(e.target.value)}}>
    //                         <option value="">Todas as mídia</option>
    //                         {mediaImprensa.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Escopo</label>
    //                     <select name="" id="" onChange={(e) => {setScope(e.target.value)}}>
    //                         <option value="">Todos os escopos</option>
    //                         {escopoImprensa.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Regiao (UF)</label>
    //                     <input placeholder="Todos" onChange={(e) => {setState(e.target.value)}}/>
    //                 </fieldset>
    //             </SearchBar>
    //             <Content>
    //                 {impact?.map((id: any) => {
    //                     return (
    //                         <>
    //                             {id.type == 'Imprensa' &&
    //                                 <CardTertiary
    //                                     author={id?.vehicle_name}
    //                                     data={convertData(id?.updatedAt)}
    //                                     title={id?.title}
    //                                     to={id?.link}
    //                                     area=""
    //                                     campoConhecimento={id?.field_knowledge}
    //                                     escopo={id?.scope}
    //                                     locale={id?.state}
    //                                     media={id?.media_type}
    //                                     poderFederativo={id?.federative_power}
    //                                     tipo={id?.type_material}
    //                                     year={id?.year}
    //                                 />
    //                             }
    //                         </>
    //                     )
    //                 })}
    //             </Content>
    //         </>
    //     )}

    //     {polPublicas === true && (
    //         <>
    //             <ContainerSearch>
    //                 <Search>
    //                     <input type="search"
    //                         onChange={(e) => {
    //                             setTitle(e.target.value)
    //                         }}
    //                     />
    //                     <button onClick={() => refSearch}>
    //                         <img src={searchImg} alt="" />
    //                     </button>
    //                 </Search>
    //                 <SearchButton
    //                     onClick={() => setShowPP(true)}
    //                 >
    //                     <img src={filterImg} alt="" />
    //                 </SearchButton>
    //             </ContainerSearch>
    //             <SearchBarMid>
    //                 <fieldset>
    //                     <label htmlFor="">Ano</label>
    //                     <input min={1990} max={maxYear} placeholder="Tudo" onChange={(e) => {setYear(e.target.value)}}/>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Poder federativo</label>
    //                     <select name="" id="" onChange={(e) => {setFederativePower(e.target.value)}}>
    //                         <option value="">Todos os poderes</option>
    //                         {powerFederative.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Escopo</label>
    //                     <select name="" id="" onChange={(e)=> {setScope(e.target.value)}}>
    //                         <option value="">Tudo</option>
    //                         {escopoPolitica.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Tipo</label>
    //                     <select name="" id="" onChange={(e) => {setDocumentType(e.target.value)}}>
    //                         <option value="">Todos os tipos</option>
    //                         {tipoPP.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Área</label>
    //                     <select name="" id="" onChange={(e) => {setSubjectArea(e.target.value)}}>
    //                         <option value="">Todas as áreas</option>
    //                         {areaPP.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //             </SearchBarMid>
    //             <Content>
    //                 {impact?.map((id: any) => {
    //                     return (
    //                         <>
    //                             {id.type == 'Politicas Públicas' &&
    //                                 <CardTertiary
    //                                     author={id?.federative_power}
    //                                     data={convertData(id?.updatedAt)}
    //                                     title={id?.title}
    //                                     to={id?.link}
    //                                     area=""
    //                                     campoConhecimento={id?.field_knowledge}
    //                                     escopo={id?.scope}
    //                                     locale={id?.state}
    //                                     media={id?.media_type}
    //                                     poderFederativo=''
    //                                     tipo={id?.type_material}
    //                                     year={id?.year}
    //                                 />
    //                             }
    //                         </>
    //                     )
    //                 })}
    //             </Content>
    //         </>
    //     )}

    //     {academica === true && (
    //         <>
    //             <ContainerSearch>
    //                 <Search>
    //                     <input type="search"
    //                         onChange={(e) => {
    //                             setTitle(e.target.value)
    //                         }}
    //                     />
    //                     <button onClick={() => refSearch}>
    //                         <img src={searchImg} alt="" />
    //                     </button>
    //                 </Search>
    //                 <SearchButton
    //                     onClick={() => setShowPA(true)}
    //                 >
    //                     <img src={filterImg} alt="" />
    //                 </SearchButton>
    //             </ContainerSearch>
    //             <SearchBar>
    //                 <fieldset>
    //                     <label htmlFor="">Ano</label>
    //                     <input placeholder="Tudo" min={1990} max={maxYear} onChange={(e) => {setYear(e.target.value)}}/>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Campo de conhecimento</label>
    //                     <select name="" id="" onChange={(e) => {setFieldKnowledge(e.target.value)}}>
    //                         <option value="">Todos os campos</option>
    //                         {camposConhecimento.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Mídia</label>
    //                     <select name="" id="" onChange={(e) => {setFederativePower(e.target.value)}}>
    //                         <option value="">Todas as mídias</option>
    //                         {mediaTypes.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //             </SearchBar>
    //             <Content>
    //                 {impact?.map((id: any) => {
    //                     return (
    //                         <>
    //                             {id.type == 'Pesquisa Acadêmica' &&
    //                                 <CardTertiary
    //                                     author={id?.author}
    //                                     data={convertData(id?.updatedAt)}
    //                                     title={id?.title}
    //                                     to={id?.link}
    //                                     area=""
    //                                     campoConhecimento={id?.field_knowledge}
    //                                     escopo={id?.scope}
    //                                     locale={id?.state}
    //                                     media={id?.media_type}
    //                                     poderFederativo={id?.federative_power}
    //                                     tipo={id?.type_material}
    //                                     year={id?.year}
    //                                 />
    //                             }
    //                         </>
    //                     )
    //                 })}
    //             </Content>

    //         </>
    //     )}
    //     <Paginatior>
    //         <div>
    //             <PrevBtn
    //                 onClick={() => {
    //                     let cont = page - 1;
    //                     setPage(cont);
    //                 }}
    //                 disabled={page === 1 ? true : false}
    //             >
    //                 <img src={icon} alt="" />
    //             </PrevBtn>
    //             <Indicator>{page}</Indicator>
    //             <NextBtn
    //                 onClick={() => {
    //                     let cont = page + 1;
    //                     setPage(cont);
    //                 }}
    //             >
    //                 <img src={icon} alt="" />
    //             </NextBtn>
    //         </div>
    //     </Paginatior>

    //     <Modal
    //         open={showImprensa}
    //         onClose={() => setShowImprensa(false)}
    //     >
    //         <BoxModal>
    //             <button
    //                 onClick={() => {
    //                     setShowImprensa(false)
    //                 }}
    //             >
    //                 <img src={close} alt="" />
    //             </button>
    //             <h3>Filtros</h3>
    //             <fieldset>
    //                 <label htmlFor="">Tipo de mídia</label>
    //                 <select name="" id="" onChange={(e) => {setMediaType(e.target.value)}}>
    //                     <option value="">Todas as mídia</option>
    //                     {mediaImprensa.map((select: any) => {
    //                         return <option value={select.value}>{select.label}</option>
    //                     })}
    //                 </select>
    //             </fieldset>
    //             <fieldset>
    //                 <label htmlFor="">Escopo</label>
    //                 <select name="" id="" onChange={(e) => {setScope(e.target.value)}}>
    //                     <option value="">Todos os escopos</option>
    //                     {escopoImprensa.map((select: any) => {
    //                         return <option value={select.value}>{select.label}</option>
    //                     })}
    //                 </select>
    //             </fieldset>
    //             <fieldset>
    //                 <label htmlFor="">Regiao (UF)</label>
    //                 <input placeholder="Todos" onChange={(e) => {setState(e.target.value)}}/>
    //             </fieldset>
    //             <div>
    //                 <button
    //                     onClick={() => {
    //                         setShowImprensa(false)
    //                     }}
    //                 >Filtrar</button>
    //                 <button
    //                     onClick={() => {
    //                         setMediaType('')
    //                         setScope('')
    //                         setState('')
    //                         setImprensa(false)
    //                     }}
    //                 >Limpar filtros</button>
    //             </div>
    //         </BoxModal>
    //     </Modal>
    //     <Modal
    //         open={showPP}
    //         onClose={() => setShowPP(false)}
    //     >
    //         <BoxModal>
    //             <button
    //                 onClick={() => {
    //                     setShowPP(false)
    //                 }}
    //             >
    //                 <img src={close} alt="" />
    //             </button>
    //             <h3>Filtros</h3>
    //             <fieldset>
    //                     <label htmlFor="">Ano</label>
    //                     <input min={1990} max={maxYear} placeholder="Tudo" onChange={(e) => {setYear(e.target.value)}}/>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Poder federativo</label>
    //                     <select name="" id="" onChange={(e) => {setFederativePower(e.target.value)}}>
    //                         <option value="">Todos os poderes</option>
    //                         {powerFederative.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Escopo</label>
    //                     <select name="" id="" onChange={(e)=> {setScope(e.target.value)}}>
    //                         <option value="">Tudo</option>
    //                         {escopoPolitica.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Tipo</label>
    //                     <select name="" id="" onChange={(e) => {setDocumentType(e.target.value)}}>
    //                         <option value="">Todos os tipos</option>
    //                         {tipoPP.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //                 <fieldset>
    //                     <label htmlFor="">Área</label>
    //                     <select name="" id="" onChange={(e) => {setSubjectArea(e.target.value)}}>
    //                         <option value="">Todas as áreas</option>
    //                         {areaPP.map((select: any) => {
    //                             return <option value={select.value}>{select.label}</option>
    //                         })}
    //                     </select>
    //                 </fieldset>
    //             <div>
    //                 <button
    //                     onClick={() => {
    //                         setShowPP(false)
    //                     }}
    //                 >Filtrar</button>
    //                 <button
    //                     onClick={() => {
    //                         setYear('')
    //                         setFederativePower('')
    //                         setScope('')
    //                         setDocumentType('')
    //                         setSubjectArea('')
    //                         setShowPP(false)
    //                     }}
    //                 >Limpar filtros</button>
    //             </div>
    //         </BoxModal>
    //     </Modal>
    //     <Modal
    //         open={showPA}
    //         onClose={() => setShowPA(false)}
    //     >
    //         <BoxModal>
    //             <button
    //                 onClick={() => {
    //                     setShowPA(false)
    //                 }}
    //             >
    //                 <img src={close} alt="" />
    //             </button>
    //             <h3>Filtros</h3>
    //             <fieldset>
    //                 <label htmlFor="">Ano</label>
    //                 <input placeholder="Tudo" min={1990} max={maxYear} onChange={(e) => {setYear(e.target.value)}}/>
    //             </fieldset>
    //             <fieldset>
    //                 <label htmlFor="">Campo de conhecimento</label>
    //                 <select name="" id="" onChange={(e) => {setFieldKnowledge(e.target.value)}}>
    //                     <option value="">Todos os campos</option>
    //                     {camposConhecimento.map((select: any) => {
    //                         return <option value={select.value}>{select.label}</option>
    //                     })}
    //                 </select>
    //             </fieldset>
    //             <fieldset>
    //                 <label htmlFor="">Mídia</label>
    //                 <select name="" id="" onChange={(e) => {setFederativePower(e.target.value)}}>
    //                     <option value="">Todas as mídias</option>
    //                     {mediaTypes.map((select: any) => {
    //                         return <option value={select.value}>{select.label}</option>
    //                     })}
    //                 </select>
    //             </fieldset>
    //             <div>
    //                 <button
    //                     onClick={() => {
    //                         setShowPA(false)
    //                     }}
    //                 >Filtrar</button>
    //                 <button
    //                     onClick={() => {
    //                         setYear('')
    //                         setFieldKnowledge('')
    //                         setFederativePower('')
    //                         setShowPA(false)
    //                     }}
    //                 >Limpar filtros</button>
    //             </div>
    //         </BoxModal>
    //     </Modal>
    // </Container>
    <>
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <h1>Em breve!</h1>
      </div>
    </>
  )
}

export default Impacto
